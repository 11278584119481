/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import { LOCAL_STORAGE_APP_STATE } from './util/appConstants';
import { exportAppState } from './util/util';
import ErrorIllustration from './components/icons/ErrorIllustration'
import { IconGeneralDownload, IconGeneralTrash } from '@curity-internal/ui-icons-react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        };
    }

    componentDidMount() {
      document.documentElement.setAttribute('data-color-scheme', localStorage.getItem('colorScheme'));
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error("Something went wrong state couldn't be loaded, clearing localStorage");
        console.trace(info.componentStack);
        this.setState({
            hasError:true
        });
    }

    clearBrowserState = () =>{
        window.localStorage.removeItem(LOCAL_STORAGE_APP_STATE);
        window.location.reload();
    };

    downloadAppState = () =>{
        const data = localStorage[LOCAL_STORAGE_APP_STATE] || JSON.stringify(this.state.defaultState);
        const fileName = 'app-config.json';
        const fileType = 'application/json';
        const keysToRemove = ['appState'];
        if (data && Object.keys(data).length > 0) {
            exportAppState(data, fileName, fileType, keysToRemove);
        }
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
              <div className="error-container">
                <div className="error-page">
                  <h1 className="h3 mt0">Something&apos;s not working</h1>
                  <ErrorIllustration />
                  <p>
                    Your application state could not be loaded. That could be
                    because of version mismatch or a corrupted import. You will
                    not be able to continue unless you clear your state.
                  </p>

                  <h4>Things you can do</h4>
                  <ul>
                    <li>Clear the state and the local storage data</li>
                    <li>Download the config (app state) as a backup</li>
                    <li>
                      Drop us an email on{' '}
                      <a className="bright" href="mailto:oauth.tools@curity.io">
                        oauth.tools@curity.io
                      </a>
                      . You can also download the config below{' '}
                      <code>(app-config.json)</code>and send it to our support
                      team for more help
                    </li>
                  </ul>

                  <button
                    className={'button button-small button-primary mr1'}
                    onClick={this.downloadAppState}
                    aria-haspopup="true"
                  >
                    <IconGeneralDownload width={16} height={16} />
                    Download config
                  </button>

                  <button
                    className={'button button-small button-danger'}
                    onClick={this.clearBrowserState}
                    aria-haspopup="true"
                    aria-expanded={
                      this.state.showEnvironments ? 'true' : 'false'
                    }
                  >
                    <IconGeneralTrash width={16} height={16} />
                    Clear data
                  </button>
                </div>
              </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary
