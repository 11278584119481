/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import FoldButton from '../FoldButton';
import AceEditor from 'react-ace';
import CustomHighlightMode from '../../util/JwtModeHighlight';
import TokenPurposeSelector from './TokenPurposeSelector';

const TokenInput = (props) => {
    const aceEditorRef = useRef();
    const [isFolded, setIsFolded] = useState(false)

    const handleFold = () => {
        setIsFolded(!isFolded)
    };
    const updateJwtToken = (newValue) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('jwt_flow_token_input', newValue)
        props.updateParameters(props.collection.id, updatedParameters)
    };

    const updateJwtPurpose = (newValue) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('jwt_flow_token_purpose', newValue)
        props.updateParameters(props.collection.id, updatedParameters)

    }

    useEffect(() => {
        const customMode = new CustomHighlightMode();
        if (aceEditorRef.current){
            aceEditorRef.current.editor.getSession().setMode(customMode);
            aceEditorRef.current.editor.container.style.lineHeight = 1.75;
            aceEditorRef.current.editor.renderer.updateFontSize();
        }
    },[isFolded])

    const tokenPurpose = props.collection.parameters.jwt_flow_token_purpose ?
        props.collection.parameters.jwt_flow_token_purpose : 'unknown';

    return (
        <div className="tools-form-token tools-form-token-active">
            <header className="tools-box-header tools-box-header-token">
                <div className="flex flex-center flex-justify">

                    <div className="flex flex-center flex-justify flex-wrap">
                        <div className="mr2">Token Purpose:</div>
                        <TokenPurposeSelector
                            purpose={tokenPurpose}
                            handleChange={updateJwtPurpose}
                        />
                    </div>

                    <div className="flex flex-center flex-justify">
                        <FoldButton handleFold={handleFold} isFolded={isFolded}/>
                    </div>
                </div>
            </header>
            {(!isFolded) ? <div className={'ace-editor-wrapper px2 py2'}>
                <AceEditor
                    ref={aceEditorRef}
                    mode={'text'}
                    theme={'monokai'}
                    setOptions={{ indentedSoftWrap: false, tabSize: 0, showGutter: false }}
                    indentedSoftWrap={false}
                    showPrintMargin={false}
                    onChange={updateJwtToken}
                    focus={true}
                    name={'jwt-editor'}
                    value={props.collection.parameters.jwt_flow_token_input}
                    width={'100%'}
                    maxLines={40}
                    minLines={10}
                    fontSize={14}
                    wrapEnabled={true}
                    editorProps={{ $blockScrolling: true }}
                />
            </div> : ''}
        </div>

    )
}

export default TokenInput;
