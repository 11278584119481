/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import InteractiveFlowCallback from './InteractiveFlowCallback';
import { oauthResponseTypes, tokenPurposes } from '../../util/appConstants'
import Token from '../../data/Token';
import Environments from '../../data/Environments';
import { splitParams } from '../../util/util';

class ImplicitFlowCallback extends InteractiveFlowCallback {

    componentWillMount() {
        const fragment = this.props.hash.replace('#', '');
        const params = splitParams(fragment);

        const collectionId = params.state;

        const accessToken = params.access_token ? decodeURIComponent(params.access_token) : null;
        const idToken = params.id_token ? decodeURIComponent(params.id_token) : null;
        const errorMessage = params['error'] ? decodeURIComponent(params['error']) : null;
        const errorDescription = Object.prototype.hasOwnProperty.call(params, 'error_description') ? decodeURIComponent(params.error_description) : null;

        const error = errorMessage ? 'Call to authorize endpoint failed. ' + errorMessage + ': ' + errorDescription : null;

        if (collectionId && this.props.collections[collectionId]) {
            this.props.setOAuthResponseOnCollection(collectionId, {
                type: oauthResponseTypes.url,
                url: this.props.callbackUrl
            }, 'ImplicitFlowAuthorizationCode');

            if (this.props.collections[collectionId].parameters.can_be_framed && FEATURE_FRAMING_ENABLED) {

                const tokens = { access_token: accessToken, id_token: idToken };
                const data = { hideModal: true, callbackUrl: this.props.callbackUrl, tokens, error };

                window.parent.postMessage(data, window.location.origin);
            } else {
                let tokens = [];
                if (accessToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.access_token.value,
                        name: 'Access Token',
                        value: accessToken
                    }));
                }
                if (idToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.id_token.value,
                        name: 'ID Token',
                        value: idToken
                    }));
                }
                const environments = Environments.create(this.props.environments);
                const environment = environments.getEnvironment(this.props.collections[collectionId].provider);
                //Update collection
                const keys = (environment.jwks !== undefined) ? environment.jwks : [];
                this.props.setTokensOnCollection(collectionId, tokens, keys, error);
            }
        }
    }
}

export default ImplicitFlowCallback;
