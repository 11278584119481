/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class DCRInput extends React.Component {

    handleChange = (event) => {
        const currentCollection = this.props.collection;

        const updatedEnvironment = currentCollection.parameters.withUpdatedDcrParameters(this.props.field,
            event.currentTarget.value);
        this.props.updateParameters(currentCollection.id, updatedEnvironment);
    };

    render() {
        const field = this.props.field;
        const value = this.props.collection.parameters.dcr_parameters[field] || undefined;
        return (
            <input type={this.props.type} placeholder={this.props.placeholder}
                   onChange={this.handleChange}
                   value={value || ''}
                   className="field col-12"
                   autoFocus={false}
                   autoComplete="off"
                   data-lpignore="true"
                   spellCheck="false"
            />
        );
    }
}

export default DCRInput
