/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import CryptoJS from 'crypto-js'
import FileSaver from 'file-saver';
import { IMPORT_TYPE, PLAYGROUND } from './appConstants';
import jose from 'node-jose';
import CurityIcon from '../components/icons/CurityIcon';
import SignicatIcon from '../components/icons/SignicatIcon';
import React from 'react';
import util from 'node-jose/lib/util';
import { isEmptyObject } from './validationUtils';
import { SdArray, SdObject, SdPrimitive } from 'curity-ssi-libs-sd-jwt';


export const randomTimeId = () => {
    let text = generateRandomString(3);

    const d = Date.now();
    return d + '-' + text;
};

export const generateRandomString = (length) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const base64encode = (string) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(string));
};

export const base64URLEncode = (string) => {
    return jose.util.base64url.encode(string);
};

export const createNoneJwt = (header, body) => {
    return base64URLEncode(header) + '.' + base64URLEncode(body) + '.';
}

export const generateCodeChallenge = (code_verifier) => {
    return CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

export const notEmpty = (value) => {
    return (value !== null && value !== undefined && value !== '')
};

export const isMapEmpty = (obj) => {
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key))
            return false;
    }
    return true;
};

export const nullIfUndefined = (value) => {
    if (value === undefined) {
        return null;
    }
    return value;
};

export const emptyStringIfUndefined = (value) => {
    if (value === undefined) {
        return null;
    }
    return '';
};

export const getUnique = (arr, param) => {
    //store the comparison  values in array
    if (arr) {
        return arr.map(e => e[param])// store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & return unique objects
            .filter((e) => arr[e]).map(e => arr[e])
    }
    return null;
};

export const decodeUrlParameter = (str) => {
    return decodeURIComponent((str + '').replace(/\+/g, '%20'));
};


export const encodeUrlParameter = (obj) => {
    const str = [];
    for (let p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p]) {
            if (typeof obj[p] === 'object') {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(JSON.stringify(obj[p])));
            } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
    return str.join('&');
};

export const splitParams = (paramsStr) => {
    let keyValue;
    if (paramsStr.includes('&')) {
        keyValue = paramsStr.split('&');
    } else {
        keyValue = [paramsStr];
    }

    const result = {};
    for (let i = 0; i < keyValue.length; i++) {
        if (keyValue[i].includes('=')) {
            const nextSplit = keyValue[i].split('=');
            result[nextSplit[0]] = (nextSplit.length === 2) ? nextSplit[1] : null;
        } else {
            result[keyValue[i]] = null;
        }
    }
    return result;
};

export const downloadFile = (text, name, type) => {
    const file = new Blob([text], { type });
    const isIE = /*@cc_on!@*/!!document.documentMode;
    if (isIE) {
        window.navigator.msSaveOrOpenBlob(file, name);
    } else {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = name;
        a.click();
        a.remove();
    }
};

export const mergeObjects = (obj, src) => {
    for (const key in src) {
        if (Object.prototype.hasOwnProperty.call(src, key)) obj[key] = src[key];
    }
    return obj;
};

export const arrayIntersection = (arr1, arr2) => {
    if (!arr2) {
        return arr1;
    }
    return arr1.filter(value => -1 !== arr2.indexOf(value));
};

export const stringArrayToValueLabelArray = (array) => {
    return array.map((value) => {
        return { value, label: value }
    })
};

export const exportAppState = (dataToExport, fileName, fileType, keysToRemove) => {
    if (dataToExport && Object.keys(dataToExport).length > 0) {
        const fileData = JSON.parse(dataToExport);
        const restOfParameters = removePropsFromData(fileData, keysToRemove);
        downloadFileAs(restOfParameters, fileName, fileType);
    }
};

// WILL REMOVE ROOT LEVEL PROPS:
export const removePropsFromData = (dataToExport, keysToRemove) => {
    let data = dataToExport;
    // Any Props to remove:
    keysToRemove.forEach((key) => {
        // eslint-disable-next-line no-unused-vars
        const { [key]: removedProp, ...restOfTheParameters } = data;
        data = restOfTheParameters;
    });
    return data;
};

// EXPORT FILE AS:
export const downloadFileAs = (fileDataToExport, fileName, fileType) => {
    const exportData = JSON.stringify(fileDataToExport);
    const blob = new Blob([exportData], { type: fileType });
    FileSaver.saveAs(blob, fileName);
};


export const mergeDeep = (target, source) => {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                if (isArray(source[key])) {
                    // ARRAY CASE:
                    let mergedValues = [...target[key], ...source[key]];
                    let uniqueValues = [...new Set(mergedValues)];
                    Object.assign(output, { [key]: uniqueValues });
                } else {
                    if (!Object.prototype.hasOwnProperty.call(output, key)) {
                        // If Prop Not Exists
                        Object.assign(output, { [key]: source[key] });
                    }
                }
            }
        });
    }
    return output;
};

export const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
};

export const isArray = (item) => {
    return (item && typeof item === 'object' && Array.isArray(item));
};

export const removeKey = (k = '', { [k]: _, ...o } = {}) => o


const ivLen = 12; // the IV is always 12 bytes for `AES-GCM"

export const generateKey = (rawKey) => {
    if (!window.crypto || !window.crypto.subtle) {
        return new Promise((resolve, reject) => {
            reject('Agent doesn\'t support to generate key');
        });
    }
    return window.crypto.subtle.importKey(
        'raw'
        , rawKey,
        {
            name: 'AES-GCM',
            length: 256
        },
        true,
        ['encrypt', 'decrypt']
    );
};


export const encryptMessage = ({ message, key }) => {
    // a public value that should be generated for changes each time
    const initializationVector = new Uint8Array(ivLen);
    crypto.getRandomValues(initializationVector);

    let encoded = getMessageEncoding({ message });
    return window.crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: initializationVector
        },
        key,
        encoded
    ).then((encrypted) => {
        const ciphered = joinIvAndData(initializationVector, new Uint8Array(encrypted));
        let base64 = bufferToBase64(ciphered);

        while (base64.length % 4) {
            base64 += '=';
        }
        return base64;
    })
};

function getMessageEncoding({ message }) {
    let enc = new TextEncoder();
    return enc.encode(message);
}

// convert base64 to unicode (utf-8) format:
export const b64DecodeUnicode = (base64Str) => {
    // Going backwards: from byte stream, to percent-encoding, to original string.
    return decodeURIComponent(atob(base64Str).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

// merge initialization vector with encrypted data to form single bytes array[iv+data]
function joinIvAndData(iv, data) {
    let buf = new Uint8Array(iv.length + data.length);
    Array.prototype.forEach.call(iv, (byte, i) => {
        buf[i] = byte;
    });
    Array.prototype.forEach.call(data, (byte, i) => {
        buf[ivLen + i] = byte;
    });
    return buf;
}

export const bufferToBase64 = (buf) => {
    let binaryToString = Array.prototype.map.call(buf, (ch) => {
        return String.fromCharCode(ch);
    }).join('');

    return btoa(binaryToString);
};

// split initialization vector and encrypted data in parts from single bytes array[iv+data]
const separateIvFromData = (buf) => {
    const iv = new Uint8Array(ivLen);
    let data = new Uint8Array(buf.length - ivLen);
    Array.prototype.forEach.call(buf, (byte, i) => {
        if (i < ivLen) {
            iv[i] = byte;
        } else {
            data[i - ivLen] = byte;
        }
    });
    return { iv, data };
};

export const decryptMessage = (base64Data, key) => {
    const bytes = base64ToBuffer(base64Data);
    const parts = separateIvFromData(bytes);
    return window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: parts.iv }
        , key
        , parts.data
    ).then((decrypted) => {
        let base64 = bufferToBase64(new Uint8Array(decrypted));
        while (base64.length % 4) {
            base64 += '=';
        }
        return base64;
    });
};

export const base64ToBuffer = (base64) => {
    const binaryToString = atob(base64);
    let buf = new Uint8Array(binaryToString.length);
    Array.prototype.forEach.call(binaryToString, (char, idx) => {
        buf[idx] = char.charCodeAt(0);
    });
    return buf;
};

export const isCryptoSupportedByAgent = () => {
    const crypto = window.crypto || window.msCrypto || {
        getRandomValues: array => {
            for (let i = 0, l = array.length; i < l; i++) {
                array[i] = Math.floor(Math.random() * 256);
            }
            return array;
        }
    };
    if (crypto.getRandomValues === undefined) {
        throw new Error('crypto is not supported on this browser');
    }
    return true;
};

export const isHashFragmentExists = (url) => {
    return (url.indexOf('#') !== -1);
};

export const claimsArrayToRequestParameter = (claims) => {
    let claimsJson = {};
    claims.forEach(claim => {
        if (claim.usage && claim.name) {
            let formattedClaim = (!claim.values || claim.values.length === 0) && !claim.required ? null : {};
            if (claim.required) {
                formattedClaim.essential = true;
            }

            if (claim.values && claim.values.length === 1) {
                formattedClaim.value = claim.values[0];
            } else if (claim.values && claim.values.length > 1) {
                formattedClaim.values = claim.values
            }


            claim.usage.forEach(usage => {
                claimsJson[usage] = claimsJson[usage] ? claimsJson[usage] : {};
                claimsJson[usage][claim.name] = formattedClaim;
            });
        }
    });
    return claimsJson;
};


export const isQueryParamsExists = (query) => {
    return !!Object.keys(parseQueryParams(query)).length > 0;
};

export const parseQueryParams = (query) => {
    let queryParams = {};
    if (query.indexOf('&') !== -1) {
        const queryArray = query.split('&');
        for (let i = 0; i < queryArray.length; i++) {
            const [key, val] = queryArray[i].split('=');
            queryParams[key] = val || '';
        }
        return queryParams;
    }
    return queryParams;
};

export const truncateCharacters = (str, truncationLength) => {
    let strLen = str.length;
    const placeholder = '.....';
    if (strLen > (truncationLength * 2) + placeholder.length) {
        const firstHalf = str.substr(0, truncationLength);
        const lastHalf = str.substr(strLen - truncationLength);
        return firstHalf + placeholder + lastHalf;
    } else {
        return str;
    }
};

export const convertToQueryParams = (params) => {
    if (Object.keys(params).length > 0) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    return '';
};


export const getSharedConfigType = (config) => {
    if (config) {
        const content = JSON.parse(config);
        if (Object.prototype.hasOwnProperty.call(content, 'collections') || Object.prototype.hasOwnProperty.call(content, 'environments')) {
            return IMPORT_TYPE.IMPORT_APP_STATE;
        } else if (
            Object.prototype.hasOwnProperty.call(content, 'client_id') ||
            Object.prototype.hasOwnProperty.call(content, 'client_name') ||
            Object.prototype.hasOwnProperty.call(content, 'client_secret') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_code_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_implicit_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_hybrid_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_client_credentials_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_ropc_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_introspect') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_device_flow'),
            Object.prototype.hasOwnProperty.call(content, 'can_do_jwt_assertion_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_do_token_exchange_flow') ||
            Object.prototype.hasOwnProperty.call(content, 'can_be_framed'),
                Object.prototype.hasOwnProperty.call(content, 'scope')
        ) {
            return IMPORT_TYPE.IMPORT_CLIENTS;
        } else {
            return IMPORT_TYPE.IMPORT_INVALID
        }
    }
};

export const figureTypeFromValue = (value) => {
    if (typeof value === 'number') {
        return 'int';
    } else if (typeof value === 'object') {
        return 'json'
    }
    return 'text'
};

export const typeForKnownClaims = (claim) => {
    if (claim === 'exp' || claim === 'iat' || claim === 'nbf' || claim === 'auth_time') {
        return 'date';
    }
    return 'text'
};

export const generateKeyPair = async (alg) => {
    let keyPair, privateKey, publicKey;
    switch (alg) {
        case 'HS256':
            publicKey = privateKey = generateRandomString(32);
            break;
        case 'HS384':
            publicKey = privateKey = generateRandomString(48);
            break;
        case 'HS512':
            publicKey = privateKey = generateRandomString(64);
            break;
        case 'RS256':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'RSASSA-PKCS1-v1_5',
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: 'SHA-256'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'RS384':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'RSASSA-PKCS1-v1_5',
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: 'SHA-384'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'RS512':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'RSASSA-PKCS1-v1_5',
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: 'SHA-512'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'ES256':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'ECDSA',
                    namedCurve: 'P-256'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'ES384':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'ECDSA',
                    namedCurve: 'P-384'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'ES512':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'ECDSA',
                    namedCurve: 'P-521'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'PS256':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'RSA-PSS',
                    // Consider using a 4096-bit key for systems that require long-term security
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: 'SHA-256'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
        case 'PS384':
            keyPair = await crypto.subtle.generateKey(
                {
                    name: 'RSA-PSS',
                    // Consider using a 4096-bit key for systems that require long-term security
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: 'SHA-384'
                },
                true,
                ['sign', 'verify']
            );
            publicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey);
            privateKey = await crypto.subtle.exportKey('jwk', keyPair.privateKey);
            break;
    }
    return [publicKey, privateKey]
}

export const resolvePlayGroundIcon = (workspace, reverse = false) => {
    switch (workspace.playground) {
        case PLAYGROUND.CURITY:
            return <CurityIcon/>
        case PLAYGROUND.SIGNICAT:
            return <SignicatIcon/>
        default:
            return '';
    }
}

export const removeLeadingZeros = (jwk) => {
    const jwkWithoutLeadingZeros = jwk;
    if (!isEmptyObject(jwk)) {
        for (const [key, value] of Object.entries(jwk)) {
            const decoded = util.base64url.decode(value);
            if (decoded.length > 1 && decoded[0] === 0) {
                jwkWithoutLeadingZeros[key] = util.base64url.encode(decoded.slice(1));
                return removeLeadingZeros(jwkWithoutLeadingZeros)
            } else {
                jwkWithoutLeadingZeros[key] = value;
            }
        }
    }
    return jwkWithoutLeadingZeros;
}

const shouldNotIncludeInDisclosures = randomTimeId()
export const disclosedClaimsToMap = (disclosedClaims) => {
    if (!disclosedClaims) {
        return null;
    }
    let disclosedClaimsJson = {}
    disclosedClaims.properties.forEach(disclosedClaim => {
        const parsedPropertyValue = parseSdValue(disclosedClaim.value, disclosedClaim.disclosure !== null);
        if (parsedPropertyValue !== shouldNotIncludeInDisclosures) {
            disclosedClaimsJson[disclosedClaim.name] = parsedPropertyValue;
        }
    })

    return isEmptyObject(disclosedClaimsJson) ? null : disclosedClaimsJson;
}


const parseSdValue = (sdValue, ignoreDisclosureCheck) => {

    if (sdValue instanceof SdPrimitive && (sdValue.disclosure || ignoreDisclosureCheck)) {
        return sdValue.value;
    }

    if (sdValue instanceof SdObject) {
        const returnObject = {};
        sdValue.properties.forEach(property => {
            const newIgnoreDisclosureCheck = ignoreDisclosureCheck || property.disclosure;
            const parsedPropertyValue = parseSdValue(property.value, newIgnoreDisclosureCheck)
            if (parsedPropertyValue !== shouldNotIncludeInDisclosures) {
                returnObject[property.name] = parsedPropertyValue;
            }
        })
        return isEmptyObject(returnObject) && !ignoreDisclosureCheck ? shouldNotIncludeInDisclosures : returnObject;
    }

    if (sdValue instanceof SdArray) {
        const returnArray = [];
        sdValue.values.forEach(arrayEntry => {
            const newIgnoreDisclosureCheck = ignoreDisclosureCheck || arrayEntry.disclosure;
            const parsedArrayEntry = parseSdValue(arrayEntry.value, newIgnoreDisclosureCheck)
            if (parsedArrayEntry !== shouldNotIncludeInDisclosures) {
                returnArray.push(parsedArrayEntry)
            }
        })
        return returnArray.length === 0 && !ignoreDisclosureCheck ? shouldNotIncludeInDisclosures : returnArray;
    }

    return shouldNotIncludeInDisclosures;
}


export const copyToClipboard = (str) => {
	navigator.clipboard.writeText(str).then(
		() => {},
		(err) => {
			console.error('Could not copy text: ', err)
		}
	)
}
