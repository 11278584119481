/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import CopyToClipboard from '../CopyToClipboard';
import FoldButton from '../FoldButton';
import ResponseStatusCode from '../sidebar/response/ResponseStatusCode';
import ResponseBody from '../sidebar/response/ResponseBody';
import ResponseHeaders from '../sidebar/response/ResponseHeaders';
import { oauthResponseTypes } from '../../util/appConstants';

class ServerResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFolded: true
        };
    }

    handleFold = () => {
        if (this.props.response) {
            this.setState((state) => ({
                isFolded: !state.isFolded
            }));
        }
    };

    render() {
        let baseUrl, queryPairItems, hashPairItems, jsonBody, textToCopy;
        if (this.props.response && this.props.response.type === oauthResponseTypes.url && this.props.response.url) {


            try {
                const url = new URL(this.props.response.url);
                baseUrl = url.origin + url.pathname;

                const queryParams = url.search.substr(1).split('&');
                const queryArray = [];

                queryParams.forEach((d) => {
                    queryArray.push(d.split('='))
                });

                queryPairItems = queryArray.map((pair, i) =>
                    pair.length > 1 &&
                    <React.Fragment key={pair.toString()}>
                        <span className="key">{i === 0 ? '?' : '&'}{pair[0]}</span>=
                        <span className="value">{pair[1]}</span><br/>
                    </React.Fragment>
                );

                const hashParams = url.hash.substr(1).split('&');
                const hashArray = [];


                hashParams.forEach((d) => {
                    hashArray.push(d.split('='))
                });

                hashPairItems = hashArray.map((pair, i) =>
                    pair.length > 1 &&
                    <React.Fragment key={pair.toString()}>
                        <span className="key">{i === 0 ? '#' : '&'}{pair[0]}</span>=
                        <span className="value">{pair[1]}</span><br/>
                    </React.Fragment>
                );

            } catch (e) {
                // could not parse url
                baseUrl = this.props.response.url;
            }

            textToCopy = this.props.response.url;
        }

        if (this.props.response && this.props.response.type === oauthResponseTypes.json) {
            jsonBody = JSON.parse(this.props.response.body);
            textToCopy = this.props.response.body;
        }

        const dimmed = !this.props.response && 'fieldset-dimmed';

        return (
            <fieldset
                className={`fieldset fieldset-step fieldset-step-3 fieldset-server-response mt2 ${dimmed} ${(this.state.isFolded && 'fieldset-folded' )}`}>
                <div className="flex justify-between" onClick={this.handleFold}>
                    <h3 className={`primary ${(this.state.isFolded ? 'm0' : 'mt0 mb2')}`}>Server Response</h3>
                    {this.props.response && <FoldButton isFolded={this.state.isFolded}/> }
                </div>

                <div className="tools-form-token tools-form-token-active">

                    {this.props.response && this.props.response.type === oauthResponseTypes.url &&
                    <>
                        <header className="tools-box-header tools-box-header-token">
                            <div className="flex flex-center flex-justify">
                                <div className="white">Redirected to:</div>
                                <CopyToClipboard text={textToCopy} className="mr1"/>
                            </div>
                        </header>
                        <pre className="field field-dark field-mono col-12">
                            {baseUrl}<br/>
                            {queryPairItems}
                            {hashPairItems}
                        </pre>
                    </>
                    }

                    {this.props.response && this.props.response.type === oauthResponseTypes.json &&
                    <>
                        <ResponseStatusCode responseStatusCode={this.props.response.status}/>
                        <ResponseBody copyText={textToCopy}
                                      responseBody={jsonBody}
                                      responseHeaders={this.props.response.headers}/>
                        <ResponseHeaders responseHeaders={this.props.response.headers}/>
                    </>
                    }
                </div>
            </fieldset>
        );
    }
}

export default ServerResponse;
