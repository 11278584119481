/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { randomTimeId } from '../../../util/util';

class Nonce extends React.Component {

    createNonce = (event) => {
        const nonceValue = randomTimeId();
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('nonce', nonceValue);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
        event.currentTarget.blur();
    };

    setNonce = (event) => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('nonce', event.currentTarget.value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    clearNonce = (event) => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('nonce', null);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
        event.currentTarget.blur();
    };

    render() {
        const nonce = this.props.collection.parameters.nonce ? this.props.collection.parameters.nonce : '';

        const button = nonce ?
            <button className="button button-small button-primary-outline button-input" onClick={this.clearNonce}>Clear</button> :
            <button className="button button-small button-primary-outline button-input" onClick={this.createNonce}>Generate</button>;

        return (
            <div className="sm-flex flex-center flex-wrap flex-gap-2">
                <div className="flex-auto">
                    <input className="field col-12" type="text" min="0" placeholder="Nonce" value={nonce}
                           onChange={this.setNonce}
                           autoComplete="off"
                           data-lpignore="true"
                           spellCheck="false"
                    />
                </div>

                <div>
                    {button}
                </div>

            </div>
        );
    }
}

export default Nonce;
