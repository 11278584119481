/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import InteractiveFlow from './InteractiveFlow';
import AC, { flows, guides } from '../../util/appConstants';
import Scopes from './settings/Scopes';
import Environments from '../../data/Environments';
import OpenIdConnectSettings from './settings/OpenIdConnectSettings';
import IntrospectionSettings from './settings/IntrospectionSettings';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import StepBox from './StepBox';
import ServerResponse from '../shared/ServerResponse';
import StartUrl from './StartUrl';
import RunButton from './RunButton';
import StartHere from './StartHere';
import ClientCredentials from './settings/ClientCredentials';
import { base64encode, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import CurlRequestPreview from './CurlRequestPreview';
import FlowHeader from './FlowHeader';
import PKCE from './settings/PKCE';
import CallbackUriHelper from './settings/CallbackUriHelper';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import CanBeFramed from './settings/CanBeFramed';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClaimsModal from '../modals/ClaimsModal';
import RarModal from '../modals/RarModal'
import JAR from './settings/JAR';
import PAR from './settings/PAR';
import CopyToClipboard from '../CopyToClipboard';
import ResizablePanels from '../ResizablePanels';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';
import { IconGeneralClose, IconGeneralCode, IconToken } from '@curity-internal/ui-icons-react';

class CodeFlow extends InteractiveFlow {

    constructor(props) {
        super(props, AC.CODE_PATH, 'code');
        this.state.startUrl = '';
        this.state.parRequest = '';
        this.state.auto_redeem_code = !!this.props.collection.parameters.auto_redeem_code;
        this.redeemCodeButtonRef = React.createRef();

        const parameters = this.props.collection.parameters;
        if (parameters.authorization_code && parameters.auto_redeem_code && !parameters.authorization_code_spent) {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('authorization_code_spent', true);

            this.props.updateParameters(this.props.collection.id, updatedParameters);
            this.redeemCode();
        }
    }

    redeemCode = () => {
        if (this.redeemCodeButtonRef.current) {
            this.redeemCodeButtonRef.current.classList.add('button-loading-active', 'button-disabled');
            this.redeemCodeButtonRef.current.blur();
        }
        const currentCollection = this.props.collection;
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        this.props.exchangeCodeForToken(currentCollection, environment, this.redirectUri());
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.redeemCodeButtonRef.current) {
            this.redeemCodeButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
        if (prevProps !== this.props) {
            this.startUrl().then(url => {
                this.setState({ startUrl: url });
            });
            this.getPushedAuthorizationRequest().then(request => {
                this.setState({ parRequest: request });
            });
        }
    }

    componentDidMount() {
        this.getPushedAuthorizationRequest().then(request => {
            this.setState({ parRequest: request });
        });
        super.componentDidMount();
    }

    updateAutoRedeemCode = (event) => {
        this.setState({ auto_redeem_code: event.currentTarget.checked });
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('auto_redeem_code', event.currentTarget.checked);

        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    previewCodeRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const requestParameters = {
            grant_type: 'authorization_code',
            redirect_uri: this.redirectUri(),
            code: this.props.collection.parameters.authorization_code,
            code_verifier: currentCollection.parameters.code_verifier
        };

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }
        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + encodeUrlParameter(requestParameters) + '\''
    };

    getPushedAuthorizationRequest = async () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const requestParameters = await this.constructInteractiveQuery(currentCollection, environment,
            currentCollection.id, this.redirectUri());

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        const params = Object.keys(requestParameters).map((key) => {
            if (typeof requestParameters[key] === 'object') {
                return encodeURIComponent(key) + '=' + JSON.stringify(requestParameters[key])
            } else {
                return encodeURIComponent(key) + '=' + requestParameters[key]
            }
        }).join('&')

        const parEndpoint = environment ? environment.endpoints.pushed_authorization_request_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            parEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + params + '\''
    }

    sendPar = async () => {
        const currentCollection = this.props.collection;
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const requestParameters = await this.constructInteractiveQuery(currentCollection, environment,
            currentCollection.id, this.redirectUri());
        this.props.clearOAuthResponses(currentCollection.id);
        this.props.pushedAuthorizationRequest(currentCollection, environment, requestParameters)
    }

    render() {
        super.render();
        const currentCollection = this.props.collection;
        const parameters = currentCollection.parameters;

        let clientId = null;
        if (parameters.client_id) {
            clientId = parameters.client_id;
        }
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        const parEnabled = currentCollection.parameters.par;

        const enableAuthorizationRequest = (clientId !== null && environment && environment.canDoCodeFlow() &&
            (!parEnabled || !!currentCollection.parameters.par_request_uri));

        const startUrl = (!enableAuthorizationRequest) ? '' : this.state.startUrl;
        const authorizationCode = parameters.authorization_code;
        const authorizationCodeSpent = parameters.authorization_code_spent;

        const enableTokenRequest = !!(enableAuthorizationRequest && authorizationCode)
            || this.state.forceEnableRedeemStep;

        const loginIFrameModal = this.state.showModal
            ?
            <div className="modal modal-is-visible">
                <section className="modal-main" ref={this.setInteractiveFlowModalWrapper}>
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-end">
                            <button className="button button-close button-link" onClick={this.hideModal}>
                                <IconGeneralClose width={18} height={18} />
                            </button>
                        </div>
                    </header>
                    <div className="modal-main-content">
                        <iframe className="login-iframe" src={this.state.loginModalUrl}/>
                    </div>
                </section>
            </div>

            : '';

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">
                  <IconGeneralClose width={18} height={18} />
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}>
                    <IconGeneralClose width={18} height={18} />
                </button>
            </div>;

        const curlRequest = this.previewCodeRequest();

        const parRequest = this.state.parRequest;
        const addToStep = parEnabled ? 1 : 0;

        const disableRunButton = parEnabled && authorizationCodeSpent


        return (
            <React.Fragment>
                {loginIFrameModal}
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            workspace={environment}
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>
                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Enter client ID, client Secret and run a new code flow.'}/>
                            {error}

                            <CallbackUriHelper text={this.interactiveFlowCallbackUrlHelpText()}
                                               updateParameters={this.props.updateParameters}
                                               collection={currentCollection}
                                               showEditButton={true}
                                               defaultUrl={this.defaultRedirectUri()}
                                               url={this.redirectUri()}/>

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={clientId}/>

                                <ClientCredentials
                                    updateParameters={this.props.updateParameters}
                                    updateEnvironment={this.props.updateEnvironment}
                                    collection={currentCollection}
                                    environment={environment}
                                    flow={flows.code}
                                />
                                <div className="sm-flex flex-center flex-wrap flex-gap-2 mt2">
                                    <div>
                                        <Scopes
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    {environment && environment.claims_parameter_supported ?
                                        <div>
                                            <ClaimsModal
                                                updateParameters={this.props.updateParameters}
                                                environment={environment}
                                                collection={currentCollection}
                                                showClaimsModal={this.state.showClaimsModal}
                                                handleClose={this.hideClaimsModal}/>
                                            <button onClick={this.showClaimsModal}
                                                    className="button button-small button-primary-outline button-input">
                                                Add Claims
                                            </button>
                                        </div>
                                        : ''}
                                    <div>
                                        <RarModal
                                            updateParameters={this.props.updateParameters}
                                            collection={currentCollection}
                                            showRarModal={this.state.showRarModal}
                                            handleClose={this.hideRarModal}/>
                                        <button onClick={this.showRarModal}
                                                className="button button-small button-primary-outline button-input">
                                            Add authorization_details
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-auto mt2">
                                    <div className="flex-100">
                                        <PKCE
                                            updateParameters={this.props.updateParameters}
                                            collection={currentCollection}
                                        />
                                    </div>
                                </div>

                                <JAR
                                    updateParameters={this.props.updateParameters}
                                    environment={environment}
                                    collection={currentCollection}
                                />

                                <PAR
                                    updateParameters={this.props.updateParameters}
                                    environment={environment}
                                    collection={currentCollection}
                                />

                                <OpenIdConnectSettings
                                    environment={environment}
                                    collection={currentCollection}
                                    updateParameters={this.props.updateParameters}
                                    hideResponseTypes={true}
                                />

                                <IntrospectionSettings
                                    collection={currentCollection}
                                    environment={environment}
                                    updateEnvironment={this.props.updateEnvironment}
                                    updateParameters={this.props.updateParameters}
                                />
                            </StepBox>

                            {parEnabled && <>
                                <StepBox title={'Pushed Authorization Request'} step={'2'} enabled={true}>
                                    <div className={'flex justify-end'}>
                                        <ExtraQueryParametersModal
                                            updateParameters={this.props.updateParameters}
                                            collection={this.props.collection}
                                            parameter={'par_request_extra_query_parameters'}
                                        />
                                    </div>
                                        <CurlRequestPreview request={parRequest}/>
                                        <RunButton runFlow={this.sendPar} buttonText={'Send'}/>
                                </StepBox>
                                <ServerResponse
                                    response={currentCollection.OAuthResponses.CodeFlowPushedAuthorization}/>
                            </>}

                            <StepBox title={'Start Flow'} step={2 + addToStep} enabled={enableAuthorizationRequest}
                                     tooltip="Define an authorization and token endpoint to start using this flow">

                                <div className={'flex flex-justify'}>
                                    <CanBeFramed
                                        collection={currentCollection}
                                        updateParameters={this.props.updateParameters}/>

                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'authorization_request_extra_query_parameters'}
                                    />
                                </div>

                                <StartUrl startUrl={startUrl}/>
                                <RunButton runFlow={this.runFlow} disabled={disableRunButton}/>
                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.CodeFlowAuthorizationCode}/>

                            <StepBox title={'Redeem Authorization Code'} step={3 + addToStep}
                                     enabled={enableTokenRequest}>

                                <div className="flex flex-auto">
                                    <div className="field field-mono col-12">
                                        {authorizationCode ? <>
                                                <CopyToClipboard text={this.jwtFormatAuthCode(authorizationCode)}
                                                                 className="mr1"/>
                                                {this.isJwt(authorizationCode) ?
                                                    <button onClick={this.createJwtFromAuthCode}
                                                            className={`button button-tiny button-link button-loading blinking`}
                                                            data-tooltip="Create JWT from Authorization Code">
                                                        <IconToken width={24} height={24} />
                                                    </button> : ''
                                                }


                                                <span>code: </span>
                                                <span className="value">{this.jwtFormatAuthCode(authorizationCode)}</span>
                                            </>
                                            :
                                            <div className={'flex'}>
                                                <input type={'text'} className={'field col-8 mr1'}
                                                       placeholder={'Enter the authorization code'}
                                                       onChange={this.updateAuthCode}/>
                                                <button
                                                    className={`button button-small button-success-outline button-input`}
                                                    data-tooltip={'Save'}
                                                    onClick={this.saveAuthCode}
                                                ></button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-auto flex-wrap flex-gap-2 mt1">
                                    <div className="flex-auto">
                                        <div className="custom-checkbox">
                                            <label className="toggle-switch">
                                                <input className="form-control custom-checkbox"
                                                       type="checkbox"
                                                       id="autoredeemcode"
                                                       checked={this.state.auto_redeem_code}
                                                       onChange={this.updateAutoRedeemCode}
                                                />
                                                <div className="toggle-slider round"/>
                                            </label>
                                            <label className="ml1" htmlFor="autoredeemcode">Auto-redeem code</label>
                                        </div>
                                    </div>
                                    <div className="flex-auto">
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'token_endpoint_auth_method'}
                                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div className={'justify-end'}>
                                        <ExtraQueryParametersModal
                                            updateParameters={this.props.updateParameters}
                                            collection={this.props.collection}
                                            parameter={'token_request_extra_query_parameters'}
                                        />
                                    </div>
                                </div>

                                <CurlRequestPreview request={curlRequest}/>

                                <div className="mt2 center">
                                    <button
                                        ref={this.redeemCodeButtonRef}
                                        onClick={this.redeemCode}
                                        data-qa="redeem-code-button"
                                        disabled={!authorizationCode || authorizationCodeSpent
                                            || (this.state.auto_redeem_code && authorizationCodeSpent)}
                                        className={'button button-large button-primary button-run button-loading'}>
                                        <IconGeneralCode width={24} height={24} />
                                        <span>Redeem code</span>
                                    </button>
                                </div>

                                <div className="sm-flex flex-justify flex-start mt2">
                                    <div className="flex-auto">
                                        <div className="request-illustration">
                                            <label className="label block center py2">Fetch Tokens Request</label>
                                            <div className="sm-flex flex-justify flex-center flex-wrap flex-gap-4">

                                                <div
                                                    className="request-illustration-box request-illustration-box-fetch flex-auto">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                         viewBox="0 0 24 24" fill="none" stroke="#006fd1"
                                                         strokeWidth="1"
                                                         strokeLinecap="square" strokeLinejoin="arcs">
                                                        <rect x="2" y="3" width="20" height="14" rx="2" ry="2"/>
                                                        <line x1="8" y1="21" x2="16" y2="21"/>
                                                        <line x1="12" y1="17" x2="12" y2="21"/>
                                                    </svg>

                                                    <span className="block mt1">OAuth Tools</span>
                                                </div>
                                                <div className="request-illustration-lines ml2 mr2 flex-auto">
                                                    <div
                                                        className="request-illustration-lines-line request-illustration-lines-line-code"/>
                                                    <div
                                                        className="request-illustration-lines-line request-illustration-lines-line-token mt3"/>
                                                </div>
                                                <div
                                                    className="request-illustration-box request-illustration-box-server flex-auto">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                         viewBox="0 0 24 24" fill="none" stroke="#27615c"
                                                         strokeWidth="1"
                                                         strokeLinecap="square" strokeLinejoin="arcs">
                                                        <rect x="2" y="2" width="20" height="8" rx="2" ry="2"/>
                                                        <rect x="2" y="14" width="20" height="8" rx="2" ry="2"/>
                                                        <line x1="6" y1="6" x2="6" y2="6"/>
                                                        <line x1="6" y1="18" x2="6" y2="18"/>
                                                    </svg>

                                                    <span className="block mt1">OAuth Server</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.CodeFlowTokens}/>

                        </div>
                    </section>
                    <ReceivedTokensSidebar
                        guide={guides.code}
                        flow={flows.code}
                        showLogoutButton={true}
                        collection={currentCollection}
                        environment={environment}
                        groups={this.props.groups}
                        setTokensOnCollection={this.props.setTokensOnCollection}
                        clearOAuthResponses={this.clearResponsesAndState}
                        updateParameters={this.props.updateParameters}
                        refreshTokens={this.props.refreshTokens}
                        introspectToken={this.props.introspectToken}
                        createAndSelectCollectionWithToken={this.props.createAndSelectCollectionWithToken}
                    />
                </ResizablePanels>
            </React.Fragment>
        )
    }
}

export default CodeFlow;
