/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef } from 'react';
import { tokenPurposes } from '../util/appConstants';
import { IconGeneralRefresh } from '@curity-internal/ui-icons-react';

const RefreshTokenButton = props => {
    const reference = useRef(null);

    const handleRefreshButton = (event) => {
        event.currentTarget.classList.add('button-loading-active', 'button-disabled');
        event.currentTarget.blur();
        props.action();
    };

    useEffect(() => {
        if (reference.current) {
            reference.current.classList.remove('button-loading-active', 'button-disabled');
        }
    })


    const button = (props.token.purpose === tokenPurposes.refresh_token.value) ?
        <button className="button button-link button-tiny button-loading" ref={reference} onClick={handleRefreshButton}>
                <span data-tooltip={'Refresh tokens'}>
                    <IconGeneralRefresh width={24} height={24} />
                </span>
        </button>
        : '';

    return (<>{button}</>);

}

export default RefreshTokenButton;
