/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Scopes from './settings/Scopes';
import IntrospectionSettings from './settings/IntrospectionSettings';
import Environments from '../../data/Environments';
import StepBox from './StepBox';
import RunButton from './RunButton';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import ClientCredentials from './settings/ClientCredentials';
import StartHere from './StartHere';
import { flows, guides } from '../../util/appConstants';
import { base64encode, claimsArrayToRequestParameter, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import CurlRequestPreview from './CurlRequestPreview';
import Nonce from './settings/Nonce';
import DeviceCode from './DeviceCode';
import FlowHeader from './FlowHeader';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClaimsModal from '../modals/ClaimsModal';
import { isEmpty } from '../../util/validationUtils';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';
import { IconGeneralClose } from '@curity-internal/ui-icons-react';

class DeviceFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedToken: null,
            showStateModal: false
        }
    }

    hideClaimsModal = () => {
        this.setState({ showClaimsModal: false });
        document.querySelector('html').style.overflow = '';
    };

    showClaimsModal = () => {
        this.setState({ showClaimsModal: true });
        document.querySelector('html').style.overflow = '';
    };

    previewDeviceEndpointRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const scopeList = (this.props.collection.parameters.scopes) ?
            this.props.collection.parameters.scopes.map((scope) => scope.value) : [];
        const scope = (scopeList.length > 0) ? scopeList.join(' ') : null;
        const requestParameters = {
            scope,
            claims: !isEmpty(currentCollection.parameters.claims) ?
                JSON.stringify(claimsArrayToRequestParameter(currentCollection.parameters.claims)) : null,
            nonce: currentCollection.parameters.nonce
        };

        let authHeader = '';
        if (!currentCollection.parameters.device_authorization_endpoint_auth_method || currentCollection.parameters.device_authorization_endpoint_auth_method === 'client_secret_basic') {
            authHeader = "-H 'Authorization: Basic " + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + "' \\\n";
        }
        if (currentCollection.parameters.device_authorization_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        currentCollection.parameters.request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const deviceEndpoint = environment ? environment.endpoints.device_authorization_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            deviceEndpoint + ' \\\n' +
            authHeader +
            "-H 'Content-Type: application/x-www-form-urlencoded' \\\n" +
            "-d '" + encodeUrlParameter(requestParameters) + "'"
    };

    callDeviceEndpoint = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        this.props.deviceEndpointRequest(this.props.collection, environment);

        let updatedParameters = this.props.collection.parameters;
        updatedParameters = updatedParameters.withUpdatedValue('authorization_code_spent', false);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    previewTokenEndpointRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const requestParameters = {
            grant_type: flows.device.grant_type,
            device_code: this.props.collection.parameters.device_device_code
        };

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = "-H 'Authorization: Basic " + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + "' \\\n";
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            "-H 'Content-Type: application/x-www-form-urlencoded' \\\n" +
            "-d '" + encodeUrlParameter(requestParameters) + "'"
    };

    tokenEndpointRequestParameters = () => {
        const requestParameters = {
            grant_type: flows.device.grant_type,
            device_code: this.props.collection.parameters.device_device_code
        };

        if (this.props.collection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = this.props.collection.parameters.client_id;
            requestParameters.client_secret = this.props.collection.parameters.client_secret || '';
        }
        this.props.collection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        return requestParameters;
    };

    pollTokenEndpoint = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        this.props.tokenEndpointRequest(this.props.collection, environment, this.tokenEndpointRequestParameters(), true, 'DeviceFlowPollingResponse');
        window.scrollTo(0, 0);
    };

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const currentCollection = this.props.collection;

        let clientId = null;
        if (currentCollection.parameters.client_id) {
            clientId = currentCollection.parameters.client_id;
        }
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const enableStep2 = !!(clientId !== null && environment && environment.canDoDevice());
        const enableStep3 = enableStep2 && !!currentCollection.parameters.device_device_code;

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">

                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><IconGeneralClose width={18} height={18} />
                </button>
            </div>;

        const deviceEndpointRequest = this.previewDeviceEndpointRequest();

        const tokenEndpointRequest = this.previewTokenEndpointRequest();


        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>
                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Enter client ID, client Secret and run a new Device flow.'}/>
                            {error}

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={clientId}/>

                                <ClientCredentials
                                    updateParameters={this.props.updateParameters}
                                    updateEnvironment={this.props.updateEnvironment}
                                    collection={currentCollection}
                                    environment={environment}
                                    flow={flows.device}
                                />

                                <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                    <div className="flex-auto">

                                        <Scopes
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    {environment && environment.claims_parameter_supported ?
                                        <div className="flex-auto">
                                            <ClaimsModal
                                                updateParameters={this.props.updateParameters}
                                                environment={environment}
                                                collection={currentCollection}
                                                showClaimsModal={this.state.showClaimsModal}
                                                handleClose={this.hideClaimsModal}/>
                                            <button onClick={this.showClaimsModal}
                                                    className="button button-small button-primary-outline button-input">
                                                Add Claims
                                            </button>
                                        </div>
                                        : ''}


                                </div>
                                <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                    <div className="flex-auto">
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'device_authorization_endpoint_auth_method'}
                                            serverConfigFrom={'device_authorization_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <Nonce collection={currentCollection} environment={this.props.environment}
                                            updateParameters={this.props.updateParameters}/>
                                    </div>
                                </div>
                                <IntrospectionSettings
                                    collection={currentCollection}
                                    environment={environment}
                                    updateEnvironment={this.props.updateEnvironment}
                                    updateParameters={this.props.updateParameters}
                                />
                            </StepBox>

                            <StepBox title={'Call device endpoint'} step={'2'} enabled={enableStep2}>
                                <div className={'flex justify-end'}>
                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'request_extra_query_parameters'}
                                    />
                                </div>
                                <CurlRequestPreview request={deviceEndpointRequest}/>
                                <RunButton runFlow={this.callDeviceEndpoint}/>
                            </StepBox>

                            <ServerResponse
                                response={currentCollection.OAuthResponses.DeviceFlowAuthorizationResponse}/>

                            <StepBox title={'Authenticate the user'} step={'3'} enabled={enableStep3}>
                                <DeviceCode collection={currentCollection}
                                            disabled={this.props.collection.parameters.authorization_code_spent}/>

                            </StepBox>


                            <StepBox title={'Poll the token endpoint'} step={'4'} enabled={enableStep3}>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-50">
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'token_endpoint_auth_method'}
                                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div className={'justify-end'}>
                                        <ExtraQueryParametersModal
                                            updateParameters={this.props.updateParameters}
                                            collection={this.props.collection}
                                            parameter={'token_request_extra_query_parameters'}
                                        />
                                    </div>
                                </div>

                                <CurlRequestPreview request={tokenEndpointRequest}/>

                                <div className="mt2">
                                    <button
                                        onClick={this.pollTokenEndpoint}
                                        disabled={this.props.collection.parameters.authorization_code_spent}
                                        className={'button button-medium button-primary button-run button-fullwidth button-loading'}>
                                        <span>Poll</span>
                                    </button>
                                </div>

                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.DeviceFlowPollingResponse}/>

                        </div>
                    </section>

                    <ReceivedTokensSidebar
                        guide={guides.device}
                        flow={flows.device}
                        collection={currentCollection}
                        environment={environment}
                        groups={this.props.groups}
                        clearOAuthResponses={this.props.clearOAuthResponses}
                        setTokensOnCollection={this.props.setTokensOnCollection}
                        updateParameters={this.props.updateParameters}
                        refreshTokens={this.props.refreshTokens}
                        introspectToken={this.props.introspectToken}
                        createAndSelectCollectionWithToken={this.props.createAndSelectCollectionWithToken}
                    />
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default DeviceFlow;
