/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralInfo } from '@curity-internal/ui-icons-react';
import React from 'react';

class DCRToggle extends React.Component {

    handleChange = (event) => {
        const currentCollection = this.props.collection;

        const updatedEnvironment = currentCollection.parameters.withUpdatedDcrParameters(this.props.field,
            event.currentTarget.checked);
        this.props.updateParameters(currentCollection.id, updatedEnvironment);
    };

    render() {
        const field = this.props.field;
        const checked = this.props.collection.parameters.dcr_parameters[field];

        const tooltip = this.props.tooltip ? <span
            data-tooltip={this.props.tooltip}
            data-tooltip-long
            className="tooltip-holder inline-block ml1 mr1">
                                        <IconGeneralInfo width={16} height={16} />
                                    </span> : '';
        return (
            <div className="custom-checkbox">
                <label className="toggle-switch">
                    <input className="form-control custom-checkbox"
                           type="checkbox"
                           id={field}
                           name={field}
                           checked={checked}
                           onChange={this.handleChange}
                    />
                    <div className="toggle-slider round"/>
                </label>
                <label className="ml1" htmlFor={field}>{this.props.label}{tooltip}</label>
            </div>
        );
    }
}

export default DCRToggle
