/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { randomTimeId } from '../../../util/util';
import Password from './Password';
import Username from './Username';
import TrickAutoCompleteForms from '../../TrickAutoCompleteForms';

class UsernamePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    addClientInEnvironment = () => {
        const newClientId = randomTimeId();
        const clientSettings = {};
        clientSettings.client_id = this.props.collection.parameters.client_id;
        const updatedEnvironment = this.props.environment.withClient(newClientId, clientSettings);
        this.props.updateEnvironment(updatedEnvironment);

        this.setState({ showModal: !this.state.showModal });
    };

    render() {
        const environment = this.props.environment;
        const collection = this.props.collection;

        return (
            <React.Fragment>
                <form autoComplete="off">
                    <TrickAutoCompleteForms/>

                    <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                        <div className="flex-auto">
                            <Username
                                updateParameters={this.props.updateParameters}
                                collection={collection}
                                environment={environment}
                                flow={this.props.flow}
                            />
                        </div>
                        <div className="flex-auto">
                            <Password
                                updateParameters={this.props.updateParameters}
                                collection={collection}
                            />
                        </div>

                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default UsernamePassword;
