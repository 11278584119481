/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Parameters from '../../data/Parameters';
import { flows } from '../../util/appConstants';
import { isEmpty } from '../../util/validationUtils';
import { truncateCharacters } from '../../util/util';
import { IconGeneralCheckmark, IconGeneralWarning } from '@curity-internal/ui-icons-react';

const listMarkupOnMissingKid = (isSignatureValid, kid) => {
    if (isSignatureValid) {
        return (<React.Fragment>
            <section className="tools-sidebar-box mt2 p2">
                <div className="alert alert-success">
                    <IconGeneralCheckmark width={18} height={18} />
                    Signature is valid using key ID: {kid}
                </div>
            </section>
        </React.Fragment>)
    }
};

const listMarkup = (isSignatureValid) => {
    if (isSignatureValid) {
        return (<React.Fragment>
            <section className="tools-sidebar-box mt2 p2">
                <div className="alert alert-success">
                    <IconGeneralCheckmark width={18} height={18} />
                    Signature is valid
                </div>
            </section>
        </React.Fragment>)
    } else {
        return (<React.Fragment>
            <section className="tools-sidebar-box mt2 p2">
                <div className="alert alert-warning">
                    <IconGeneralWarning width={18} height={18} />
                    Could not verify signature
                </div>
            </section>
        </React.Fragment>)
    }
};

const listWarningMarkup = (text) => {
    return (
        <React.Fragment>
            <section className="tools-sidebar-box mt2 p2">
                <div className="alert alert-warning">
                    <IconGeneralWarning width={18} height={18} />
                    {text}
                </div>
            </section>
        </React.Fragment>
    )
};


const TokenValidationSignatureData = (props) => {

    const collection = props.collection;
    const validation = props.token.validation_data;
    const signatureHeader = props.token.decoded_token.header;
    const algorithm = signatureHeader ? signatureHeader.alg : null;
    let signatureMarkup;

    const isSignatureValid = validation.signature && validation.signature.value;
    const isMatchingKeyExists = validation.signature && validation.signature.key;
    const isKeyEmpty = (isEmpty(props.token.validation_key) || isEmpty(props.token.validation_key.pem));

    if (collection && algorithm === 'none' &&
        (collection.flow === flows.code.id || collection.flow === flows.hybrid.id
            || collection.flow === flows.implicit.id)) {
        const { parameters } = collection;
        const params = new Parameters(parameters);
        const scopes = params.scopes;
        const isOpenIdScopeExists = scopes.find((scope) => scope.value === 'openid');

        if (isOpenIdScopeExists) {
            signatureMarkup = listWarningMarkup('No signature included');
        }
    } else if (algorithm !== 'none' && !isSignatureValid && isKeyEmpty && !isEmpty(props.token.value)) {
        signatureMarkup = listWarningMarkup('Could not derive key from environment');
    } else if (algorithm !== 'none' && isSignatureValid) {
        if (isMatchingKeyExists) {
            const { kid } = isMatchingKeyExists.jwk;
            const truncatedKid = truncateCharacters(kid, 5);
            signatureMarkup = listMarkupOnMissingKid(isSignatureValid, truncatedKid);
        } else {
            signatureMarkup = listMarkup(isSignatureValid);
        }
    } else {
        signatureMarkup = listMarkup(false)
    }

    return (
        <React.Fragment>
            {signatureMarkup}
        </React.Fragment>
    )
}

export default TokenValidationSignatureData;
