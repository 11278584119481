/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import InteractiveFlowCallback from './InteractiveFlowCallback';
import Collection from '../../data/Collection';
import Token from '../../data/Token';
import { oauthResponseTypes, tokenPurposes } from '../../util/appConstants';
import Environments from '../../data/Environments';
import { splitParams } from '../../util/util';

class HybridFlowCallback extends InteractiveFlowCallback {
    // noinspection DuplicatedCode
    componentDidMount() {
        let params= {};
        if (this.props.query) {
            const query = this.props.query.replace('?', '');
            params = splitParams(query);
        } else if (this.props.hash) {
            const fragment = this.props.hash.replace('#', '');
            params = splitParams(fragment);
        }

        const collectionId = params.state;
        if (collectionId) {
            const collection = Collection.getCollectionById(collectionId, this.props.collections);
            this.props.setOAuthResponseOnCollection(collectionId, {
                type: oauthResponseTypes.url,
                url: this.props.callbackUrl
            }, 'CodeFlowAuthorizationCode');


            const authorizationCode = params.code ? decodeURIComponent(params.code) : null;
            const sessionState = params.session_state ? decodeURIComponent(params.session_state) : null;

            const errorMessage = params['error'] ? decodeURIComponent(params['error']) : null;
            const errorDescription = Object.prototype.hasOwnProperty.call(params, 'error_description') ? decodeURIComponent(params.error_description) : null;
            const accessToken = params.access_token ? decodeURIComponent(params.access_token) : null;
            const idToken = params.id_token ? decodeURIComponent(params.id_token) : null;

            const error = errorMessage ? 'Call to authorize endpoint failed. ' + errorMessage + ': ' + errorDescription : null;
            const receivedTokens = (accessToken || idToken) ? { access_token: accessToken, id_token: idToken } : null;

            if (collection) {
                const parameters = collection.parameters;
                if (parameters.can_be_framed && FEATURE_FRAMING_ENABLED) {
                    const data = {
                        hideModal: true,
                        authorizationCode,
                        session_state: sessionState,
                        tokens: receivedTokens,
                        error,
                        callbackUrl: this.props.callbackUrl
                    };
                    window.parent.postMessage(data, window.location.origin);
                } else {
                    let tokens = [];
                    let keys = [];
                    if (receivedTokens) {

                        if (accessToken) {
                            tokens.push(Token.createNewToken({
                                purpose: tokenPurposes.access_token.value,
                                name: 'frontend',
                                value: accessToken
                            }));
                        }
                        if (idToken) {
                            tokens.push(Token.createNewToken({
                                purpose: tokenPurposes.id_token.value,
                                name: 'frontend',
                                value: idToken
                            }));
                        }
                        const environments = Environments.create(this.props.environments);
                        const environment = environments.getEnvironment(this.props.collections[collectionId].provider);
                        //Update collection
                        keys = (environment.jwks !== undefined) ? environment.jwks : [];
                    }
                    if (authorizationCode) {
                        const updatedParameters = parameters
                            .withUpdatedValue('authorization_code', authorizationCode)
                            .withUpdatedValue('session_state', sessionState)
                            .withUpdatedValue('authorization_code_spent', false);

                        this.props.updateParameters(collectionId, updatedParameters);
                    }

                    this.props.setTokensOnCollection(collectionId, tokens, keys, error);
                }
            }
        }
    }
}

export default HybridFlowCallback;
