/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import SingleValueSetting from './SingleValueSetting';

class Username extends SingleValueSetting {

    constructor(props) {
        super('username', props);

    }

    render() {
        const username = this.props.collection.parameters.username;


        return (
            <div className="field-group flex-auto">
                <input className="field col-12"
                       placeholder="Enter username"
                       name="username"
                       type="text"
                       autoComplete="off"
                       data-lpignore="true"
                       spellCheck="false"
                       onChange={this.updateValue}
                       value={(username) ? username : ''}
                />
            </div>)
    }

}

export default Username;
