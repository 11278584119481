/*
 * Copyright (C) 2020 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { Provider } from 'react-redux';
import store from './Store';
import App from './App';
import CreateDemoFlow from './CreateDemoFlow';

function CreateDemoFlowContainer(props) {


  return (
    <Provider store={store}>
      <App>
        <CreateDemoFlow {...props}/>
      </App>
    </Provider>
  )

}

export default CreateDemoFlowContainer;
