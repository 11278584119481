/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from '../util/analytics';
import OAuthToolsIcon from './icons/OAuthToolsIcon'
import CurityLogo from './icons/CurityLogo';
import { IconGeneralArrowForward, IconGeneralPlay, IconGeneralQuestionMark } from '@curity-internal/ui-icons-react';

const HeaderStripe = props => {
  const ref = useRef(null);
  const [showVideo, setShowVideo] = useState(null)

  const handleDoubleClick = () => {
    if (window && window.nodeRequire) {
      const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
      ipcRenderer.send('toggleMaximize', {})
    }
  };

  const toggleVideo = (e) => {
    e.preventDefault(); // avoid "#" on URL
    logEvent('Video', 'clicked', 'Clicked video link in header');
    setShowVideo(true)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowVideo(false);
      }
    };

    const escFunction = (event) => {
      if (event.keyCode === 27) {
        setShowVideo(false);
      }
    };

    if (showVideo) {
      document.addEventListener('mousedown', handleClickOutside, false);
      document.addEventListener('keydown', escFunction, false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
      document.removeEventListener('keydown', escFunction, false);
    }
  }, [showVideo])

    return (
      <div
        className="header-stripe flex flex-center justify-between px1"
        onDoubleClick={handleDoubleClick}
      >
        <div className="header-presentation flex justify-between">

          <Link to="/" className="header-logo white">
            <OAuthToolsIcon/>
            OAuth Tools
          </Link>
          <div
            className={'video-screen ' + (showVideo ? 'active' : '')}
          >
            <div>
              {showVideo && (
                <div className="video-wrapper video-wrapper-16-9 mt3 mb3" ref={ref}>
                  <iframe
                    src="https://www.youtube.com/embed/Kr0SECFMoU4"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <nav role="navigation">

        <a href="#" onClick={toggleVideo} className="inline-flex flex-center flex-gap-1">
            <IconGeneralPlay width={16} height={16}/>
          Getting started
        </a>


          {!IS_ELECTRON_BUILD && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.curity.io/oauth-tools?utm_source=oauth.tools&utm_medium=Link&utm_content=header"
              className="flex flex-center flex-gap-1"
            >
              <IconGeneralArrowForward width={16} height={16} />
              OAuth Tools for macOS, Windows and Linux
            </a>
          )}
          {!IS_ELECTRON_BUILD &&
            <Link to="/about" className="flex flex-center flex-gap-1">
              <IconGeneralQuestionMark width={16} height={16}/>
              About
            </Link>
          }
        </nav>



        <div className="header-service-from">
          {IS_ELECTRON_BUILD ? (
            <a
                className="flex flex-center justify-center ml3"
                href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=Header"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Curity.io"
                >
                <small className="flex-auto">A learning tool from</small>
                <CurityLogo
                    title="Curity"
                    color="white"
                    className="header-powered-by"
                    />
            </a>
          ) : (
            <div className={'inline-flex flex-center flex-gap-2'}>
              <a
              className="flex flex-center justify-center"
              href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=Header"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit Curity.io"
              >
              <small className="flex-auto">A service from</small>
              <CurityLogo
                  title="Curity"
                  color="white"
                  className="header-powered-by"
                  />
              </a>
              </div>
          )}
        </div>
      </div>
    );
}
export default HeaderStripe;
