/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const NoEnvironmentSelected = () => {
    return (
        <div className="flex flex-center justify-center py2 lg-py4">
            <p className="m0">No Environment selected</p>
        </div>
    )
};

export default NoEnvironmentSelected;
