/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralSearch } from '@curity-internal/ui-icons-react';
import React, { useEffect, useRef } from 'react';

const IntrospectButton = props => {
    const reference = useRef(null);

    const showSpinner = (event) => {
        event.currentTarget.classList.add('button-loading-active', 'button-disabled');
        event.currentTarget.blur();
        props.action();
    };

    useEffect(() => {
        reference.current.classList.remove('button-loading-active', 'button-disabled');
    })


    return (
        <button onClick={showSpinner} ref={reference} data-qa="introspect-token-button"
                className={`button button-tiny button-link button-loading`} disabled={!props.enabled}>
            <span data-tooltip="Introspect">
            <IconGeneralSearch width={32} height={32} />
            </span>
        </button>
    );

}

export default IntrospectButton;
