/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralArrowBack } from '@curity-internal/ui-icons-react';
import React from 'react'

class StartHere extends React.Component {

    render() {

        const justifyClass = this.props.clientId ? 'justify-end' : 'justify-start';
        const label = this.props.label ? this.props.label : 'Start with client ID';
        return (
            <>
                {!this.props.clientId &&
                <div className={'sm-flex flex-center mb1 mt1 start-here ' + justifyClass}>

                    <div className="pulse-wrapper pulse-wrapper-small mr2" style={{ transform: 'rotate(-90deg)' }}>
                        <span className="pulse-stay"/>
                        <span className="pulse"/>
                        <IconGeneralArrowBack width={18} height={18} color="white"/>
                    </div>

                    <label className="label inline-block">{label}</label>
                </div>
                }
            </>
        )
    }
}

export default StartHere
