/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

const constants = {
    WORKSPACE_CHANGE_COLLECTION: 'WORKSPACE_CHANGE_COLLECTION',
    CREATE_COLLECTION: 'CREATE_COLLECTION',
    CREATE_DEMO_COLLECTION: 'CREATE_DEMO_COLLECTION',
    UPDATE_COLLECTION: 'UPDATE_COLLECTION',
    DUPLICATE_COLLECTION: 'DUPLICATE_COLLECTION',
    IMPORT_COLLECTION: 'IMPORT_COLLECTION',
    SET_ERROR_ON_COLLECTION: 'SET_ERROR_ON_COLLECTION',
    DELETE_COLLECTION: 'DELETE_COLLECTION',
    ADD_COLLECTION_TO_GROUP: 'ADD_COLLECTION_TO_GROUP',
    CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
    SET_GROUP_COLLAPSED: 'SET_GROUP_COLLAPSED',
    RENAME_GROUP: 'RENAME_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',
    IMPORT_GROUP: 'IMPORT_GROUP',
    MERGE_GROUP: 'MERGE_GROUP',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    UPDATE_RESPONSE: 'UPDATE_RESPONSE',
    SET_TOKENS  : 'SET_TOKEN',
    UPDATE_INTROSPECTED_TOKEN  : 'UPDATE_INTROSPECTED_TOKEN',
    UPDATE_PARAMETERS: 'UPDATE_PARAMETERS',
    UPDATE_OAUTH_RESPONSE: 'UPDATE_OAUTH_RESPONSE',
    CLEAR_OAUTH_RESPONSES: 'CLEAR_OAUTH_RESPONSES',
    UPDATE_ENVIRONMENT : 'UPDATE_ENVIRONMENT',
    CREATE_ENVIRONMENT: 'CREATE_ENVIRONMENT',
    CREATE_SIGNICAT_ENVIRONMENT: 'CREATE_SIGNICAT_ENVIRONMENT',
    DELETE_ENVIRONMENT: 'DELETE_ENVIRONMENT',
    IMPORT_ENVIRONMENT: 'IMPORT_ENVIRONMENT',
    MERGE_ENVIRONMENT: 'MERGE_ENVIRONMENT',
    DUPLICATE_ENVIRONMENT: 'DUPLICATE_ENVIRONMENT',
    IMPORT_CLIENT: 'IMPORT_CLIENT',
    CHANGE_SELECTED_ENVIRONMENT: 'CHANGE_SELECTED_ENVIRONMENT',
    SELECT_PROVIDER_FOR_COLLECTION: 'SELECT_PROVIDER_FOR_COLLECTION',
    UPDATE_KEY_FOR_TOKEN: 'UPDATE_KEY_FOR_TOKEN',
    UPDATE_PRIVATE_KEY_FOR_TOKEN: 'UPDATE_PRIVATE_KEY_FOR_TOKEN',
    ERROR_SET : 'ERROR_SET',
    ERROR_CLEAR : 'ERROR_CLEAR',
    DO_NOTHING : 'DO_NOTHING'
};

export default constants;
