/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import TokenValidationBody from './TokenValidationBody';

const OpaqueTokenValidation = (props) => {

    const token = props.token;
    const headerName = props.headerName ? props.headerName : 'Introspected Token';

    return (
        <>
            <TokenValidationBody
                token={token}
                headerName={headerName}
                collection={props.collection}
                environment={props.environment}
            />
        </>
    )

}

export default OpaqueTokenValidation;
