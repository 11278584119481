/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import CheckBoxSetting from './CheckBoxSetting';
import React from 'react';

class SeparateIntrospectionClientSetting extends CheckBoxSetting {
    constructor(props) {
        super('use_application_jwt', props);
        this.state = {
            use_application_jwt: !!this.props.collection.parameters.use_application_jwt
        }
    }

    onInputChange = (event) => {
        this.setState({ use_application_jwt: event.currentTarget.checked });
        this.onChange(event);
    };

    render() {
        return (
            <React.Fragment>
                <div className="custom-checkbox">
                    <label className="toggle-switch">
                        <input className="form-control custom-checkbox"
                               type="checkbox"
                               name="useApplicationJwt"
                               id="useApplicationJwt"
                               checked={(this.state.use_application_jwt)}
                               onChange={this.onInputChange}
                        />
                        <div className="toggle-slider round"/>
                    </label>
                    <label className="ml1" htmlFor="useApplicationJwt">Use <code className="inline-code">Accept:
                        application/jwt</code></label>
                </div>
            </React.Fragment>
        )
    }
}

export default SeparateIntrospectionClientSetting;
