/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import Select from 'react-select';
import { generateRandomString } from '../../../util/util';

class JwtKeyValueTypeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intermediateJsonValue: null
        };
        this.dateField = React.createRef();
        this.timeField = React.createRef();
    }

    updateType(selectedOption) {
        this.props.updateBodyParamValue(selectedOption.value, this.props.pairPosition, 'type');
    }

    generateNonce = (e) => {
        const nonce = generateRandomString(16);
        this.props.updateBodyParamValue(nonce, e.currentTarget.getAttribute('data-id'), 'value')
    };

    updateDateTime = (e) => {
        const dateTime = this.dateField.current.value + ' ' + this.timeField.current.value;
        const epoch = new Date(dateTime).getTime() / 1000;
        this.props.updateBodyParamValue(epoch, this.props.pairPosition, 'value')
    };

    updateJsonValue = (event) => {
        try {
            const newValue = JSON.parse(event.currentTarget.value);
            this.props.updateBodyParamValue(newValue, this.props.pairPosition, 'value');
            this.setState({ intermediateJsonValue: null })
        } catch (e) {
            this.setState({ intermediateJsonValue: event.currentTarget.value })
        }
    };


    render() {
        const types = [
            { value: 'text', label: 'Text' },
            { value: 'int', label: 'Number' },
            { value: 'json', label: 'Object' }
        ];
        if (!this.props.hideDate) {
            types.push({ value: 'date', label: 'Date' });
        }
        if (!this.props.hideNonce) {
            types.push({ value: 'nonce', label: 'Nonce' });
        }


        let date, time, jsonValue;
        if (this.props.type === 'date') {
            try {
                const possibleTimestampInt = parseInt(this.props.value);
                const parsedDate = new Date(possibleTimestampInt * 1000);
                date = parsedDate.toISOString().substr(0, 10);
                time = parsedDate.toISOString().substr(11, 5);
            } catch (e) {
                console.trace('could not parse value as datetime');
                date = '1970-01-01';
                time = '00:00';
            }
        }


        if (this.props.type === 'json') {
            jsonValue = this.state.intermediateJsonValue ?
                this.state.intermediateJsonValue : JSON.stringify(this.props.value);
        }

        return (
            <>
                <div className="flex flex-auto pr1">
                    {!this.props.hideNonce && this.props.type === 'nonce' && (
                        <div className="flex flex-auto">
                            <input className="field col-12" type="text" placeholder="Nonce"
                                   value={this.props.value}
                                   onChange={(e) => {
                                       this.props.updateBodyParamValue(e.currentTarget.value, this.props.pairPosition, 'value');
                                       this.props.updateBodyParamValue('nonce', this.props.pairPosition, 'type');
                                   }
                                   }
                            />
                            <div className={'pl1'}>
                                <button
                                    onClick={this.generateNonce}
                                    data-id={this.props.pairPosition}
                                    className="button button-small button-primary-outline button-input">
                                    Generate
                                </button>
                            </div>
                        </div>
                    )}
                    {!this.props.hideDate && this.props.type === 'date' && (
                        <>
                            <input className="field col-12" type="date" ref={this.dateField}
                                   onChange={this.updateDateTime} defaultValue={date}/>
                            <div className="pl1">
                                <input className="field" type="time" ref={this.timeField}
                                       onChange={this.updateDateTime} defaultValue={time}/>
                            </div>
                        </>
                    )}
                    {(this.props.type === 'text' || this.props.type === undefined) && (
                        <input
                            className="field col-12"
                            type="text"
                            placeholder="Value"
                            value={this.props.value}
                            onChange={(e) => {
                                this.props.updateBodyParamValue(e.currentTarget.value, this.props.pairPosition, 'value');
                            }}
                        />
                    )}
                    {this.props.type === 'int' && (
                        <input
                            className="field col-12"
                            type="number"
                            placeholder="Value"
                            value={this.props.value}
                            onChange={(e) => {
                                this.props.updateBodyParamValue(e.currentTarget.value, this.props.pairPosition, 'value');
                            }}
                        />
                    )}
                    {(this.props.type === 'json') && (
                        <input
                            className={this.state.intermediateJsonValue ? 'field col-12 is-error' : 'field col-12'}
                            type="text"
                            placeholder="Value"
                            value={jsonValue}
                            onChange={this.updateJsonValue}
                        />
                    )}
                    <div className="ml1 flex-30">
                        <Select
                            value={types.filter(({ value }) => value === this.props.type)}
                            onChange={(e) => this.updateType(e)}
                            options={types}
                            placeholder="Select Type..."
                            className="select-container select-container-narrow"
                            classNamePrefix="react-select"
                            id="TypeSelector"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#f2f3f6',
                                    primary: '#626c87'
                                }
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default JwtKeyValueTypeSelector;
