/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { Navigate } from 'react-router';
import Spinner from '../Spinner';

class InteractiveFlowCallback extends React.Component {

    render() {
        const collectionId = this.props.appState.currentCollection;
        const showRedirect = collectionId && this.props.collections[collectionId].parameters.can_be_framed && FEATURE_FRAMING_ENABLED ? '' : <Navigate to="/" />;

        return (
            <React.Fragment>
                <div>

                    <div className="flex flex-center justify-center h100vh">
                        <Spinner size="medium" color="blue"/>
                    </div>

                    {showRedirect}

                </div>
            </React.Fragment>
        )
    }

}

export default InteractiveFlowCallback;
