/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/ext-language_tools';

export class JwtModeHighlight extends window.ace.acequire('ace/mode/text_highlight_rules').TextHighlightRules {
    constructor() {
        super();
        this.$rules = {
            'start': [{
                token: 'jwt-header',
                regex: '^[A-Za-z0-9-_=]+',
                next: 'dot'
            }],
            'dot': [{
                token: 'jwt-dot',
                regex: '\.',
                next: 'payload'
            }],
            'payload': [{
                token: 'jwt-payload',
                regex: '\.[A-Za-z0-9-_=]+',
                next: 'secondDot'
            }],
            'secondDot': [{
                token: 'jwt-dot',
                regex: '\.',
                next: 'signature'
            }],
            'signature': [{
                token: 'jwt-signature',
                regex: '\.[A-Za-z0-9-_=]+',
                next: 'disclosure'
            }],
            'disclosure': [{
                token: 'jwt-dot',
                regex: '~',
                next: 'disclosureData'
            },
                {
                    defaultToken: 'jwt-error'
                }
            ],
            'disclosureData': [{
                token: 'jwt-disclosure',
                regex: '\.[A-Za-z0-9-_=]+',
                next: 'disclosure'
            }]
        };
    }
}

export default class CustomHighlightMode extends window.ace.acequire('ace/mode/java').Mode {
    constructor() {
        super();
        this.HighlightRules = JwtModeHighlight;
    }
}

