/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { IconGeneralExternalLink } from '@curity-internal/ui-icons-react';

class DeviceCode extends React.Component {

    render() {
        const qrCode = !this.props.collection.parameters.device_qr_code ? '' :
            <><p className="mt3">Or scan the QR code </p>
                <img alt="qrCode" src={this.props.collection.parameters.device_qr_code}/></>;

        const link = this.props.collection.parameters.device_verification_uri_complete ?
            this.props.collection.parameters.device_verification_uri_complete :
            this.props.collection.parameters.device_verification_uri;

        const mutedlass = this.props.disabled ? 'muted' : '';

        return (
            <div className={'center py2 ' + mutedlass}>
                <p>Visit this URL and enter the code <code className={'inline-code'}>
                    {this.props.collection.parameters.device_user_code}</code></p>

                <a className="h3" rel="noopener noreferrer" target="_blank" href={link}
                   title="Click on the link below and enter the code ">
                    {this.props.collection.parameters.device_verification_uri}
                    <IconGeneralExternalLink width={24} height={24} />
                </a>
                <div className={'qr-code'}>
                    {qrCode}
                </div>
            </div>
        )
    }

}

export default DeviceCode
