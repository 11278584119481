import React from 'react';

const OAuthFlows = () => (
  <>
    <h2>OAuth Flows</h2>
    <p>
      The most common OAuth flows supported are namely, implicit, code, hybrid,
      device, client credentials and ROPC. Other OAuth and OpenID complementary
      flows are also made available, like user info and introspection together
      JWT decoding and validation.
    </p>

    <p>
      In addition to running the OAuth flows, OAuth Tools also enables
      developers to experiment with user interaction over OpenID Connect. You
      can test how different parameters affect authentication flows, various
      login methods, single sign-on and more.
    </p>

    <p>
      The clean UI lets you see the flows clearly, in what order things happen,
      what tokens are returned and if signatures are valid.
    </p>
  </>
);

export default OAuthFlows;
