/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import PrettyJson from '../../validation/PrettyJson';
import CopyToClipboard from '../../CopyToClipboard';
import FoldButton from '../../FoldButton';

class ResponseBody extends React.Component {
    constructor(props) {
        super(props);
    }

    handleCollapse = (e) => {
        e.preventDefault();
        if (e.target.hasAttribute('data-tooltip') || e.target.parentNode.hasAttribute('data-tooltip') ) return
        const receivedToken = e.currentTarget.parentElement;
        receivedToken.classList.toggle('folded');
        receivedToken.setAttribute('aria-expanded', receivedToken.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
    };

    render() {

        const rawBody = this.props.responseBody ? this.props.responseBody : 'Response Body is empty';
        const responseHeaders = this.props.responseHeaders;
        const responseType = responseHeaders ? responseHeaders['content-type'] : null;
        const isJson = (this.props.responseBody && responseType && responseType.includes('application/json')) || typeof rawBody === 'object';

        // todo  we can display xml better also:
        // const isXml = responseType && (responseType.indexOf('application/xml') > -1 || responseType.indexOf('text/html') > -1);

        return (<div className={'received-token '} aria-expanded="true">
            <header className="received-token-header flex flex-center justify-between" onClick={this.handleCollapse}>
                <h4 className="m0">Response Body{this.props.copyText? <CopyToClipboard text={this.props.copyText} className="mr1"/> : ''}</h4>
                <FoldButton />
            </header>

            <div className="tools-form-token tools-form-token-active">

                <React.Fragment>
                <pre className="tools-input tools-input-readonly">
                {isJson ? <PrettyJson json={rawBody}/> : <>{rawBody}</>}
            </pre>
                </React.Fragment>
            </div>
        </div>)
    }
}

export default ResponseBody
