import React, { useState, useEffect } from 'react';
import Split from 'react-split';

const ResizablePanels = ({ children, ratio = [70, 30], collection }) => {
  const { _id: flowId } = collection;
  const [isDesktop, setIsDesktop] = useState(true);

  const initialState = JSON.parse(localStorage.getItem('oauth-tools-panel-size')) || [];
  const [flowSize, setFlowSize] = useState(initialState);

  const onDragEnd = (sizes) => {
    let currentFlowSize = []

    if (initialState.some(item => item.id === flowId)) {
      currentFlowSize = flowSize.map(flow => {
        if (flow.id === flowId) {
          return { ...flow, x: sizes[0], y: sizes[1] };
        } else {
          return flow;
        }
      })
    } else {
      currentFlowSize = [
        ...flowSize,
        { id: flowId, x: sizes[0], y: sizes[1] }
      ];
    }

    setFlowSize(currentFlowSize);
    localStorage.setItem('oauth-tools-panel-size', JSON.stringify(currentFlowSize));
  };

  useEffect(() => {
    const mediaWatcher = window.matchMedia('(min-width: 52em)');
    setIsDesktop(mediaWatcher.matches);

    function updateIsDesktop(e) {
      setIsDesktop(e.matches);
    }

    mediaWatcher.addEventListener('change', updateIsDesktop);

    return function cleanup() {
      mediaWatcher.removeEventListener('change', updateIsDesktop);
    };
  }, []);


  if (isDesktop) {
    const sizeRatio = flowSize.find(flow => flow.id === flowId)

    return (
      <Split
        className="split"
        sizes={sizeRatio ? [sizeRatio.x, sizeRatio.y] : ratio}
        minSize={440}
        gutterSize={8}
        onDragEnd={onDragEnd}
      >
        {children}
      </Split>
    );
  } else {
    return <div>{children}</div>;
  }
};

export default ResizablePanels;
