/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated';

class IntrospectionClientId extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {

            let updatedParameters = this.props.collection.parameters;
            Object.keys(newValue.meta).forEach((metaKey) => {
                updatedParameters = updatedParameters.withUpdatedValue(metaKey, newValue.meta[metaKey]);
            });
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('introspection_client_id', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('introspection_client_id', undefined);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    render() {
        const clientId = (this.props.collection.parameters.introspection_client_id) ?
            this.props.collection.parameters.introspection_client_id : '';

        const selectedOption = { value: clientId, label: clientId };
        let clientOptions = [];
        if (this.props.environment && this.props.environment.clients) {
            Object.entries(this.props.environment.clients).forEach(([key, client]) => {
                if (client.can_do_introspect) {
                    clientOptions.push({
                        value: client.client_id, label: client.client_id, meta: {
                            introspection_client_id: client.client_id,
                            introspection_client_secret: client.client_secret
                        }
                    });
                }
            });
        }

        return (
            <div className="flex-auto">
                <Creatable
                    isClearable
                    placeholder="Select or enter a Client ID"
                    inputId={'introspection-client-id'}
                    components={makeAnimated()}
                    defaultValue={(clientId ? selectedOption : undefined)}
                    options={clientOptions}
                    onChange={this.handleChange}
                    className="select-container"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#f2f3f6',
                            primary: '#626c87'
                        }
                    })}
                />
            </div>
        )
    }
}

export default IntrospectionClientId;
