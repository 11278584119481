/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';


const TrickAutoCompleteForms = () => {
    return (
        <React.Fragment>
            <input style={{ display: 'none' }} type="text" name="fakeusernameremembered"
                   autoComplete="off"
                   data-lpignore="true"
                   spellCheck="false"
            />
            <input style={{ display: 'none' }} type="password"
                   name="fakepasswordremembered"
                   autoComplete="off"
                   data-lpignore="true"
                   spellCheck="false"
            />
        </React.Fragment>
    );
};

export default TrickAutoCompleteForms

