/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';


class DCRSelect extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (selectedOption) => {
        const currentCollection = this.props.collection;
        const value = selectedOption ? selectedOption.value : '';

        const updatedEnvironment = currentCollection.parameters.withUpdatedDcrParameters(this.props.field,
            value);

        this.props.updateParameters(currentCollection.id, updatedEnvironment);
    };

    render() {
        const field = this.props.field;

        let value = null;
        if (this.props.collection?.parameters?.dcr_parameters[field]) {
            value = {
                value: this.props.collection.parameters.dcr_parameters[field],
                label: this.props.collection.parameters.dcr_parameters[field]
            };
        }

        return (
            <Select
                isClearable
                placeholder={this.props.placeholder}
                components={makeAnimated()}
                value={value}
                options={this.props.options}
                onChange={this.handleChange}
                className="select-container select-container-big"
                classNamePrefix="react-select"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />
        )
    }
}

export default DCRSelect;
