/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import Token from './Token';
import { flows } from '../util/appConstants';
import { isEmpty } from '../util/validationUtils';
import Parameters from './Parameters';
import { randomTimeId } from '../util/util';

class Collection {

    constructor(id, collection) {
        this._id = id;
        this._name = collection.name;
        this._flow = collection.flow;
        this._provider = (collection.provider) ? collection.provider : null;
        this._tokens = (collection.tokens) ? collection.tokens : {};
        this._response = (collection.response) ? collection.response : null;
        this._OAuthResponses = (collection.OAuthResponses) ? collection.OAuthResponses : {};
        this._error = collection.error;

        const parameters = collection.parameters;
        if (parameters === null || parameters === undefined) {
            this._parameters = new Parameters({});
        } else if (parameters instanceof Parameters) {
            this._parameters = parameters;
        } else {
            this._parameters = new Parameters(parameters);
        }
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get flow() {
        return this._flow;
    }

    get provider() {
        return this._provider;
    }

    get tokens() {
        return this._tokens;
    }

    get error() {
        return this._error;
    }

    get parameters() {
        return this._parameters;
    }

    get response() {
        return this._response;
    }

    get OAuthResponses() {
        return this._OAuthResponses;
    }

    getTokenIds = () => {
        return Object.keys(this._tokens);
    };

    getTokensByPurpose = (purpose) => {
       return Object.values(this._tokens).filter(token => {
            return !isEmpty(token.value) && token.purpose === purpose;
        })
    }

    getTokenById = (id) => {
        if (this._tokens[id]) {
            return new Token(id, this._tokens[id])
        }
        return null;
    };

    toMap = () => {
        return {
            id: this._id,
            name: this._name,
            flow: this._flow,
            provider: this._provider,
            tokens: this._tokens,
            error: this._error,
            parameters: this._parameters.toMap(),
            response: this._response,
            OAuthResponses: this._OAuthResponses
        };
    };

    static getCollection(appState, collections) {
        const collectionId = appState.currentCollection;
        const collection = collections[collectionId];

        return new Collection(collectionId, collection);
    }

    static getCollectionById(id, collections) {
        const collection = collections[id];

        return new Collection(id, collection);
    }

    static createCollection(id, name, response_types, flow, provider) {

        let nonce = null;
        if (flow === flows.hybrid.id){
            nonce = randomTimeId();
        }

        let properties = {
            name,
            flow,
            provider,
            parameters: new Parameters({
                default_response_types: response_types,
                nonce,
                create_jwt_header: [{
                    name: '',
                    value: '',
                    type: 'text',
                    id: Math.random()
                }],
                create_jwt_body: [{
                    name: '',
                    value: '',
                    type: 'text',
                    id: Math.random()
                }]
            })
        };

        if (flow === flows.none.id || flow === flows.create_jwt.id) {
            const emptyToken = Token.createNewToken();
            properties.tokens = {
                [emptyToken.id]: emptyToken.toMap()
            };
        }

        return new Collection(id, properties);
    }
}

export default Collection;
