/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralPlay } from '@curity-internal/ui-icons-react';
import React, { useEffect, useRef } from 'react';

const RunButton = props => {
    const runButtonRef = useRef();

    const run = (event) => {
        event.currentTarget.classList.add('button-loading-active', 'button-disabled');
        event.currentTarget.blur();
        props.runFlow();
    };

    useEffect(() => {
        if (runButtonRef && !props.disabled) {
            runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    });

    const buttonText = props.buttonText ? props.buttonText : 'Run';
    const disabledClass = props.disabled ? ' button-disabled' : '';

    return (
        <div className="mt2 center">
            <button ref={runButtonRef}
                    onClick={run}
                    disabled={props.isDisabled}
                    data-qa="run-button"
                    className={'button button-large button-success button-run button-loading' + disabledClass}>
                <span>
                    <IconGeneralPlay width={24} height={24} />
                    {buttonText}</span>
            </button>
        </div>
    );

}

export default RunButton;
