/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'auto' : 'initial',
        backgroundColor: state.isSelected ? '#f2f3f6' : 'initial'
    })
};

const Option = props => (
    <div className={'flex flex-center'}>
        <components.Option {...props}>
            <input type="checkbox" checked={props.isSelected} onChange={() => null}/>{' '}
            <label>{props.label}</label>
        </components.Option>
    </div>
);

class ClaimNameSelect extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.node,
                label: PropTypes.node
            })
        ).isRequired,
        onChangeCallback: PropTypes.func.isRequired
    };

    static defaultProps = {
        options: []
    };

    render() {
        const { options, value, onChangeCallback, ...otherProps } = this.props;
        const selectedValue = value ? { label: value, value } : null;

        return (
            <Creatable
                autoFocus={selectedValue === null || selectedValue === ''}
                placeholder="Select claim"
                closeMenuOnSelect={true}
                components={{ Option }}
                value={selectedValue}
                options={options}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                onChange={e => onChangeCallback(e)}
                className="select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                {...otherProps}
            />
        )
    }
}

export default ClaimNameSelect;
