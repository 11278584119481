import React from 'react';

const LearnMore = () => (
  <div className="container">
    <h2>Learn more</h2>
    <div className="md-flex flex-center justify-between flex-column learn-more">
      <nav>
        <ul className="m0 list-reset md-flex flex-start flex-wrap justify-between">
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://curity.io/resources/oauth/"
              className="flex flex-center "
            >
              <img
                src="/assets/images/oauth-overview.svg"
                alt="OAuth Overview"
                role="presentation"
              />
              <h4 className="m0">OAuth overview</h4>
            </a>
          </li>
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/watch?v=Kr0SECFMoU40&feature=youtu.be"
              className="flex flex-center "
            >
              <img
                src="/assets/images/video.svg"
                alt="OAuth Tools Getting Started Video"
                role="presentation"
              />
              <h4 className="m0">OAuth Tools Getting Started Video</h4>
            </a>
          </li>
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://curity.io/resources/?utm_source=oauth.tools&utm_medium=Link&utm_content=Learn"
              className="flex flex-center "
            >
              <img
                src="/assets/images/resources.svg"
                alt="Curity Resource Library"
                role="presentation"
              />
              <h4 className="m0">Curity Resource Library</h4>
            </a>
          </li>
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://gist.github.com/anestos/d743e2444eaae33cc4bc0e6cbf0ab028"
              className="flex flex-center "
            >
              <img
                src="/assets/images/ngrok.svg"
                alt="Expose Curity locally using ngrok"
                role="presentation"
              />
              <h4 className="m0">
                Expose Curity Identity Server locally using ngrok
              </h4>
            </a>
          </li>
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://openid.net/developers/specs/"
              className="flex flex-center "
            >
              <img
                src="/assets/images/openid-connect-spec.svg"
                alt="OpenID Spec"
                role="presentation"
              />
              <h4 className="m0">OpenID Spec</h4>
            </a>
          </li>
          <li className="md-flex-50 lg-mb2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tools.ietf.org/html/rfc6749"
              className="flex flex-center "
            >
              <img
                src="/assets/images/oauth-2-spec.svg"
                alt="OAuth 2 Spec"
                role="presentation"
              />
              <h4 className="m0">OAuth 2 Spec</h4>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default LearnMore;
