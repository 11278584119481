/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import FoldButton from '../FoldButton';
import KeysDropdown from '../settings/KeysDropdown';
import PrettyJson from './PrettyJson';
import CopyToClipboard from '../CopyToClipboard';
import { randomTimeId } from '../../util/util';

class TokenValidationSignature extends React.Component {

    state = {
        isFolded: false,
        isJwk: false
    };
    generateButtonRef = React.createRef();
    identifier1 = randomTimeId()
    identifier2 = randomTimeId()
    identifier3 = randomTimeId()

    handleFold = () => {
        this.setState((state) => ({
            isFolded: !state.isFolded
        }));
    };

    updateKeyType = (event) => {
        this.setState({ isJwk: event.currentTarget.checked });
    };

    handleChange = (event) => {
        const key = {};
        try {
            key.jwk = JSON.parse(event.currentTarget.value);
            this.setState({ isJwk: true });
        } catch {
            // Could not parse key as JSON, will try to use it raw
            key.pem = event.currentTarget.value;
            this.setState({ isJwk: false });
        }
        this.props.updateKeyForToken(key, this.props.token.id, this.props.collection);
    };

    componentDidUpdate() {
        if (this.generateButtonRef.current && this.props.showGenerateButton) {
            this.generateButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }

    generateKeyPair = (event) => {
        event.currentTarget.classList.add('button-loading-active', 'button-disabled');
        event.currentTarget.blur();
        this.props.generateKeyPairForSigning();
    };

    render() {

        const collection = this.props.collection;
        const environment = this.props.environment;
        const token = this.props.token;
        const whichKey = this.props.privateKey ? 'private_key' : 'validation_key';
        const inputLabel = this.props.token[whichKey].pem ? 'PEM or Secret' : 'PEM, JWK or Secret';

        return (
            <section className="tools-sidebar-box-signature mt2">
                {!this.props.hideHeader &&
                <header className="tools-box-header">
                    <div className="flex flex-center flex-justify">
                        <div className="tools-box-header-title">Signature
                        </div>
                        <FoldButton handleFold={this.handleFold} isFolded={this.state.isFolded}/>
                    </div>
                </header>
                }

                {(this.state.isFolded) ? '' :
                    <div className="tools-sidebar-box-content p3">

                        <div className="flex flex-column mb1">
                            <label htmlFor={this.identifier1} className="label block">
                                {this.props.label ? this.props.label : 'Add a JWK, a Certificate or a Secret'}
                            </label>

                            {(!this.props.hideEnvironment || environment !== null || environment.jwks !== null) &&
                                <div className="flex flex-center flex-justify mt1 mb1">
                                    {this.props.showGenerateButton &&
                                        <button
                                            ref={this.generateButtonRef}
                                            className="button-small button-primary-outline button-tiny mr1"
                                            onClick={this.generateKeyPair}
                                            >Generate key</button>
                                    }

                                    {(this.props.hideEnvironment || environment == null || environment.jwks == null) ? '' :
                                        <KeysDropdown collection={collection} token={token} environment={environment}
                                        updateKeyForToken={this.props.updateKeyForToken}
                                        />}
                                </div>
                            }

                        </div>

                        <div className="tools-sidebar-box-content-area flex flex-justify flex-column">
                            {(this.state.isJwk && this.props.token[whichKey].jwk) ?
                                <div className="flex-auto">
                                    <div className="tools-sidebar-box-content-area-header flex flex-center justify-between">
                                        <label className="label label-key">JWK <CopyToClipboard
                                            text={this.props.token[whichKey].jwk}/></label>

                                        <div className="custom-checkbox">
                                            <label className="mr1 label-key label-key-switch"
                                                   htmlFor={this.identifier2}>PEM</label>
                                            <label className="toggle-switch">
                                                <input className="form-control custom-checkbox"
                                                       type="checkbox"
                                                       id={this.identifier2}
                                                       name="key_type"
                                                       onChange={this.updateKeyType}
                                                       checked={this.state.isJwk}
                                                       disabled={!(this.props.token[whichKey].jwk)}
                                                />
                                                <div className="toggle-slider toggle-slider-switch round"/>
                                            </label>
                                            <label className="ml1 label-key label-key-switch"
                                                   htmlFor={this.identifier2}>JWK</label>
                                        </div>

                                    </div>
                                    <pre className="tools-input tools-input-white-bg tools-input-signature">
                                        <PrettyJson json={this.props.token[whichKey].jwk}/>
                                    </pre>
                                </div>
                                :

                                <div className="flex-auto">
                                    <div className="tools-sidebar-box-content-area-header flex flex-center justify-between">
                                        <label className="label label-key">{inputLabel} <CopyToClipboard
                                            text={this.props.token[whichKey].pem}/></label>
                                        <div className="custom-checkbox">
                                            <label className="mr1 label-key label-key-switch"
                                                   htmlFor={this.identifier3}>PEM</label>
                                            <label className="toggle-switch">
                                                <input className="form-control custom-checkbox"
                                                       type="checkbox"
                                                       id={this.identifier3}
                                                       name="key_type"
                                                       onChange={this.updateKeyType}
                                                       checked={this.state.isJwk}
                                                       disabled={!(this.props.token[whichKey].jwk)}
                                                />
                                                <div className="toggle-slider toggle-slider-switch round"/>
                                            </label>
                                            <label className="ml1 label-key label-key-switch"
                                                   htmlFor={this.identifier3}>JWK</label>
                                        </div>
                                    </div>

                                    <textarea rows={8} spellCheck="false"
                                              id={this.identifier1}
                                              onChange={this.handleChange}
                                              name="signature-upload" className="tools-input tools-input-signature"
                                              value={this.props.token[whichKey].pem}
                                              placeholder="Enter a JWK, a PEM certificate or a Secret"
                                    />
                                </div>
                            }
                        </div>

                    </div>
                }
            </section>
        )
    }
}

export default TokenValidationSignature;
