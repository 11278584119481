/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralHistory } from '@curity-internal/ui-icons-react';
import React, { useRef, useState } from 'react';

const LoadJsonData = (props) => {
    const [copyMessage, setCopyMessage] = useState('Load Data')
    const [copySuccess, setCopySuccess] = useState(false)
    const buttonRef = useRef();

    const saveToWorkspace = () => {
        props.loadData()
        buttonRef.current.blur();

        setCopySuccess(true)
        setCopyMessage('Data Loaded!')

        setTimeout(() => {
            setCopySuccess(false)
            setCopyMessage('Load Data')
        }, 750)

    };

    return (
            <button ref={buttonRef} onClick={saveToWorkspace} type="button" aria-label="Load"
                    className={`button button-tiny button-link ${(props.cssClass ? props.cssClass : '')}`}
                    data-tooltip={copyMessage}
                    data-tool-tip-copied={copySuccess}>
                <IconGeneralHistory width={24} height={24} />
            </button>)

}
export default LoadJsonData;
