/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class SelectSetting extends React.Component {

    constructor(parameterName, props) {
        super(props);
        this._parameterName = parameterName;
    }

    handleChange = (selectedOption) => {
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue(this._parameterName, selectedOption);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };
}

export default SelectSetting;
