/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react'
import TokenValidationHeader from './TokenValidationHeader';
import TokenValidationBody from './TokenValidationBody';
import TokenValidationSignatureData from './TokenValidationSignatureData';
import { flows, tokenPurposes } from '../../util/appConstants';
import TokenValidationDisclosures from './TokenValidationDisclosures';

const JwtTokenValidation = (props) => {
    const [expandedJwt, setExpandedJwt] = useState(props.flow === flows.none
        || props.token.purpose === tokenPurposes.id_token.value)

    const handleCollapse = (e) => {
        e.preventDefault();
        setExpandedJwt(!expandedJwt);
    };
    const token = props.token;
    const expandJwtText = expandedJwt ? 'Hide decoded JWT' : 'Show decoded JWT';

    return (
        <>
            {props.flow === flows.none ? '' :
                <div
                    className={'decoded-jwt-data flex flex-center justify-between ' + (expandedJwt ? '' : 'folded')}
                    onClick={handleCollapse}>
                    <p className={'mb0'}>{expandJwtText}</p>
                    <button className={'button button-expand'}/>
                </div>}
            {expandedJwt &&
            <>
                <TokenValidationHeader
                    environment={props.environment}
                    token={token}
                />
                <TokenValidationBody
                    token={token}
                    headerName="Payload"
                    collection={props.collection}
                    environment={props.environment}
                />
                <TokenValidationDisclosures
                    headerName={'Disclosed Claims'}
                    token={token}
                />
                <TokenValidationSignatureData
                    token={token}
                    collection={props.collection}
                />
            </>}
        </>
    )
}

export default JwtTokenValidation;
