/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import SelectSetting from './SelectSetting';

class Locale extends SelectSetting {

    constructor(props) {
        super('locales', props);
    }

    render() {

        let localeOptions = [];
        if (this.props.environment && this.props.environment.locales) {
            const localeList = this.props.environment.locales;
            localeOptions = localeList.map((locale) => {
                return { value: locale, label: locale }
            })
        }

        const selectedLocale = this.props.collection.parameters.locales;

        return (
            <Creatable
                isClearable={true}
                placeholder="Select or add locale"
                components={makeAnimated()}
                value={selectedLocale}
                inputId="locale"
                options={localeOptions}
                onChange={this.handleChange}
                className="select-container"
                classNamePrefix="react-select"
                theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: '#f2f3f6',
                    primary: '#626c87'
                }
                })}
            />
        )
    }
}

export default Locale;
