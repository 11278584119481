/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import ClientAuthenticationMethod from './ClientAuthenticationMethod';

class PAR extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (event) => {
        const checked = event.currentTarget.checked;
        this.setState({ checked });
        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('par', checked);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const parEnabled = this.props.collection.parameters.par;
        const environmentSupportsPar = this.props.environment && this.props.environment.canDoPar();


        return (<>
                {environmentSupportsPar &&
                <div className="flex flex-center flex-wrap flex-gap-2 mt2">
                    <div>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       name="PAR"
                                       id="PAR"
                                       type="checkbox"
                                       checked={parEnabled}
                                       onChange={this.onChange}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                            <label className="ml1" htmlFor="PAR">Pushed Authorization Request (PAR)</label>
                        </div>
                    </div>
                    {parEnabled &&
                    <div>
                        <ClientAuthenticationMethod
                            updateParameters={this.props.updateParameters}
                            environment={this.props.environment}
                            endpoint={'token_endpoint_auth_method'}
                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                            collection={this.props.collection}
                        />
                    </div>
                    }
                </div>}
            </>
        )
    }
}

export default PAR;
