/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralDuplicate } from '@curity-internal/ui-icons-react';
import { copyToClipboard } from '../util/util';
import React, { useMemo, useState } from 'react'

const CopyToClipboard = ({ text, cssclass, flow }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [textToCopy, setTextToCopy] = useState(null);

  useMemo(() => {
    if (typeof text === 'undefined') {
      setTextToCopy('');
    } else if (typeof text === 'object') {
      setTextToCopy(JSON.stringify(text));
    } else if (typeof text === 'string' && text.startsWith('"') && text.endsWith('"')) {
      setTextToCopy(text.slice(1, -1));
    } else if (typeof text === 'string') {
      setTextToCopy(text);
    }
  }, [text]);

  return (
    <button
      onClick={() => {
        copyToClipboard(textToCopy);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
      }}
      type="button"
      aria-label="Copy"
      className={`button button-tiny button-link ${
        cssclass ? cssclass : ''
      }`}
      data-tooltip={`${isCopied ? 'Copied' : 'Copy to clipboard'}`}
      flow={flow}
    >
      <IconGeneralDuplicate width={24} height={24} />
    </button>
  );
};

export default CopyToClipboard;
