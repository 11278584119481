/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const WithErrorHandling = WrappedComponent => {
  const ComponentWithErrorHandling = ({ errorState, children }) => {
    return (
        <WrappedComponent>
          {errorState && errorState.isError && <div className={'error-message ' + errorState.errorType}>{errorState.message}</div>}
          {children}
        </WrappedComponent>
    );
  };

  ComponentWithErrorHandling.displayName = `WithErrorHandling(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return ComponentWithErrorHandling;
};

export default WithErrorHandling
