/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Router from './components/Router';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('content');
const root = createRoot(container);
root.render(<Router />);
