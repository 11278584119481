/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const FlowHeader = ({ name, description }) => {
    return (
        <div>
            <h1 className="h2 mt0">{name}</h1>
            <p>{description}</p>
        </div>
    )
};
export default FlowHeader;
