/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { tokenPurposes } from '../util/appConstants';
import { randomTimeId } from '../util/util';

class Token {
    constructor(id, tokenData) {
        this._name = tokenData.name;
        this._purpose = tokenData.purpose;
        this._value = tokenData.value;
        this._decoded_token = tokenData.decoded_token ? tokenData.decoded_token : {};
        this._validation_data = tokenData.validation_data ? tokenData.validation_data : {};
        this._validation_key = tokenData.validation_key ? tokenData.validation_key : '';
        this._private_key = tokenData.private_key ? tokenData.private_key : '';
        this._introspected_token_data = tokenData.introspected_token_data;
        this._authorization_details = tokenData.authorization_details;
        this._id = id;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get purpose() {
        return this._purpose;
    }

    get value() {
        return this._value;
    }

    get decoded_token() {
        return this._decoded_token;
    }

    get validation_data() {
        return this._validation_data;
    }

    get introspected_token_data() {
        return this._introspected_token_data;
    }

    get validation_key() {
        return this._validation_key;
    }

    get private_key() {
        return this._private_key;
    }
    get authorization_details() {
        return this._authorization_details;
    }

    isJwt() {
        return typeof this._value === 'string' && this._value.startsWith('ey') && this._value.includes('.');
    }

    withNewValue = (value) => {
        const currentMap = this.toMap();
        const data = {
            ...currentMap,
            value
        };
        return new Token(this.id, data);
    };

    withUpdatedName = (name) => {
        return this.withUpdatedProperty('name', name);
    };

    withUpdatedPurpose = (purpose) => {
        return this.withUpdatedProperty('purpose', purpose)
    };

    withUpdatedProperty = (key, value) => {
        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new Token(this._id, next);
        } else {
            throw 'Key ' + key + ' is not a property of Token';
        }
    };

    toMap = () => {
        return {
            name                    : this._name,
            purpose                 : this._purpose,
            value                   : this._value,
            decoded_token           : this._decoded_token,
            validation_data         : this._validation_data,
            validation_key          : this._validation_key,
            private_key             : this._private_key,
            introspected_token_data : this._introspected_token_data,
            authorization_details : this._authorization_details
        }
    };

    static createNewToken(properties) {
        const id = randomTimeId();
        const props = properties ? properties : {};

        const params = {
            name: (props.name) ? props.name : 'Token',
            purpose: (props.purpose) ? props.purpose : tokenPurposes.unknown.value,
            value: (props.value) ? props.value : '',
            decoded_token: (props.decoded_token) ? props.decoded_token : {},
            validation_data: (props.validation_data) ? props.validation_data : {},
            validation_key: (props.validation_key) ? props.validation_key : {},
            private_key: (props.private_key) ? props.private_key : {},
            authorization_details: (props.authorization_details) ? props.authorization_details : {},
            introspected_token_data: (props.introspected_token_data) ? props.introspected_token_data : {}
        };
        return new Token(id, params);
    }
}

export default Token;
