import React, { useState, Children } from 'react';

export const Tabs = ({ noSelection, children }) => {
    const [activeTab, setActiveTab] = useState(noSelection ? null : 0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabs = Children.map(children, (child, index) => ({
        label: child.props.label,
        content: child.props.children,
        index
    }));

    return (
        <div className="tabs">
            <div role="tablist" className="flex list-reset m0-flex mb2">
                {tabs.map((tab) => (
                    <button
                        role="tab"
                        key={tab.index}
                        className={activeTab === tab.index ? 'active' : ''}
                        onClick={() => handleTabClick(tab.index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            {activeTab !== null ?
                <div className="tab-content">{tabs[activeTab].content}</div> : <></>}
        </div>
    );
};
