/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralClose, IconGeneralTrash } from '@curity-internal/ui-icons-react';
import React, { useEffect, useRef, useState } from 'react'

function ExtraQueryParametersModal(props) {
    const modalRef = useRef(null);
    const parameterName = props.parameter;
    const [visible, setVisible] = useState(false);


    const openQueryParamModal = () => {
        setVisible(true)
        document.querySelector('html').style.overflow = 'hidden';
    };

    const closeQueryParamModal = () => {
        setVisible(false)
        document.querySelector('html').style.overflow = '';
    };

    useEffect(() => {
        const keyboardPresses = event => {
            if (event.keyCode === 27) {
                closeQueryParamModal();
            }
        };
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeQueryParamModal();
            }
        };
        if (visible) {
            document.addEventListener('keydown', keyboardPresses, false)
            document.addEventListener('mousedown', handleClickOutside, false)
        }
        return () => {
            document.removeEventListener('keydown', keyboardPresses, false)
            document.removeEventListener('mousedown', handleClickOutside, false)
        }
    }, [visible])

    const addEmptyQueryParameter = () => {
        let parameters = props.collection.parameters[parameterName];
        const parameterExists = parameters.find(param => param.name === '' && param.value === '');
        if (parameterExists === undefined) {
            parameters.push({ name: '', value: '', id: Math.random() });
            const updatedParameters =
                props.collection.parameters.withUpdatedValue(parameterName, parameters);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    };

    const onUpdateQueryParameter = (value, i, field) => {
        let parameters = Object.assign(props.collection.parameters[parameterName]);
        parameters[i][field] = value;
        const updatedParameters =
            props.collection.parameters.withUpdatedValue(parameterName, parameters);
        props.updateParameters(props.collection.id, updatedParameters);
        addEmptyQueryParameter()
    };

    const onDeleteQueryParameter = (event, i) => {
        let parameters = [...props.collection.parameters[parameterName]];
        parameters.splice(i, 1);
        if (parameters.length === 0) {
            parameters.push({ name: '', value: '', id: Math.random() })
        }
        const updatedParameters =
            props.collection.parameters.withUpdatedValue(parameterName, parameters);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const selectedParameters = props.collection.parameters[parameterName];

    return (<>
        <button onClick={openQueryParamModal}
                className="button button-small button-primary-outline button-input">
            Add Extra Parameters
        </button>
        {visible &&
            <div className={'modal modal-claims modal-is-visible'}>
                <section
                    className="modal-main modal-main-wide"
                    ref={modalRef}
                >
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-between">
                            <h3 className="h4 m0">Define Extra Parameters</h3>
                            <div className="flex flex-center justify-between">
                                <button
                                    aria-label="Close modal"
                                    className="button button-close button-link ml2"
                                    onClick={closeQueryParamModal}
                                >
                                    <IconGeneralClose width={18} height={18} />
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="modal-main-content">

                        <div className="api-options-headers">
                            {selectedParameters.map((parameter, i) => (
                                <div className="flex justify-between mb1" key={parameter.id}>
                                    <div className="flex-auto pr1">
                                        <input
                                            className="field col-12"
                                            placeholder={'New parameter'}
                                            type="text"
                                            onFocus={addEmptyQueryParameter}
                                            onChange={(e) =>
                                                onUpdateQueryParameter(e.currentTarget.value, i, 'name')}
                                            value={parameter.name}
                                        />
                                    </div>
                                    <div className="flex-auto pr1">
                                        <input
                                            className="field col-12"
                                            type="text"
                                            placeholder="Value"
                                            onFocus={addEmptyQueryParameter}
                                            onChange={e => onUpdateQueryParameter(e.currentTarget.value, i, 'value')}
                                            defaultValue={parameter.value}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className="button button-light button-small button-input"
                                            onClick={e => onDeleteQueryParameter(e, i)}
                                        >
                                            <IconGeneralTrash width={18} height={18} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </section>
            </div>}
            </>)
        }

        export default ExtraQueryParametersModal
