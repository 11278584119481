/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

function CurityIcon() {
  return (
    <svg
      className="inlineicon curity-icon"
      overflow="visible"
      viewBox="0 0 57.1 37.3"
      style={{ width: 20, height: 20 }}
    >
      <path
        d="M51.2 23.7l-.6.6c-3.3 3.3-7.3 5.1-11.2 5.1-6.2 0-10.6-4.5-10.6-10.7C28.8 12.5 33.2 8 39.2 8c3.5 0 7.6 1.6 10.5 4.1l.6.5 5.8-6-.6-.6c-4.6-3.7-10.8-6-16.9-6-6.7 0-12.4 3.1-15.8 7.9H12.2l-.6 5.2h8.7c-.3 1-.5 1.9-.6 2.9h-13L6 21.3h13.7c.1 1 .3 2 .6 2.9H.6L0 29.4h22.8c3.4 4.9 9.1 7.9 16.1 7.9 8.7 0 14.8-4.5 17.6-7.1l.6-.6-5.9-5.9z"
      ></path>
      <path d="M5.1 16H1.3l-.6 5.3h3.8z"></path>
    </svg>
  );
}

export default CurityIcon;
