/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react'

import TabBody from './TabBody';
import Environments from '../../data/Environments';
import {
  IMPORT_CONFIG_MODAL_SOURCE,
  IMPORT_TYPE
} from '../../util/appConstants';
import { resolvePlayGroundIcon } from '../../util/util';
import { IconGeneralArrowBack, IconGeneralClose, IconGeneralEdit, IconGeneralTrash } from '@curity-internal/ui-icons-react';

class Settings extends Component {

  delete = () => {
    this.props.handleDeleteEnvironment(this.props.appState.activeTab);
    this.props.handleClose();
  };

  setEnvModalWrapper = (node) => {
    this.envModalWrapper = node;
  };


  handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (this.envModalWrapper && !this.envModalWrapper.contains(event.target)
      && !path.includes(this.envModalWrapper)) {
      if (!this.props.isImportModalVisible) {
        this.props.handleClose();
      }
    }
  };

  showImportModalfile = () => {
    this.props.showImportExportModal(IMPORT_TYPE.IMPORT_ENVIRONMENTS, IMPORT_CONFIG_MODAL_SOURCE.BUTTON);
  };

  editName = () => {
    this.props.setEditing(true)
  };

  updateName = (event) => {
    const environments = Environments.create(this.props.environments);
    const currentEnvironment = environments.getEnvironment(this.props.appState.activeTab);
    const updatedEnvironment = currentEnvironment.withUpdatedName(event.currentTarget.value);
    const name = event.currentTarget.value;

    this.props.updateEnvironment(updatedEnvironment);

    this.setState({
      hasNewName: name.length ? true : false
    })
  };

  handleFocus = (event) => event.target.select();

  confirm = () => {
    this.props.setEditing(false)
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.confirm()
    }
  };

  render() {
    const environments = Environments.create(this.props.environments);
    const currentEnvironment = environments.getEnvironment(this.props.appState.activeTab);
    const environmentName = currentEnvironment ? currentEnvironment.name : '';

    const showHideClassName = this.props.showSettings
      ? 'modal modal-is-visible'
      : 'modal modal-is-hidden';

    if (this.props.showSettings) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    const playGroundIcon = resolvePlayGroundIcon(currentEnvironment, true)

    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-main-wide modal-environments" ref={this.setEnvModalWrapper}>

          <header className="modal-main-header">
            <div className="flex flex-center justify-between">
              <div>

                {this.props.editing ?
                  <div className="flex flex-center">
                    <input type="text" className="field"
                           data-qa={'rename-workspace-input'}
                           onChange={this.updateName}
                           value={environmentName}
                           onBlur={this.confirm}
                           onFocus={this.handleFocus}
                           onKeyPress={this.handleKeyPress}
                           autoFocus
                           autoComplete="off"
                           data-lpignore="true"
                           spellCheck="false"
                    />
                    <button className={'button button-success-outline button-small button-input ml1'}
                          onClick={this.confirm }>
                      Save
                    </button>
                  </div>
                  :
                  <h3 className="h4 m0">{playGroundIcon}{environmentName}</h3>
                }
              </div>

              <div className="flex flex-center justify-between">
                {!this.props.editing &&
                <button className="button button-tiny button-light"
                        data-qa={'rename-workspace'}
                        onClick={this.editName}>
                  <IconGeneralEdit width={20} height={20}/>
                  Rename
                </button>
                }

                <button className="button button-tiny button-light ml1"
                        onClick={this.showImportModalfile}>
                  <IconGeneralArrowBack width={20} height={20}/>
                  Import
                </button>
                <button className="button button-tiny button-danger-outline ml1"
                        data-qa={'delete-workspace'}
                        onClick={this.delete}>
                  <IconGeneralTrash width={20} height={20}/>
                  Delete
                </button>
                <button className="button button-close button-link ml2"
                        data-qa="close-workspace-window"
                        onClick={this.props.handleClose}>
                          <IconGeneralClose width={20} height={20}/>
                        </button>
              </div>
            </div>
          </header>

          <div className="modal-main-content modal-environments">
            <section role="contentinfo" className="flex justify-between">
              <div className="tab-content">
                <TabBody
                  environment={currentEnvironment}
                  collections={this.props.collections}
                  discoverWebFinger={this.props.discoverWebFinger}
                  discoverMetaData={this.props.discoverMetaData}
                  discoverCredentialMetaData={this.props.discoverCredentialMetaData}
                  jwksRequest={this.props.jwksRequest}
                  updateEnvironment={this.props.updateEnvironment}
                  showImportExportModal={this.props.showImportExportModal}
                />
              </div>
            </section>
          </div>

        </section>

      </div>
    )
  }
}

export default Settings
