/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import SelectSetting from './SelectSetting';
import { stringArrayToValueLabelArray } from '../../../util/util';

class Scopes extends SelectSetting {

    constructor(props) {
        super('scopes', props);
    }

    render() {

        let scopeOptions = [];
        if (this.props.environment) {
            const scopeList = this.props.collection.parameters.client_from_environment
            && this.props.environment.hasClientWithId(this.props.collection.parameters.client_from_environment)
                && this.props.environment.clients[this.props.collection.parameters.client_from_environment].scope ?
                this.props.environment.clients[this.props.collection.parameters.client_from_environment].scope.split(' ') :
                this.props.environment.scopes;

            scopeOptions = scopeList ? stringArrayToValueLabelArray(scopeList) : [];
        }

        const selectedScopes = this.props.collection.parameters.scopes;

        return (
            <Creatable
                isMulti
                placeholder="Select or add scope"
                components={makeAnimated()}
                value={selectedScopes}
                inputId={'scopes'}
                options={scopeOptions}
                onChange={this.handleChange}
                className="select-container"
                classNamePrefix="react-select"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />
        )
    }
}

export default Scopes;
