/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import CopyToClipboard from '../CopyToClipboard';

const CurlRequestPreview = ({ request }) => {

    const preview = request.split(/[\$]/);

    const lines = preview.map((line) =>
        <React.Fragment key={line.toString()}>
            <span className="raw">{line}</span><br/>
        </React.Fragment>
    );

    return (
        <div className="mt2">
            <div
                className={'sm-flex flex-center mb1 mt1 start-here justify-between'}>
                <div>
                    <label className="label">Request preview in cURL <CopyToClipboard text={request.replace(/\\/g, '')}/></label>
                </div>
            </div>

            <div className="flex flex-justify flex-center">
                <pre className="field field-mono col-12">
                    {lines}
                </pre>
            </div>
        </div>
    )
};

export default CurlRequestPreview;
