/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import CopyToClipboard from './CopyToClipboard';
import TokenPurpose from './token/TokenPurpose';

class JWTTokenOutput extends React.Component {
    render() {

        let formattedToken = '';
        const parts = this.props.token.value.split('.');
        if (parts.length === 3) {
            formattedToken = <code className="json">
                <span className="jwt-header">{parts[0]}</span><span className="jwt-dot">.</span>
                <span className="jwt-payload">{parts[1]}</span><span className="jwt-dot">.</span>
                <span className="jwt-signature">{parts[2]}</span></code>;
        }

        return (
            <>
            {formattedToken &&
                <div className="tools-form-token p1 tools-form-token-active">
                    <header className="tools-box-header tools-box-header-token">
                        <div className="flex flex-center flex-justify">
                            <div className="flex flex-center flex-justify">
                                <label className="mr2">JWT Output</label>
                                <TokenPurpose token={this.props.token} editable={true}
                                                updateToken={this.props.updateToken}
                                                collectionId={this.props.collectionId}/>
                            </div>
                            <CopyToClipboard flow="left" text={this.props.token.value} className="mr1"/>
                        </div>
                    </header>
                        <pre className={'tools-input tools-input-readonly tools-input-jwt'}>{formattedToken}</pre>
                </div>
            }
            </>
        );
    }
}

export default JWTTokenOutput;
