/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'
import ClaimNameSelect from './ClaimsNameSelect'
import ClaimUsageSelect from './ClaimsUsageSelect'
import { claimUsage, flattenClaimUsage } from '../../../../util/appConstants';
import ClaimsValuesSelect from './ClaimsValuesSelect';
import { IconGeneralEmpty, IconGeneralPlus, IconGeneralTrash } from '@curity-internal/ui-icons-react';

class ClaimsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            claims: this.props.collection.parameters.claims
        };

        this.onAddItem = this.onAddItem.bind(this)
    }

    setOpenIdClaimsMappings = (claims) => {
        let formattedClaims = [];
        claims.forEach(claim => {
            formattedClaims.push({ name: claim, usage: ['id_token', 'userinfo'] })
        });
        this.setState({ claims: formattedClaims }, () => this.onItemUpdate());
    };

    setClaimsFromJson = (formattedClaims) => {
      this.setState({ claims: formattedClaims }, ()=> this.onItemUpdate())
    };

    onAddItem(event) {
        if (event.target) {
            const newClaim = {};
            this.setState(
                {
                    claims: [...this.state.claims, newClaim]
                }, () => this.onItemUpdate()
            )
        }
    }

    onRemoveItem = i => {
        let claims = this.state.claims;
        claims.splice(i, 1);
        this.setState({
            claims
        }, () => this.onItemUpdate())
    };

    updateClaimValues = (index, values) => {
        let currentState = this.state.claims;
        currentState[index].values = values.map(value => value.value);
        this.setState({ claims: currentState }, () => this.onItemUpdate());
    };

    updateClaimName = (index, value) => {
        let currentState = this.state.claims;
        currentState[index].name = value;
        this.setState({ claims: currentState }, () => this.onItemUpdate());
    };

    updateClaimUsage = (index, value) => {
        let currentState = this.state.claims;
        currentState[index].usage = value;
        this.setState({ claims: currentState }, () => this.onItemUpdate());
    };

    updateClaimRequired = (index, event) => {
        if (event) {
            let currentState = this.state.claims;
            currentState[index].required = event.currentTarget.checked;
            this.setState({ claims: currentState }, () => this.onItemUpdate());
        }
    };

    onItemUpdate = () => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('claims', this.state.claims);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const claimsFromEnvironment = this.props.environment ? this.props.environment.claims_supported : [];

        let claimsOptions = [];
        if (claimsFromEnvironment) {
            claimsOptions = claimsFromEnvironment.map((value) => {
                return { value, label: value }
            })
        }
        this.props.collection.parameters.claims.forEach(claim => {
            if (claim.name && (!claimsFromEnvironment || claimsFromEnvironment.indexOf(claim.name) === -1)) {
                claimsOptions.push({ value: claim.name, label: claim.name });
            }
        });

        let usageSet = flattenClaimUsage;
        this.props.collection.parameters.claims.forEach(claim => {
            if (claim.usage) {
                claim.usage.forEach(claimString => {
                    usageSet.add(claimString);
                })
            }
        });

        const usageList = Array.from(usageSet).map((value) => {
            return { value, label: claimUsage[value] ? claimUsage[value] : value }
        });

        return (
            <>
                <div className="sm-flex flex-center  justify-between mb2">
                    <h3 className="m0">Claims <span className="pill pill-grey">{this.state.claims.length}</span></h3>
                    <div className="sm-flex flex-center flex-gap-2">
                        <button
                            className="button button-tiny button-success"
                            onClick={this.onAddItem}
                        >
                            <IconGeneralPlus width={18} height={18} />
                            Add claim
                        </button>
                    </div>
                </div>
                <p className="mt0 mb2">
                Paste the claims request parameter value (JSON),
                    select one of the presets below or add the claims manually from the table.
                </p>
                <div className="action-table-wrapper">
                    <table className="inline-table action-table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value(s) (Optional)</th>
                            <th>Usage</th>
                            <th width="40px">Essential (Optional)</th>
                            <th width="20px">Remove</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.claims.map((item, index) => (
                            <tr key={index} data-index={index}>
                                <td>
                                    <ClaimNameSelect
                                        options={claimsOptions}
                                        value={item.name}
                                        onChangeCallback={response => this.updateClaimName(index, response.value)}
                                    />
                                </td>
                                <td>
                                    <ClaimsValuesSelect
                                        options={claimsOptions}
                                        item={item}
                                        onChangeCallback={response => this.updateClaimValues(index, response)}
                                    />

                                </td>
                                <td>
                                    <ClaimUsageSelect
                                        options={usageList}
                                        value={item.usage}
                                        onChangeCallback={response =>
                                            this.updateClaimUsage(index, response)
                                        }
                                    />
                                </td>
                                <td>
                                    <div className="custom-checkbox">
                                        <label className="toggle-switch">
                                            <input className="form-control custom-checkbox"
                                                   type="checkbox"
                                                   name="claim_required"
                                                   id={index + 'claim_required'}
                                                   checked={item.required ? item.required : false}
                                                   onChange={this.updateClaimRequired.bind(this, index)}
                                            />
                                            <div className="toggle-slider round"/>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <button
                                        className="button button-small button-icon-only button-danger-outline"
                                        onClick={() => this.onRemoveItem(index)}
                                        title="Delete claim"
                                    >
                                        <IconGeneralTrash width={22} height={22} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {!this.state.claims.length &&
                            <tr>
                                <td colSpan="5"><div className="py3 center">
                                    <IconGeneralEmpty width={96} height={96} />
                                    <h4 className="mt0">Looks like you don’t have any Claims</h4>
                                    <p className="mb0"><small>Fortunately, it’s very easy to add one.</small></p>
                                    </div>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default ClaimsTable
