/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { Link } from 'react-router-dom';

const AboutText = () => (
  <>
    <p>
      OAuth Tools is a free OAuth laboratory and serves as a place for API
      developers to learn and explore the inner workings of OAuth and OpenID
      Connect, the standards used to secure API access.
    </p>

    <p>
      It can be viewed as an advanced OAuth client that can easily be connected
      to any OAuth server and used to experiment and test different OAuth flows.
      By using OAuth Tools, you can understand the different steps in the
      process to issue tokens and how those can be used to perform access
      control and to protect APIs.
    </p>

    <div className="alert alert-info flex justify-between">
      This application uses only the browser local storage, no data is stored on
      the server.
      <Link
        to="/"
        className="button button-primary button-small"
        aria-label="Try out OAuth Tools"
      >
        Try out OAuth Tools
      </Link>
    </div>
  </>
);

export default AboutText;
