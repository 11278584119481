/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';


const openTag = (json) => {
    const array = json instanceof Array;
    const object = json instanceof Object;
    if (array) {
        return '[';
    }
    if (object) {
        return '{';
    }
}

const closeTag = (json) => {
    const array = json instanceof Array;
    const object = json instanceof Object;
    if (array) {
        return ']';
    }
    if (object) {
        return '}'
    }
}

const highlight = (json) => {
    const array = json instanceof Array;
    const space = ' ';

    const highlightAndIndent = (jsonObject, indent, showKey) => {
        return Object.keys(jsonObject).map((key, index) => {
            let value = jsonObject[key];
            const comma = Object.keys(jsonObject).length - 1 > index ? <span className={'comma'}>,</span> :'';
            let quotedValue = '"';
            let valueType = typeof value;
            const isPrimitive =
                ['string', 'number', 'boolean'].includes(valueType) || !value;
            if (isPrimitive && valueType === 'object') {
                valueType = 'null';
            }
            if (valueType !== 'string') {
                quotedValue = '';
            }

            if (['exp', 'iat', 'nbf', 'auth_time', 'updated_at'].includes(key)) {
                let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                d.setUTCSeconds(value);
                value = <span data-tooltip-long data-tooltip={d.toUTCString()}>{value}</span>;
            } else if (valueType === 'boolean') {
                value = `${value}`
            }

            const showKeyNext = !(value instanceof Array);

            return (
                <div key={key} className="line">
                    {space.repeat(indent)}
                    {showKey && <span className="key">&quot;{key}&quot;: </span>}
                    {isPrimitive ? (
                        <><span className={valueType}>{quotedValue}{value}{quotedValue}</span>{comma}</>
                    ) : (<>{openTag(value)}{highlightAndIndent(value, indent + 2, showKeyNext)}
                            <span className="line">{space.repeat(indent)}{closeTag(value)}</span>{comma}</>
                    )}
                </div>
            );
        });
    };
    return (<>
        <span className="line">{openTag(json)}</span>
        {highlightAndIndent(json, 2, !array)}
        <span className="line">{closeTag(json)}</span>
    </>)
};

const PrettyJson = (props) => {
        if (!props.json) {
            return (<code className="json"/>);
        } else if (typeof props.json === 'string') {
            const parts = props.json.split('.');
            const disclosures = parts[2].split('~')
            const signature = disclosures.shift();
            const disclosureOutput = disclosures.map((d) =>
                <>
                    <span className="jwt-dot">~</span>
                    <span className="jwt-disclosure">{d}</span>
                </>
            )
            return (<code className="json">
                <span className="jwt-header">{parts[0]}</span><span className="jwt-dot">.</span>
                <span className="jwt-payload">{parts[1]}</span><span className="jwt-dot">.</span>
                <span className="jwt-signature">{signature}</span>{disclosureOutput}</code>);
        } else {
            return (<pre className={'field field-mono col-12 mb2 pretty-json'}>{highlight(props.json)}</pre>);
        }
    }
;

export default PrettyJson;
