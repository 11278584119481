/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Select from 'react-select';
import TokenValidationSignature from '../../validation/TokenValidationSignature';
import { generateKeyPair } from '../../../util/util';
import jose from 'node-jose';
import { isEmpty } from '../../../util/validationUtils';

class JAR extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (event) => {
        const checked = event.currentTarget.checked;
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('jar', checked);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    updateAlgorithm = (selectedOption, actionMeta) => {
        let newValue;
        if (actionMeta.action === 'clear') {
            newValue = '';
        } else {
            newValue = selectedOption.value;
        }
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('jar_algorithm', newValue);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    }

    generateKeyPairForJar = async () => {
        const alg = this.props.collection.parameters.jar_algorithm;

        const [publicKey, privateKey] = await generateKeyPair(alg);
        const parsedPublicKey = await this.parseKey({ jwk: publicKey }, false)
        const parsedPrivateKey = await this.parseKey({ jwk: privateKey }, true)

        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('jarKeys', { 'private_key': parsedPrivateKey, 'validation_key': parsedPublicKey });
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    }

    parseKey = async (key, privateKey) => {

        if (Object.prototype.hasOwnProperty.call(key, 'jwk')) {
            try {
                const joseJwk = await jose.JWK.asKey(key.jwk);
                key.pem = joseJwk.toPEM(privateKey);
            } catch (e) {
                // Could not parse key as JWK
                console.warn(e)
            }
        } else {
            try {
                let keyStore = await jose.JWK.createKeyStore();
                const parsedKey = await keyStore.add(key.pem, 'pem');
                key.jwk = parsedKey.toJSON(privateKey);
            } catch (e) {
                if (!isEmpty(key.pem)) {
                    // Could not parse key as JWK
                    console.warn(e);
                }
            }
        }
        return key;
    }

    updatePublicKeyForJar = async (key) => {
        const parsedPublicKey = await this.parseKey(key, false)
        const privateKey = this.props.collection.parameters.jarKeys.private_key;
        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('jarKeys', { 'private_key': privateKey, 'validation_key': parsedPublicKey });
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    }

    updatePrivateKeyForJar = async (key) => {
        const parsedPrivateKey = await this.parseKey(key, true)
        const publicKey = this.props.collection.parameters.jarKeys.validation_key;
        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('jarKeys', { 'private_key': parsedPrivateKey, 'validation_key': publicKey });
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    }

    render() {
        const jarEnabled = this.props.collection.parameters.jar === true;
        const environmentSupportsJar = this.props.environment && this.props.environment.request_parameter_supported &&
            this.props.environment.request_object_signing_alg_values_supported;

        const algorithms = [];
        if (environmentSupportsJar) {
            Object.values(this.props.environment.request_object_signing_alg_values_supported).forEach((alg) => {
                algorithms.push({ value: alg, label: alg });
            });
        }


        const selectedAlgorithm = this.props.collection.parameters.jar_algorithm;
        const selectedToken = this.props.collection.parameters.jarKeys;
        return (<>
                {environmentSupportsJar &&
                <div className="flex flex-auto mt2">
                    <div className="flex-100">
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       name="JAR"
                                       id="JAR"
                                       type="checkbox"
                                       checked={jarEnabled}
                                       onChange={this.onChange}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                            <label className="ml1" htmlFor="JAR">JWT Secured Authorization Request (JAR)</label>
                        </div>
                        {jarEnabled &&
                        <details className="mt2" open={true} id="requestObjectSettings">
                            <summary aria-controls="requestObjectSettings">
                                Request Object Settings
                            </summary>

                            <div className="sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                <div className="flex-auto">
                                    <Select
                                        isClearable
                                        value={algorithms.filter(({ value }) => value === selectedAlgorithm)}
                                        onChange={this.updateAlgorithm}
                                        options={algorithms}
                                        placeholder="Select Algorithm..."
                                        className="select-container"
                                        classNamePrefix="react-select"
                                        id="algorithmSelector"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#f2f3f6',
                                                primary: '#626c87'
                                            }
                                        })}
                                    />

                                </div>
                                {selectedAlgorithm && selectedAlgorithm !== 'none' &&
                                <div>
                                    <button className="button button-small button-primary-outline button-input"
                                            onClick={this.generateKeyPairForJar}>Generate key
                                    </button>
                                </div>
                                }
                            </div>

                            {selectedAlgorithm && selectedAlgorithm !== 'none' &&
                            <>
                                <TokenValidationSignature
                                    label="Public key"
                                    token={selectedToken}
                                    collection={this.props.collection}
                                    environment={this.props.environment}
                                    hideHeader={true}
                                    showGenerateButton={false}
                                    hideEnvironment={true}
                                    updateKeyForToken={this.updatePublicKeyForJar}
                                />
                                <TokenValidationSignature
                                    label="Private key"
                                    token={selectedToken}
                                    hideHeader={true}
                                    privateKey={true}
                                    collection={this.props.collection}
                                    environment={this.props.environment}
                                    showGenerateButton={false}
                                    hideEnvironment={true}
                                    updateKeyForToken={this.updatePrivateKeyForJar}
                                />
                            </>}

                        </details>
                        }
                    </div>
                </div>}
            </>
        )
    }

}

export default JAR;
