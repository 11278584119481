/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import IntrospectionClientId from './IntrospectionClientId';
import IntrospectionClientSecret from './IntrospectionClientSecret';
import React from 'react';
import SeparateIntrospectionClientSetting from './SeparateIntrospectionClientSetting';
import ApplicationJwtIntrospection from './ApplicationJwtIntrospection';
import TrickAutoCompleteForms from '../../TrickAutoCompleteForms';
import ClientAuthenticationMethod from './ClientAuthenticationMethod';

const IntrospectionSettings = (props) => {
    return (
        <details className="mt2" id="introspectionsettings">
            <summary role="button" aria-controls="introspectionsettings">
                Introspection Settings
            </summary>

            <div className="sm-flex flex-center mt2">
                <ApplicationJwtIntrospection
                    collection={props.collection}
                    updateParameters={props.updateParameters}
                />
            </div>

            <div className="sm-flex flex-center mt2">
                <SeparateIntrospectionClientSetting
                    collection={props.collection}
                    updateParameters={props.updateParameters}
                />
            </div>

            {(props.collection.parameters.use_separate_client_for_introspection) ?
                <form autoComplete="off">
                    <TrickAutoCompleteForms/>

                    <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                      <div className={'flex-auto'}>
                            <IntrospectionClientId
                                updateParameters={props.updateParameters}
                                collection={props.collection}
                                environment={props.environment}
                            />
                      </div>
                      <div className={'flex-auto'}>
                            <IntrospectionClientSecret
                                updateParameters={props.updateParameters}
                                collection={props.collection}
                            />
                      </div>

                    </div>
                </form>
                : ''}
            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                <div className={'flex-auto'}>
                    <ClientAuthenticationMethod
                        updateParameters={props.updateParameters}
                        environment={props.environment}
                        endpoint={'introspection_endpoint_auth_method'}
                        serverConfigFrom={'introspection_endpoint_auth_methods_supported'}
                        collection={props.collection}
                    />
                </div>
            </div>
        </details>
    );
};

export default IntrospectionSettings;
