/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { connect } from 'react-redux';
import Main from './components/Main';
import { bindActionCreators } from 'redux';
import * as actions from './actions/actionLoader';

// This makes sure the this.props.posts is connected to this.state.posts etc
function mapStateToProps(state) {
    return {
        collections: state.collections,
        groups: state.groups,
        appState: state.appState,
        environments: state.environments
    }
}

// Make sure the actions are available as props
// so you don't have to do store.dispatch
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);
export default App;
