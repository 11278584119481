/*
 * Copyright 2021 Curity AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';

class BindingMessage extends React.Component {

    setBindingMessage = (event) => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('binding_message', event.currentTarget.value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const bindingMessage = this.props.collection.parameters.binding_message ? this.props.collection.parameters.binding_message : '';


        return (
            <input className="field col-12" type="text" min="0" placeholder="Binding Message" value={bindingMessage}
                   onChange={this.setBindingMessage}
                   autoComplete="off"
                   data-lpignore="true"
                   spellCheck="false"
            />
        )
    }
}

export default BindingMessage
