/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react'
import { Analytics } from './Analytics';

class Main extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        //In order to pass props we clone children first.
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, this.props);
        });

        return (
            <React.Fragment>
                {children}
                {!IS_ELECTRON_BUILD && <Analytics />}
            </React.Fragment>
        )
    }
}

export default Main
