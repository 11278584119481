/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { flows, PLAYGROUND } from './util/appConstants';

const collectionId = '1599049118188-OZ8';
const environmentId = '1594322492805-8Tw';

const demoLoginUrl = window.location.protocol === 'oauth.tools:' || window.location.host === 'oauth.tools' ?
    'https://login-demo.curity.io' : 'https://logindemo-dev.curityio.net';

export const appState = {
    loading: true,
    error: {
        isError: false,
        message: '',
        errorType: 'info'
    },
    currentCollection: collectionId,
    activeTab: environmentId
};

const defaultCollections = {};
defaultCollections['1599049118188-OZ8'] = flows.none.demoFlow;
defaultCollections['1599045135410-jFe'] = flows.code.demoFlow;
defaultCollections['1599045135410-jAr'] = flows.jar.demoFlow;
defaultCollections['1599045172021-mww'] = flows.implicit.demoFlow;
defaultCollections['1599045202487-sVG'] = flows.hybrid.demoFlow;
defaultCollections['1599045253169-GHF'] = flows.client_credentials.demoFlow;
defaultCollections['1599741564743-UfO'] = flows.ciba.demoFlow;
defaultCollections['1599045265746-zCR'] = flows.userinfo.demoFlow;
defaultCollections['1599045272576-QNn'] = flows.refresh.demoFlow;
defaultCollections['1599127573115-U9P'] = flows.introspect.demoFlow;
defaultCollections['1599045274417-Y8P'] = flows.revoke.demoFlow;
defaultCollections['1599741564763-UtW'] = flows.oidc_logout.demoFlow;
const defaultVciCollections = {}
defaultVciCollections['1599125162363-vci'] = flows.codeprecode.demoFlow;
defaultVciCollections['1599741562313-vci'] = flows.precode.demoFlow;
defaultVciCollections['1599741564773-vci'] = flows.vci.demoFlow;
const defaultEnvironments = {};

export const curityPlaygroundEnvironment = {
    'name': 'Curity Playground',
    'metadata_url': demoLoginUrl + '/oauth/v2/oauth-anonymous/.well-known/openid-configuration',
    'issuer': demoLoginUrl + '/oauth/v2/oauth-anonymous',
    'playground': PLAYGROUND.CURITY,
    'endpoints': {
        'authorization_endpoint': demoLoginUrl + '/oauth/v2/oauth-authorize',
        'token_endpoint': demoLoginUrl + '/oauth/v2/oauth-token',
        'introspection_endpoint': demoLoginUrl + '/oauth/v2/oauth-introspect',
        'revocation_endpoint': demoLoginUrl + '/oauth/v2/oauth-revoke',
        'userinfo_endpoint': demoLoginUrl + '/oauth/v2/oauth-userinfo',
        'jwks_uri': demoLoginUrl + '/oauth/v2/oauth-anonymous/jwks',
        'end_session_endpoint': demoLoginUrl + '/oauth/v2/oauth-session/logout',
        'backchannel_authentication_endpoint': demoLoginUrl + '/backchannel',
        'pushed_authorization_request_endpoint': demoLoginUrl + '/oauth/v2/oauth-authorize/par'
    },
    'scopes': [
        'CarInsurance',
        'address',
        'read',
        'UniversityDegreeCredentialScope',
        'phone',
        'openid',
        'profile',
        'openid_credential',
        'UniversityDegreeCredentialVerifierScope',
        'FlightTicket',
        'write',
        'email'
    ],
    'claims_supported': [
        'degreeGrade',
        'birthdate',
        'flight-date',
        'presentedDegreeType',
        'iss',
        'presentedDegreeYear',
        'preferred_username',
        'insurance-number',
        'registration-number',
        'client_id',
        'acr',
        'familyName',
        'auth_time',
        'flight-to',
        'exp',
        'givenName',
        'profile',
        'dcrm_client',
        'flight-seat',
        'nonce',
        'aud',
        'nbf',
        'authorization_details',
        'name',
        'presentedDegreeGrade',
        'phone_number',
        'car-model',
        'sub',
        'zoneinfo',
        'gender',
        'purpose',
        'degreeType',
        'amr',
        'degreeYear',
        'locale',
        'sid',
        'updated_at',
        'azp',
        'scope',
        'nickname',
        'cnf',
        'iat',
        'email',
        'jti',
        'flight-number',
        'website',
        'address',
        'email_verified',
        'phone_number_verified',
        'given_name',
        'middle_name',
        'picture',
        'delegation_id',
        'degreeSchool',
        'presentedDegreeSchool',
        'middleName',
        'family_name'
    ],
    'response_types_supported': [
        'code',
        'code id_token',
        'code id_token token',
        'code token',
        'id_token',
        'id_token token',
        'token'
    ],
    'acrs': [
        'urn:se:curity:authentication:openid-wallet:wallet',
        'urn:se:curity:authentication:username:username',
        'urn:se:curity:authentication:demo-backchannel:test'
    ],
    'backchannel_token_delivery_modes_supported': [
        'poll'
    ],
    'locales': [
        'sv',
        'pt',
        'en'
    ],
    'jwks': {
        'did:jwk:eyJrdHkiOiJFQyIsImNydiI6IlAtMjU2IiwieCI6ImlnckZtaTB3aHVpaEtuajlSM09tMVNvTXBoNzJ3VUdlRmFCYnpHMnZ6bnMiLCJ5IjoiZWZzWDViMTB4OHlqeXJqNG55M3BHZkxjWTdYYnkxS3pncU9kcW5zckpJTSJ9#0': {
            'jwk': {
                'kty': 'EC',
                'kid': 'did:jwk:eyJrdHkiOiJFQyIsImNydiI6IlAtMjU2IiwieCI6ImlnckZtaTB3aHVpaEtuajlSM09tMVNvTXBoNzJ3VUdlRmFCYnpHMnZ6bnMiLCJ5IjoiZWZzWDViMTB4OHlqeXJqNG55M3BHZkxjWTdYYnkxS3pncU9kcW5zckpJTSJ9#0',
                'use': 'sig',
                'alg': 'ES256',
                'x': 'igrFmi0whuihKnj9R3Om1SoMph72wUGeFaBbzG2vzns',
                'y': 'efsX5b10x8yjyrj4ny3pGfLcY7Xby1KzgqOdqnsrJIM',
                'crv': 'P-256'
            },
            'pem': '-----BEGIN PUBLIC KEY-----\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEigrFmi0whuihKnj9R3Om1SoMph72\nwUGeFaBbzG2vznt5+xflvXTHzKPKuPifLekZ8txjtdvLUrOCo52qeyskgw==\n-----END PUBLIC KEY-----\n'
        },
        '-1497410701': {
            'jwk': {
                'kty': 'RSA',
                'kid': '-1497410701',
                'use': 'sig',
                'alg': 'RS256',
                'n': '2lEMjrAw4RQN7WLyh_0nZTjSfzEsaXi9KwI3n_LcbAytWznd7136ZSkmbqIvWo1lQvMZ2Q0bpvzFcaEvPkxOd3hoc-Y30HqiLPdnW4TU9cziqDi40FTFJGG0_NbQK7K4evFjfmaAjJgYUYj2yHozT1_3jVyKWSRHMW5y00A8_dB0B1cVROqmAgUsZ2IDSlwP9DgAIn9j8ow6VdU-QtTNZlC6jxTbdDk3kGDQyFje-bNUoTm6CiyawDbCwHnKzgvhjpnPlXVMscVOREq_GLfrL-xWgiVFMwraZEfNIeGIe4JwPoOO9a3LSWtsYCwXiOof4t5IKTEqN-8KJPTrE9-5DQvCdVYfRR-jR3Jt9qe2gXIWxovVX5Lj987w29-HYLdoayyFWDZtWGPsExiJjX7hg-8NrPtO5_bCZQeUNFNzOF3fKxEfKS2GMCUaQ0wuWQ-0wPfGGBGRYa-P6Rf-zXRc9eNXmw8ftRR9NFuLGIJsx9UAMI6XjuqT5gHeMkbvY8EFPl8yaTQlaZcg3u6L-SNJC1ULuEUHxSgZ1RgnSjFyOvQ1GKd6baQlO-PcsC4AZP8YUu1Tx-UtlEMQMh2_OozXK9u6cPJgJpOtTUqT4RljYjXbtoQFUAE91f_4a9WDkU8jldQ3gJK9iXhutHYpUFX2XxwaWDtu7OITFLpndXIm4uU',
                'e': 'AQAB',
                'x5t': '59j-iIPscfWRnHMQGHGgoJDAr0Q'
            },
            'pem': '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA2lEMjrAw4RQN7WLyh/0n\nZTjSfzEsaXi9KwI3n/LcbAytWznd7136ZSkmbqIvWo1lQvMZ2Q0bpvzFcaEvPkxO\nd3hoc+Y30HqiLPdnW4TU9cziqDi40FTFJGG0/NbQK7K4evFjfmaAjJgYUYj2yHoz\nT1/3jVyKWSRHMW5y00A8/dB0B1cVROqmAgUsZ2IDSlwP9DgAIn9j8ow6VdU+QtTN\nZlC6jxTbdDk3kGDQyFje+bNUoTm6CiyawDbCwHnKzgvhjpnPlXVMscVOREq/GLfr\nL+xWgiVFMwraZEfNIeGIe4JwPoOO9a3LSWtsYCwXiOof4t5IKTEqN+8KJPTrE9+5\nDQvCdVYfRR+jR3Jt9qe2gXIWxovVX5Lj987w29+HYLdoayyFWDZtWGPsExiJjX7h\ng+8NrPtO5/bCZQeUNFNzOF3fKxEfKS2GMCUaQ0wuWQ+0wPfGGBGRYa+P6Rf+zXRc\n9eNXmw8ftRR9NFuLGIJsx9UAMI6XjuqT5gHeMkbvY8EFPl8yaTQlaZcg3u6L+SNJ\nC1ULuEUHxSgZ1RgnSjFyOvQ1GKd6baQlO+PcsC4AZP8YUu1Tx+UtlEMQMh2/OozX\nK9u6cPJgJpOtTUqT4RljYjXbtoQFUAE91f/4a9WDkU8jldQ3gJK9iXhutHYpUFX2\nXxwaWDtu7OITFLpndXIm4uUCAwEAAQ==\n-----END PUBLIC KEY-----\n'
        }
    },
    'token_endpoint_auth_methods_supported': [
        'client_secret_post',
        'client_secret_basic'
    ],
    'introspection_endpoint_auth_methods_supported': [
        'client_secret_post',
        'client_secret_basic'
    ],
    'revocation_endpoint_auth_methods_supported': [
        'client_secret_post',
        'client_secret_basic'
    ],
    'claims_parameter_supported': true,
    'raw_metadata': {
        'request_parameter_supported': true,
        'pushed_authorization_request_endpoint': demoLoginUrl + '/oauth/v2/oauth-authorize/par',
        'claims_parameter_supported': true,
        'introspection_endpoint': demoLoginUrl + '/oauth/v2/oauth-introspect',
        'backchannel_authentication_endpoint_auth_methods_supported': [
            'client_secret_post',
            'client_secret_basic'
        ],
        'issuer': demoLoginUrl + '/oauth/v2/oauth-anonymous',
        'authorization_endpoint': demoLoginUrl + '/oauth/v2/oauth-authorize',
        'introspection_endpoint_auth_methods_supported': [
            'client_secret_post',
            'client_secret_basic'
        ],
        'claims_supported': [
            'degreeGrade',
            'birthdate',
            'flight-date',
            'presentedDegreeType',
            'iss',
            'presentedDegreeYear',
            'preferred_username',
            'insurance-number',
            'registration-number',
            'client_id',
            'acr',
            'familyName',
            'auth_time',
            'flight-to',
            'exp',
            'givenName',
            'profile',
            'dcrm_client',
            'flight-seat',
            'nonce',
            'aud',
            'nbf',
            'authorization_details',
            'name',
            'presentedDegreeGrade',
            'phone_number',
            'car-model',
            'sub',
            'zoneinfo',
            'gender',
            'purpose',
            'degreeType',
            'amr',
            'degreeYear',
            'locale',
            'sid',
            'updated_at',
            'azp',
            'scope',
            'nickname',
            'cnf',
            'iat',
            'email',
            'jti',
            'flight-number',
            'website',
            'address',
            'email_verified',
            'phone_number_verified',
            'given_name',
            'middle_name',
            'picture',
            'delegation_id',
            'degreeSchool',
            'presentedDegreeSchool',
            'middleName',
            'family_name'
        ],
        'token_endpoint_auth_methods_supported': [
            'client_secret_post',
            'client_secret_basic'
        ],
        'tls_client_certificate_bound_access_tokens': false,
        'response_modes_supported': [
            'fragment',
            'fragment.jwt',
            'jwt',
            'form_post',
            'query',
            'query.jwt',
            'form_post.jwt'
        ],
        'backchannel_logout_session_supported': true,
        'backchannel_authentication_request_signing_alg_values_supported': [
            'RS256'
        ],
        'token_endpoint': demoLoginUrl + '/oauth/v2/oauth-token',
        'response_types_supported': [
            'code',
            'code id_token',
            'code id_token token',
            'code token',
            'id_token',
            'id_token token',
            'token'
        ],
        'mtls_endpoint_aliases': {
            'token_endpoint': demoLoginUrl + '/oauth/v2/oauth-token',
            'revocation_endpoint': demoLoginUrl + '/oauth/v2/oauth-revoke',
            'introspection_endpoint': demoLoginUrl + '/oauth/v2/oauth-introspect',
            'pushed_authorization_request_endpoint': demoLoginUrl + '/oauth/v2/oauth-authorize/par',
            'userinfo_endpoint': demoLoginUrl + '/oauth/v2/oauth-userinfo',
            'backchannel_authentication_endpoint': demoLoginUrl + '/backchannel'
        },
        'backchannel_token_delivery_modes_supported': [
            'poll'
        ],
        'revocation_endpoint_auth_methods_supported': [
            'client_secret_post',
            'client_secret_basic'
        ],
        'request_uri_parameter_supported': true,
        'grant_types_supported': [
            'refresh_token',
            'implicit',
            'client_credentials',
            'password',
            'https://curity.se/grant/accesstoken',
            'authorization_code',
            'urn:openid:params:grant-type:ciba',
            'urn:ietf:params:oauth:grant-type:pre-authorized_code'
        ],
        'ui_locales_supported': [
            'sv',
            'pt',
            'en'
        ],
        'userinfo_endpoint': demoLoginUrl + '/oauth/v2/oauth-userinfo',
        'require_request_uri_registration': true,
        'require_signed_request_object': true,
        'code_challenge_methods_supported': [
            'S256',
            'plain'
        ],
        'frontchannel_logout_session_supported': true,
        'claims_locales_supported': [
            'sv',
            'pt',
            'en'
        ],
        'authorization_signing_alg_values_supported': [
            'RS256'
        ],
        'request_object_signing_alg_values_supported': [
            'RS256'
        ],
        'prefix_scopes_supported': [],
        'authorization_response_iss_parameter_supported': true,
        'scopes_supported': [
            'CarInsurance',
            'address',
            'read',
            'UniversityDegreeCredentialScope',
            'phone',
            'openid',
            'profile',
            'openid_credential',
            'UniversityDegreeCredentialVerifierScope',
            'FlightTicket',
            'write',
            'email'
        ],
        'check_session_iframe': demoLoginUrl + '/oauth/v2/oauth-session',
        'backchannel_logout_supported': true,
        'acr_values_supported': [
            'urn:se:curity:authentication:openid-wallet:wallet',
            'urn:se:curity:authentication:username:username',
            'urn:se:curity:authentication:demo-backchannel:test'
        ],
        'userinfo_signing_alg_values_supported': [],
        'require_pushed_authorization_requests': false,
        'claim_types_supported': [
            'normal'
        ],
        'end_session_endpoint': demoLoginUrl + '/oauth/v2/oauth-session/logout',
        'revocation_endpoint': demoLoginUrl + '/oauth/v2/oauth-revoke',
        'backchannel_authentication_endpoint': demoLoginUrl + '/backchannel',
        'frontchannel_logout_supported': true,
        'jwks_uri': demoLoginUrl + '/oauth/v2/oauth-anonymous/jwks',
        'subject_types_supported': [
            'public',
            'pairwise'
        ],
        'id_token_signing_alg_values_supported': [
            'RS256'
        ]
    },
    'error': {
        'null': null
    },
    'clients': {
        '1598458021884-6HE': {
            'can_do_revoke_flow': true,
            'can_do_refresh_flow': true,
            'client_id': 'demo-web-client',
            'client_secret': '6koyn9KpRuofYt2U',
            'can_do_code_flow': true,
            'can_be_framed': false,
            'can_do_implicit_flow': true,
            'can_do_hybrid_flow': true
        },
        '1598460014215-PeB': {
            'client_id': 'demo-gateway',
            'client_secret': 'bFfUUSVsWw8AYc',
            'can_do_introspect': true
        },
        '1598460054447-gRG': {
            'can_do_revoke_flow': true,
            'can_do_refresh_flow': true,
            'client_id': 'demo-backend-client',
            'client_secret': 'MJlO3binatD9jk1',
            'can_do_client_credentials_flow': true,
            'can_do_ropc_flow': true
        },
        '1621430717428-ux0': {
            'client_id': 'demo-backchannel-client',
            'client_secret': 'LZxWLRDY7Nm',
            'can_do_revoke_flow': true,
            'can_do_refresh_flow': true,
            'can_do_ciba_flow': true
        },
        '1630497056273-g1x': {
            'can_be_framed': false,
            'can_do_code_flow': true,
            'can_do_client_credentials_flow': false,
            'can_do_implicit_flow': true,
            'can_do_hybrid_flow': true,
            'can_do_device_flow': false,
            'can_do_ciba_flow': false,
            'can_do_ropc_flow': false,
            'can_do_introspect': false,
            'client_secret': 'sbOzjQdiZYRgy8H',
            'client_id': 'demo-jar-client',
            'can_do_revoke_flow': true,
            'can_do_refresh_flow': true
        }
    },
    'request_parameter_supported': true,
    'backchannel_authentication_endpoint': demoLoginUrl + '/backchannel',
    'request_object_signing_alg_values_supported': [
        'RS256'
    ],
    'backchannel_authentication_request_signing_alg_values_supported': [
        'RS256'
    ],
    'credential_issuer': demoLoginUrl + '/oauth/v2/oauth-anonymous',
    'credential_metadata_url': demoLoginUrl + '/oauth/v2/oauth-anonymous/.well-known/openid-credential-issuer',
    'raw_credential_metadata': {
        'display': [
            {
                'name': 'Demo Verifiable Credential Issuer',
                'locale': 'en'
            },
            {
                'name': 'Demo Verifiable Credential Issuer',
                'locale': 'pt'
            },
            {
                'name': 'Demo Verifiable Credential Issuer',
                'locale': 'pt-PT'
            },
            {
                'name': 'Demo Verifiable Credential Issuer',
                'locale': 'sv'
            }
        ],
        'credential_configurations_supported': {
            'car-insurance-credential': {
                'format': 'jwt_vc_json',
                'credential_definition': {
                    'type': [
                        'VerifiableCredential',
                        'CarEnsurance'
                    ],
                    'credentialSubject': {
                        'car-model': {
                            'display': [
                                {
                                    'name': 'car-model',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'car-model',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'car-model',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'car-model',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'familyName': {
                            'display': [
                                {
                                    'name': 'familyName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'givenName': {
                            'display': [
                                {
                                    'name': 'givenName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'insurance-number': {
                            'display': [
                                {
                                    'name': 'insurance-number',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'insurance-number',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'insurance-number',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'insurance-number',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'registration-number': {
                            'display': [
                                {
                                    'name': 'registration-number',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'registration-number',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'registration-number',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'registration-number',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        }
                    }
                },
                'cryptographic_binding_methods_supported': [
                    'did:key',
                    'did:jwk'
                ],
                'display': [
                    {
                        'name': 'Car Insurance',
                        'locale': 'en',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Car Insurance Credential'
                    },
                    {
                        'name': 'Car Insurance',
                        'locale': 'pt',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Car Insurance Credential'
                    },
                    {
                        'name': 'Car Insurance',
                        'locale': 'pt-PT',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Car Insurance Credential'
                    },
                    {
                        'name': 'Car Insurance',
                        'locale': 'sv',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Car Insurance Credential'
                    }
                ]
            },
            'flight-ticket-credential': {
                'format': 'jwt_vc_json',
                'credential_definition': {
                    'type': [
                        'VerifiableCredential',
                        'FlightTicket'
                    ],
                    'credentialSubject': {
                        'familyName': {
                            'display': [
                                {
                                    'name': 'familyName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'flight-date': {
                            'display': [
                                {
                                    'name': 'flight-date',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'flight-date',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'flight-date',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'flight-date',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'flight-number': {
                            'display': [
                                {
                                    'name': 'flight-number',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'flight-number',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'flight-number',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'flight-number',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'flight-seat': {
                            'display': [
                                {
                                    'name': 'flight-seat',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'flight-seat',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'flight-seat',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'flight-seat',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'flight-to': {
                            'display': [
                                {
                                    'name': 'flight-to',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'flight-to',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'flight-to',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'flight-to',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'givenName': {
                            'display': [
                                {
                                    'name': 'givenName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        }
                    }
                },
                'cryptographic_binding_methods_supported': [
                    'did:key',
                    'did:jwk'
                ],
                'display': [
                    {
                        'name': 'Flight Ticket',
                        'locale': 'en',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Flight Ticket Credential'
                    },
                    {
                        'name': 'Flight Ticket',
                        'locale': 'pt',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Flight Ticket Credential'
                    },
                    {
                        'name': 'Flight Ticket',
                        'locale': 'pt-PT',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Flight Ticket Credential'
                    },
                    {
                        'name': 'Flight Ticket',
                        'locale': 'sv',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'Flight Ticket Credential'
                    }
                ]
            },
            'sd_jwt_university_credential': {
                'format': 'vc+sd-jwt',
                'vct': 'https://example.org/vct/university-credential',
                'claims': {
                    'degreeGrade': {
                        'display': [
                            {
                                'name': 'degreeGrade',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'degreeSchool': {
                        'display': [
                            {
                                'name': 'degreeSchool',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'degreeType': {
                        'display': [
                            {
                                'name': 'degreeType',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'degreeYear': {
                        'display': [
                            {
                                'name': 'degreeYear',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'familyName': {
                        'display': [
                            {
                                'name': 'familyName',
                                'locale': 'en'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'givenName': {
                        'display': [
                            {
                                'name': 'givenName',
                                'locale': 'en'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    }
                },
                'cryptographic_binding_methods_supported': [
                    'jwk'
                ],
                'display': [
                    {
                        'name': 'University Degree Diploma Credential',
                        'locale': 'en',
                        'description': 'University Degree Diploma Credential'
                    },
                    {
                        'name': 'University Degree Diploma Credential',
                        'locale': 'pt',
                        'description': 'University Degree Diploma Credential'
                    },
                    {
                        'name': 'University Degree Diploma Credential',
                        'locale': 'pt-PT',
                        'description': 'University Degree Diploma Credential'
                    },
                    {
                        'name': 'University Degree Diploma Credential',
                        'locale': 'sv',
                        'description': 'University Degree Diploma Credential'
                    }
                ]
            },
            'university-degree-credential': {
                'format': 'jwt_vc_json',
                'credential_definition': {
                    'type': [
                        'VerifiableCredential',
                        'UniversityDegreeCredential'
                    ],
                    'credentialSubject': {
                        'degreeSchool': {
                            'display': [
                                {
                                    'name': 'degreeSchool',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'degreeSchool',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'degreeSchool',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'degreeSchool',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'degreeType': {
                            'display': [
                                {
                                    'name': 'degreeType',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'familyName': {
                            'display': [
                                {
                                    'name': 'familyName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'familyName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'givenName': {
                            'display': [
                                {
                                    'name': 'givenName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'givenName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'degreeGrade': {
                            'display': [
                                {
                                    'name': 'degreeGrade',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'degreeGrade',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'degreeGrade',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'degreeGrade',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'degreeYear': {
                            'display': [
                                {
                                    'name': 'degreeYear',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'degreeYear',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'degreeYear',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'degreeYear',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'middleName': {
                            'display': [
                                {
                                    'name': 'middleName',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'middleName',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'middleName',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'middleName',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'address': {
                            'display': [
                                {
                                    'name': 'Address',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Endereço',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Endereço',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'address',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        }
                    }
                },
                'cryptographic_binding_methods_supported': [
                    'did:ebsi',
                    'did:key',
                    'did:jwk'
                ],
                'display': [
                    {
                        'name': 'University Degree Credential',
                        'locale': 'en',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'A verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'University Degree Credential',
                        'locale': 'pt',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'A verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'University Degree Credential',
                        'locale': 'pt-PT',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'A verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'University Degree Credential',
                        'locale': 'sv',
                        'logo': {
                            'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                        },
                        'description': 'A verifiable credential with your degree completion information.'
                    }
                ]
            },
            'university-degree-credential-with-issuer-inferred-from-key': {
                'format': 'jwt_vc_json',
                'credential_definition': {
                    'type': [
                        'VerifiableCredential',
                        'VerifiableEducationCredential'
                    ],
                    'credentialSubject': {
                        'degreeType': {
                            'display': [
                                {
                                    'name': 'degreeType',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'degreeType',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'family_name': {
                            'display': [
                                {
                                    'name': 'Last Name',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Sobrenome',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Sobrenome',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'family_name',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'given_name': {
                            'display': [
                                {
                                    'name': 'First Name',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Nome',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Nome',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'given_name',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': true
                        },
                        'birthdate': {
                            'display': [
                                {
                                    'name': 'Date of Birth',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Data de nascimento',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Data de nascimento',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'birthdate',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'gender': {
                            'display': [
                                {
                                    'name': 'Gender',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Gênero',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Gênero',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'gender',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'locale': {
                            'display': [
                                {
                                    'name': 'Locale',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Local',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Local',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'locale',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'middle_name': {
                            'display': [
                                {
                                    'name': 'Middle Name',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Nome do meio',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Nome do meio',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'middle_name',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'name': {
                            'display': [
                                {
                                    'name': 'Full Name',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Nome completo',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Nome completo',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'name',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'nickname': {
                            'display': [
                                {
                                    'name': 'Nickname',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Apelido',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Apelido',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'nickname',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'picture': {
                            'display': [
                                {
                                    'name': 'Picture',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Foto',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Foto',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'picture',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'preferred_username': {
                            'display': [
                                {
                                    'name': 'Preferred Username',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Nome de usuário preferido',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Nome de usuário preferido',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'preferred_username',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'profile': {
                            'display': [
                                {
                                    'name': 'Profile',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Perfil',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Perfil',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'profile',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'updated_at': {
                            'display': [
                                {
                                    'name': 'Last Updated',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Última atualização',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Última atualização',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'updated_at',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'website': {
                            'display': [
                                {
                                    'name': 'Website',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Website',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Website',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'website',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        },
                        'zoneinfo': {
                            'display': [
                                {
                                    'name': 'Timezone',
                                    'locale': 'en'
                                },
                                {
                                    'name': 'Fuso horário',
                                    'locale': 'pt'
                                },
                                {
                                    'name': 'Fuso horário',
                                    'locale': 'pt-PT'
                                },
                                {
                                    'name': 'zoneinfo',
                                    'locale': 'sv'
                                }
                            ],
                            'mandatory': false
                        }
                    }
                },
                'cryptographic_binding_methods_supported': [
                    'did:ebsi',
                    'did:jwk',
                    'did:key'
                ],
                'display': [
                    {
                        'name': 'Another University Degree Credential',
                        'locale': 'en',
                        'description': 'Just another verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'Another University Degree Credential',
                        'locale': 'pt',
                        'description': 'Just another verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'Another University Degree Credential',
                        'locale': 'pt-PT',
                        'description': 'Just another verifiable credential with your degree completion information.'
                    },
                    {
                        'name': 'Another University Degree Credential',
                        'locale': 'sv',
                        'description': 'Just another verifiable credential with your degree completion information.'
                    }
                ]
            }
        },
        'credential_issuer': demoLoginUrl + '/oauth/v2/oauth-anonymous',
        'authorization_servers': [
            demoLoginUrl + '/oauth/v2/oauth-anonymous'
        ],
        'credential_endpoint': demoLoginUrl + '/openid/credential'
    },
    'credential_endpoint': demoLoginUrl + '/openid/credential',
    'credentials_supported': {
        'car-insurance-credential': {
            'format': 'jwt_vc_json',
            'credential_definition': {
                'type': [
                    'VerifiableCredential',
                    'CarEnsurance'
                ],
                'credentialSubject': {
                    'car-model': {
                        'display': [
                            {
                                'name': 'car-model',
                                'locale': 'en'
                            },
                            {
                                'name': 'car-model',
                                'locale': 'pt'
                            },
                            {
                                'name': 'car-model',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'car-model',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'familyName': {
                        'display': [
                            {
                                'name': 'familyName',
                                'locale': 'en'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'givenName': {
                        'display': [
                            {
                                'name': 'givenName',
                                'locale': 'en'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'insurance-number': {
                        'display': [
                            {
                                'name': 'insurance-number',
                                'locale': 'en'
                            },
                            {
                                'name': 'insurance-number',
                                'locale': 'pt'
                            },
                            {
                                'name': 'insurance-number',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'insurance-number',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'registration-number': {
                        'display': [
                            {
                                'name': 'registration-number',
                                'locale': 'en'
                            },
                            {
                                'name': 'registration-number',
                                'locale': 'pt'
                            },
                            {
                                'name': 'registration-number',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'registration-number',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    }
                }
            },
            'cryptographic_binding_methods_supported': [
                'did:key',
                'did:jwk'
            ],
            'display': [
                {
                    'name': 'Car Insurance',
                    'locale': 'en',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Car Insurance Credential'
                },
                {
                    'name': 'Car Insurance',
                    'locale': 'pt',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Car Insurance Credential'
                },
                {
                    'name': 'Car Insurance',
                    'locale': 'pt-PT',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Car Insurance Credential'
                },
                {
                    'name': 'Car Insurance',
                    'locale': 'sv',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMTA4LjI1LDQxLjg4bC05LjgzLDkuODMtNy4zOC0xOC4xOWMtMS40Mi0zLjgxLTUuMDUtNi4zNS05LjEyLTYuMzlINDYuM2MtNC4xLS4wOS03LjgzLDIuMzctOS4zNiw2LjE4bC03LjM2LDE4LjQtOS44My05LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTM5LjQyLDcxLjM4aC4wNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik04OC41OCw3MS4zOGguMDUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cmVjdCB4PSIxOS43NSIgeT0iNTEuNzEiIHdpZHRoPSI4OC41IiBoZWlnaHQ9IjM5LjMzIiByeD0iOS44MyIgcnk9IjkuODMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48cGF0aCBkPSJNMjkuNTgsOTEuMDR2OS44MyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwYXRoIGQ9Ik05OC40Miw5MS4wNHY5LjgzIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Car Insurance Credential'
                }
            ]
        },
        'flight-ticket-credential': {
            'format': 'jwt_vc_json',
            'credential_definition': {
                'type': [
                    'VerifiableCredential',
                    'FlightTicket'
                ],
                'credentialSubject': {
                    'familyName': {
                        'display': [
                            {
                                'name': 'familyName',
                                'locale': 'en'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'flight-date': {
                        'display': [
                            {
                                'name': 'flight-date',
                                'locale': 'en'
                            },
                            {
                                'name': 'flight-date',
                                'locale': 'pt'
                            },
                            {
                                'name': 'flight-date',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'flight-date',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'flight-number': {
                        'display': [
                            {
                                'name': 'flight-number',
                                'locale': 'en'
                            },
                            {
                                'name': 'flight-number',
                                'locale': 'pt'
                            },
                            {
                                'name': 'flight-number',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'flight-number',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'flight-seat': {
                        'display': [
                            {
                                'name': 'flight-seat',
                                'locale': 'en'
                            },
                            {
                                'name': 'flight-seat',
                                'locale': 'pt'
                            },
                            {
                                'name': 'flight-seat',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'flight-seat',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'flight-to': {
                        'display': [
                            {
                                'name': 'flight-to',
                                'locale': 'en'
                            },
                            {
                                'name': 'flight-to',
                                'locale': 'pt'
                            },
                            {
                                'name': 'flight-to',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'flight-to',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'givenName': {
                        'display': [
                            {
                                'name': 'givenName',
                                'locale': 'en'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    }
                }
            },
            'cryptographic_binding_methods_supported': [
                'did:key',
                'did:jwk'
            ],
            'display': [
                {
                    'name': 'Flight Ticket',
                    'locale': 'en',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Flight Ticket Credential'
                },
                {
                    'name': 'Flight Ticket',
                    'locale': 'pt',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Flight Ticket Credential'
                },
                {
                    'name': 'Flight Ticket',
                    'locale': 'pt-PT',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Flight Ticket Credential'
                },
                {
                    'name': 'Flight Ticket',
                    'locale': 'sv',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNODcuNDEsNTIuODJsMjYuMjctMTguOThjLjY2LS40OCwxLjI0LTEuMDgsMS42Ny0xLjc4LC4wMy0uMDUsLjA3LS4xMSwuMS0uMTYsMi4xOC0zLjY1LC4zOS04LjI4LTMuNjctOS41Mi0yLjI2LS42OS00LjU0LS45OC02LjcxLTEuMDEtNi44My0uMS0xMy40MywyLjUtMTguNjQsNi45MmwtNTMuNDUsNDUuNDEtMTEuNTMtMi4yYy0zLjAxLS41Ny02LjEsLjQ4LTguMTQsMi43N2wtMS4xMSwxLjI1Yy0uOTEsMS4wMi0uNywyLjYxLC40NSwzLjM2bDEwLjA5LDYuNTljNi4xOCw0LjAzLDE0LjIsMy44NCwyMC4xNy0uNDhsMjYuMTktMTguOTIsLjMxLDM4LjYyYy4wMSwxLjY3LDEuOTgsMi41NiwzLjI0LDEuNDZsNC42OC00LjA3YzEuMzYtMS4xOCwyLjI3LTIuODEsMi41Ny00LjU4bDcuNTMtNDQuNjdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBhdGggZD0iTTYzLjQ0LDM2LjgxbC0zMy44LDMuNzFjLTEuNS0uMDctMi45OCwuMzgtNC4xOSwxLjI5bC00LjE0LDMuMDljLTEuMTIsLjgzLS43NCwyLjU5LC42MiwyLjlsMjguMDMsMS4zMmMyLjE1LC4xLDQuMjUtLjY0LDUuODYtMi4wOGw4LjcxLTcuNzZjMS4wMy0uOTIsLjI3LTIuNjItMS4xLTIuNDdaIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'Flight Ticket Credential'
                }
            ]
        },
        'sd_jwt_university_credential': {
            'format': 'vc+sd-jwt',
            'vct': 'https://example.org/vct/university-credential',
            'claims': {
                'degreeGrade': {
                    'display': [
                        {
                            'name': 'degreeGrade',
                            'locale': 'en'
                        },
                        {
                            'name': 'degreeGrade',
                            'locale': 'pt'
                        },
                        {
                            'name': 'degreeGrade',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'degreeGrade',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                },
                'degreeSchool': {
                    'display': [
                        {
                            'name': 'degreeSchool',
                            'locale': 'en'
                        },
                        {
                            'name': 'degreeSchool',
                            'locale': 'pt'
                        },
                        {
                            'name': 'degreeSchool',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'degreeSchool',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                },
                'degreeType': {
                    'display': [
                        {
                            'name': 'degreeType',
                            'locale': 'en'
                        },
                        {
                            'name': 'degreeType',
                            'locale': 'pt'
                        },
                        {
                            'name': 'degreeType',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'degreeType',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                },
                'degreeYear': {
                    'display': [
                        {
                            'name': 'degreeYear',
                            'locale': 'en'
                        },
                        {
                            'name': 'degreeYear',
                            'locale': 'pt'
                        },
                        {
                            'name': 'degreeYear',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'degreeYear',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                },
                'familyName': {
                    'display': [
                        {
                            'name': 'familyName',
                            'locale': 'en'
                        },
                        {
                            'name': 'familyName',
                            'locale': 'pt'
                        },
                        {
                            'name': 'familyName',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'familyName',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                },
                'givenName': {
                    'display': [
                        {
                            'name': 'givenName',
                            'locale': 'en'
                        },
                        {
                            'name': 'givenName',
                            'locale': 'pt'
                        },
                        {
                            'name': 'givenName',
                            'locale': 'pt-PT'
                        },
                        {
                            'name': 'givenName',
                            'locale': 'sv'
                        }
                    ],
                    'mandatory': true
                }
            },
            'cryptographic_binding_methods_supported': [
                'jwk'
            ],
            'display': [
                {
                    'name': 'University Degree Diploma Credential',
                    'locale': 'en',
                    'description': 'University Degree Diploma Credential'
                },
                {
                    'name': 'University Degree Diploma Credential',
                    'locale': 'pt',
                    'description': 'University Degree Diploma Credential'
                },
                {
                    'name': 'University Degree Diploma Credential',
                    'locale': 'pt-PT',
                    'description': 'University Degree Diploma Credential'
                },
                {
                    'name': 'University Degree Diploma Credential',
                    'locale': 'sv',
                    'description': 'University Degree Diploma Credential'
                }
            ]
        },
        'university-degree-credential': {
            'format': 'jwt_vc_json',
            'credential_definition': {
                'type': [
                    'VerifiableCredential',
                    'UniversityDegreeCredential'
                ],
                'credentialSubject': {
                    'degreeSchool': {
                        'display': [
                            {
                                'name': 'degreeSchool',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeSchool',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'degreeType': {
                        'display': [
                            {
                                'name': 'degreeType',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'familyName': {
                        'display': [
                            {
                                'name': 'familyName',
                                'locale': 'en'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'familyName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'givenName': {
                        'display': [
                            {
                                'name': 'givenName',
                                'locale': 'en'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'givenName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'degreeGrade': {
                        'display': [
                            {
                                'name': 'degreeGrade',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeGrade',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'degreeYear': {
                        'display': [
                            {
                                'name': 'degreeYear',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeYear',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'middleName': {
                        'display': [
                            {
                                'name': 'middleName',
                                'locale': 'en'
                            },
                            {
                                'name': 'middleName',
                                'locale': 'pt'
                            },
                            {
                                'name': 'middleName',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'middleName',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'address': {
                        'display': [
                            {
                                'name': 'Address',
                                'locale': 'en'
                            },
                            {
                                'name': 'Endereço',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Endereço',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'address',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    }
                }
            },
            'cryptographic_binding_methods_supported': [
                'did:ebsi',
                'did:key',
                'did:jwk'
            ],
            'display': [
                {
                    'name': 'University Degree Credential',
                    'locale': 'en',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'A verifiable credential with your degree completion information.'
                },
                {
                    'name': 'University Degree Credential',
                    'locale': 'pt',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'A verifiable credential with your degree completion information.'
                },
                {
                    'name': 'University Degree Credential',
                    'locale': 'pt-PT',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'A verifiable credential with your degree completion information.'
                },
                {
                    'name': 'University Degree Credential',
                    'locale': 'sv',
                    'logo': {
                        'uri': 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYWdlcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBkPSJNMzYuMDcsNzUuMjd2MTguMjNjMCw0Ljg0LDEyLjUxLDguNzYsMjcuOTMsOC43NnMyNy45My0zLjkyLDI3LjkzLTguNzZ2LTIwLjc0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PHBvbHlsaW5lIHBvaW50cz0iOTEuOTMgNjcuMTcgNjQgODMuOTMgMTUuNTEgNTQuODMgNjQgMjUuNzQgMTEyLjQ5IDU0LjgzIDEwMi4yNCA2MC45OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjxwb2x5bGluZSBwb2ludHM9IjY3LjMgNDYuNSAxMDQuODkgNjguMTkgMTA0Ljg5IDk2LjM4IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9zdmc+'
                    },
                    'description': 'A verifiable credential with your degree completion information.'
                }
            ]
        },
        'university-degree-credential-with-issuer-inferred-from-key': {
            'format': 'jwt_vc_json',
            'credential_definition': {
                'type': [
                    'VerifiableCredential',
                    'VerifiableEducationCredential'
                ],
                'credentialSubject': {
                    'degreeType': {
                        'display': [
                            {
                                'name': 'degreeType',
                                'locale': 'en'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'degreeType',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'family_name': {
                        'display': [
                            {
                                'name': 'Last Name',
                                'locale': 'en'
                            },
                            {
                                'name': 'Sobrenome',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Sobrenome',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'family_name',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'given_name': {
                        'display': [
                            {
                                'name': 'First Name',
                                'locale': 'en'
                            },
                            {
                                'name': 'Nome',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Nome',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'given_name',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': true
                    },
                    'birthdate': {
                        'display': [
                            {
                                'name': 'Date of Birth',
                                'locale': 'en'
                            },
                            {
                                'name': 'Data de nascimento',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Data de nascimento',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'birthdate',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'gender': {
                        'display': [
                            {
                                'name': 'Gender',
                                'locale': 'en'
                            },
                            {
                                'name': 'Gênero',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Gênero',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'gender',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'locale': {
                        'display': [
                            {
                                'name': 'Locale',
                                'locale': 'en'
                            },
                            {
                                'name': 'Local',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Local',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'locale',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'middle_name': {
                        'display': [
                            {
                                'name': 'Middle Name',
                                'locale': 'en'
                            },
                            {
                                'name': 'Nome do meio',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Nome do meio',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'middle_name',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'name': {
                        'display': [
                            {
                                'name': 'Full Name',
                                'locale': 'en'
                            },
                            {
                                'name': 'Nome completo',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Nome completo',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'name',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'nickname': {
                        'display': [
                            {
                                'name': 'Nickname',
                                'locale': 'en'
                            },
                            {
                                'name': 'Apelido',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Apelido',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'nickname',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'picture': {
                        'display': [
                            {
                                'name': 'Picture',
                                'locale': 'en'
                            },
                            {
                                'name': 'Foto',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Foto',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'picture',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'preferred_username': {
                        'display': [
                            {
                                'name': 'Preferred Username',
                                'locale': 'en'
                            },
                            {
                                'name': 'Nome de usuário preferido',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Nome de usuário preferido',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'preferred_username',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'profile': {
                        'display': [
                            {
                                'name': 'Profile',
                                'locale': 'en'
                            },
                            {
                                'name': 'Perfil',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Perfil',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'profile',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'updated_at': {
                        'display': [
                            {
                                'name': 'Last Updated',
                                'locale': 'en'
                            },
                            {
                                'name': 'Última atualização',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Última atualização',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'updated_at',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'website': {
                        'display': [
                            {
                                'name': 'Website',
                                'locale': 'en'
                            },
                            {
                                'name': 'Website',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Website',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'website',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    },
                    'zoneinfo': {
                        'display': [
                            {
                                'name': 'Timezone',
                                'locale': 'en'
                            },
                            {
                                'name': 'Fuso horário',
                                'locale': 'pt'
                            },
                            {
                                'name': 'Fuso horário',
                                'locale': 'pt-PT'
                            },
                            {
                                'name': 'zoneinfo',
                                'locale': 'sv'
                            }
                        ],
                        'mandatory': false
                    }
                }
            },
            'cryptographic_binding_methods_supported': [
                'did:ebsi',
                'did:jwk',
                'did:key'
            ],
            'display': [
                {
                    'name': 'Another University Degree Credential',
                    'locale': 'en',
                    'description': 'Just another verifiable credential with your degree completion information.'
                },
                {
                    'name': 'Another University Degree Credential',
                    'locale': 'pt',
                    'description': 'Just another verifiable credential with your degree completion information.'
                },
                {
                    'name': 'Another University Degree Credential',
                    'locale': 'pt-PT',
                    'description': 'Just another verifiable credential with your degree completion information.'
                },
                {
                    'name': 'Another University Degree Credential',
                    'locale': 'sv',
                    'description': 'Just another verifiable credential with your degree completion information.'
                }
            ]
        }
    },
    'display': [
        {
            'name': 'Demo Verifiable Credential Issuer',
            'locale': 'en'
        },
        {
            'name': 'Demo Verifiable Credential Issuer',
            'locale': 'pt'
        },
        {
            'name': 'Demo Verifiable Credential Issuer',
            'locale': 'pt-PT'
        },
        {
            'name': 'Demo Verifiable Credential Issuer',
            'locale': 'sv'
        }
    ]
};
defaultEnvironments['1594322492805-8Tw'] = curityPlaygroundEnvironment;

export const collections = { ...defaultCollections, ...defaultVciCollections };
export const environments = defaultEnvironments;
export const groups = {
    '1594322492805-8Tw': { order: Object.keys(defaultCollections), environment: '1594322492805-8Tw' },
    '1709312916849-nHR': {
        name: 'VCI',
        order: Object.keys(defaultVciCollections),
        environment: '1594322492805-8Tw',
        collapsed: false
    }
};
