/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { arrayIntersection, stringArrayToValueLabelArray } from '../../../util/util';
import { supportedClientAuthenticationMethods } from '../../../util/appConstants';

class ClientAuthenticationMethod extends React.Component{

    handleChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue(this.props.endpoint, newValue.value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);

        }
        if (actionMeta.action === 'clear') {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue(this.props.endpoint, undefined);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    render() {
        const environment = this.props.environment;
        const currentCollection = this.props.collection;
        const endpointName = this.props.endpoint;
        const environmentMethods = environment ? environment[this.props.serverConfigFrom] : [];

        const methodList = arrayIntersection(supportedClientAuthenticationMethods, environmentMethods);
        const availableOptions = stringArrayToValueLabelArray(methodList);

        const selectedMethod = currentCollection.parameters[endpointName] ? {
                label: currentCollection.parameters[endpointName],
                value: currentCollection.parameters[endpointName]
        } : null;

        return (
            <Select
                isClearable
                placeholder="Select client authentication method"
                components={makeAnimated()}
                value={selectedMethod}
                inputId={this.props.endpoint}
                options={availableOptions}
                onChange={this.handleChange}
                className="select-container"
                classNamePrefix="react-select"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />
        );
    }
}
export default ClientAuthenticationMethod;
