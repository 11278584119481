/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralClose } from '@curity-internal/ui-icons-react';
import React, { useEffect, useRef, useState } from 'react'

function CreateGroupModal(props) {
  const ref = useRef(null);
  const [inputValue, setInputValue] = useState('New Group')

  const updateInputValue = (event) => {
    setInputValue(event.currentTarget.value)
  };

  const checkEnterKey = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  }

  const submit = () => {
    props.createGroup(inputValue, props.environment);
    props.handleClose();
    setInputValue('New Group')
  }

  useEffect(() => {
    const keyboardPresses = event => {
      if (event.keyCode === 27) {
        props.handleClose();
      }
    };
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.handleClose();
      }
    };
    if (props.show) {
      document.addEventListener('keydown', keyboardPresses, false)
      document.addEventListener('mousedown', handleClickOutside, false)
    }
    return () => {
      document.removeEventListener('keydown', keyboardPresses, false)
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [props.show])


  return (
    <>
      {props.show &&
      <div className={'modal modal-is-visible'}>
        <section className="modal-main modal-share" ref={ref}>
          <header className="modal-main-header">
            <div className="flex flex-center justify-between">
              <h3 className="h4 m0">Create new group</h3>
              <div className="flex flex-center justify-between">
                <button className="button button-close button-link ml2"
                        onClick={props.handleClose}><IconGeneralClose width={18} height={18} />
                </button>
              </div>
            </div>
          </header>
          <div className="modal-main-content">
            <div className="flex justify-between">
              <div className="field-group">
                <input className="field col-12"
                       type="text"
                       onChange={updateInputValue}
                       defaultValue={inputValue}
                       autoFocus={true}
                       onKeyUp={checkEnterKey}
                />
              </div>

              <div className="ml1">
                <button
                  onClick={submit}
                  className="button button-success-outline button-small button-input">
                  Create Group
                </button>
              </div>
            </div>

          </div>
        </section>
      </div>}
    </>
  )

}

export default CreateGroupModal
