/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import Environments from '../data/Environments';
import { logModalView } from '../util/analytics';
import { resolvePlayGroundIcon } from '../util/util';
import { IconGeneralChevron, IconGeneralDuplicate, IconGeneralEdit, IconGeneralTrash, IconSystemGeneral } from '@curity-internal/ui-icons-react';

const WorkspaceMenu = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const ref = useRef(null);
  const environments = Environments.create(props.environments);
  const currentWorkspace = environments.getEnvironment(props.workspaceId);
  const playGroundIcon = resolvePlayGroundIcon(currentWorkspace, false);

  const handleActionButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const onEdit = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
    props.setShowSettings(true);
    props.setEditing(true)
  }

  const onDelete = (e) => {
    e.stopPropagation();
    setShowConfirmModal(true);
    setShowDropdown(false);
  }

  const onDuplicate = (e) => {
    e.stopPropagation();

    const defaultGroupCopies = [];
    Object.keys(props.collections).forEach(c => {
      if (props.defaultGroup.order.indexOf(c) > -1 && props.collections[c].provider === props.workspaceId) {
        defaultGroupCopies.push(c)
      }
    })
    props.duplicateEnvironment(props.workspaceId, defaultGroupCopies);
    setShowDropdown(false);
  }

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  }

  const onConfirmDelete = () => {
    setShowConfirmModal(false);

    let environmentsIds = Object.keys(props.environments);
    environmentsIds = environmentsIds.filter(e => e !== currentWorkspace.id);

    if (environmentsIds.length === 0) {
      const name = 'New Workspace';
      props.deleteEnvironment(currentWorkspace);
      props.createAndSelectEnvironment(name, false, false);
      return;
    }
    const newlySelectedEnvironment = environmentsIds[0];
    props.deleteEnvironment(currentWorkspace);
    props.changeSelectedEnvironmentWrapper(newlySelectedEnvironment, true);

  }
  const onShowSettings = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
    props.setShowSettings(true);
    props.setEditing(false);
    document.querySelector('html').style.overflow = 'hidden';
    logModalView('/environments');
  }

  useEffect(() => {
    const addEventListeners = () => {
      removeEventListeners();
      document.addEventListener('keydown', escFunction, false);
      document.addEventListener('mousedown', handleClickOutside, false);
    }

    const removeEventListeners = () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    }

    const escFunction = (event) => {
      if (event.keyCode === 27 || event.keyCode === 13) {
        setShowDropdown(false)
        removeEventListeners()
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false)
        removeEventListeners();
      }
    }

    if (showDropdown) {
      addEventListeners()
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    }
  }, [showDropdown]);

  return (
    <>
      <nav className="tools-history-workspace-menu relative" ref={ref}>
        <div className="flex workspace-menu-name justify-between"
                data-qa={currentWorkspace.name}
                onContextMenu={handleActionButtonClick} onClick={handleActionButtonClick}>
          <span>{playGroundIcon}{currentWorkspace.name}</span>
          <span className="button button-tiny button-transparent button-flat">
            <span
              className="flex flex-center justify-center"
              style={{
                transform: showDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                transformOrigin: '50% 50%',
                transition: 'transform 0.3s ease'
              }}
            >
              <IconGeneralChevron width={18} height={18} />
            </span>
          </span>
        </div>

        <div
          className={
            'context-dropdown-menu ' + (showDropdown ? 'active' : 'hide')
          }
        >
          <nav>
            <ul className="m0 list-reset">
              <li>
                <button className="button button-tiny button-link" onClick={onShowSettings}>
                <IconSystemGeneral width={16} height={16}/>Settings
                </button>
              </li>
              <li>
                <button className="button button-tiny button-link" onClick={onEdit}>
                <IconGeneralEdit width={16} height={16}/>
                  Rename
                </button>
              </li>
              <li>
                <button className="button button-tiny button-link" onClick={onDuplicate}>
                <IconGeneralDuplicate width={16} height={16}/>
                  Duplicate
                </button>
              </li>
              <li>
                <button className="button button-tiny button-link" onClick={onDelete} data-qa={'workspace-menu-delete-workspace'}>
                <IconGeneralTrash width={16} height={16}/>
                  Delete
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
      <ConfirmDeleteModal
        show={showConfirmModal}
        title={'Delete Workspace'}
        question={'Are you sure you want to delete this workspace?'}
        info={'All flows within this workspace will be deleted as well.'}
        hideConfirmModal={hideConfirmModal}
        delete={onConfirmDelete}
      />
    </>
  );
};
export default WorkspaceMenu;
