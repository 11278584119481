/*
 * Copyright (C) 2020 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useState } from 'react';
import Spinner from './components/Spinner';
import { notEmpty, randomTimeId } from './util/util';
import { flows, SIGNICAT_ISSUER_URL } from './util/appConstants'
import { Navigate } from 'react-router';
import { useParams, useLocation } from 'react-router-dom'


function CreateDemoFlow(props) {
    const { demoFlow } = useParams();
    const flowId = randomTimeId();
    const flowSupported = notEmpty(demoFlow) && notEmpty(flows[demoFlow]) && notEmpty(flows[demoFlow].demoFlow);
    const [redirect, setRedirect] = useState('')
    const { pathname, search } = useLocation();

    useEffect(() => {
        if (flowSupported) {
            props.createAndSelectEnvironment('Curity Playground', true, false, '1594322492805-8Tw')
            props.createAndSelectDemoCollection(flowId, demoFlow);
        } else if (!IS_ELECTRON_BUILD && pathname === '/curity') {
            props.createAndSelectEnvironment('Curity Playground', true, true);
        } else if (!IS_ELECTRON_BUILD && pathname === '/signicat') {
            const queryParams = new URLSearchParams(search);
            const environmentId = randomTimeId();
            const clientOverrides = {}

            if (queryParams.get('code_client_id')) {
                clientOverrides.code = {
                    client_id: queryParams.get('code_client_id'),
                    client_secret: queryParams.get('code_client_secret')
                }
            }

            if (queryParams.get('hybrid_client_id')) {
                clientOverrides.hybrid = {
                    client_id: queryParams.get('hybrid_client_id'),
                    client_secret: queryParams.get('hybrid_client_secret')
                }
            }

            if (queryParams.get('api_client_id')) {
                clientOverrides.api = {
                    client_id: queryParams.get('api_client_id'),
                    client_secret: queryParams.get('api_client_secret')
                }
            }

            const issuer = queryParams.get('issuer_override') || SIGNICAT_ISSUER_URL;

            props.createAndSelectSignicatEnvironment(environmentId, issuer, clientOverrides);
            props.discoverMetaData({ id: environmentId, issuer }, 'DISCOVERY', false);
        } else {
            console.info('Flow ' + demoFlow + ' is not supported in the demo environment');
        }

        setRedirect(<Navigate to="/"/>);
    }, []);

    return (
        <React.Fragment>
            <div>
                <div className="flex flex-center justify-center h100vh">
                    <Spinner size="medium" color="blue"/>
                </div>
                {redirect}
            </div>
        </React.Fragment>
    )
}

export default CreateDemoFlow;
