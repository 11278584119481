import React from 'react';

const Video = () => (
  <div className="video-wrapper video-wrapper-16-9 mt3 mb3">
    <iframe
      width="1120"
      height="630"
      src="https://www.youtube.com/embed/Kr0SECFMoU4"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export default Video;
