/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated';
import SelectSetting from './SelectSetting';

class Acrs extends SelectSetting {

    constructor(props) {
        super('acrs', props);
    }

    render() {
        let acrOptions = [];
        if (this.props.environment && this.props.environment.acrs) {
            const acrList = this.props.environment.acrs;
            acrOptions = acrList.map((acr) => {
                return { value: acr, label: acr }
            })
        }

        const selectedAcrs = this.props.collection.parameters.acrs;

        return (
            <Creatable
                isMulti
                placeholder="Select or add acr value"
                components={makeAnimated()}
                value={selectedAcrs}
                inputId="acrs_values"
                options={acrOptions}
                onChange={this.handleChange}
                className="select-container"
                classNamePrefix="react-select"
                theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: '#f2f3f6',
                    primary: '#626c87'
                }
                })}
            />
        );
    }
}

export default Acrs;
