/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'

class DCRCreatableSelect extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (selectedOption) => {
        const currentCollection = this.props.collection;
        let newOptions = [];
        Object.values(selectedOption).forEach((value) => {
            newOptions.push(value.value);
        });

        const updatedEnvironment = currentCollection.parameters.withUpdatedDcrParameters(this.props.field,
            newOptions);
        this.props.updateParameters(currentCollection.id, updatedEnvironment);
    };

    render() {
        const field = this.props.field;

        const options = this.props.options ? this.props.options : [];
        let value = [];
        if (this.props.collection?.parameters?.dcr_parameters[field]) {
            const optionList = this.props.collection.parameters.dcr_parameters[field];
            value = optionList.map((v) => {
                return { value: v, label: v }
            })
        }

        const noOptionsMessage = this.props.noOptionsMessage ? this.props.noOptionsMessage : 'No Options';

        return (
            <Creatable
                isMulti
                placeholder={this.props.placeholder}
                components={makeAnimated()}
                value={value}
                options={options}
                inputId={field}
                onChange={this.handleChange}
                className="select-container select-container-big"
                classNamePrefix="react-select"
                noOptionsMessage={() => noOptionsMessage}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />
        )
    }
}

export default DCRCreatableSelect;
