/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react'
import CurityIcon from '../icons/CurityIcon';
import SignicatIcon from '../icons/SignicatIcon';
import { SIGNICAT_ISSUER_URL } from '../../util/appConstants';
import { randomTimeId } from '../../util/util';
import { IconGeneralClose } from '@curity-internal/ui-icons-react';

function CreateWorkspaceModal(props) {
  const ref = useRef(null);
  const [inputValue, setInputValue] = useState('New Workspace')

  const submit = () => {
    props.createAndSelectEnvironment(inputValue, false, false);
    props.hideCreateWorkspaceModal();
    props.showSettings();
    setInputValue('New Workspace')
  }

  const addCurityPlaygroundEnvironment = () => {
    const name = 'Curity Playground';
    props.createAndSelectEnvironment(name, true, true);
    props.hideCreateWorkspaceModal();
  };

  const addSignicatPlaygroundEnvironment = () => {

    const environmentId = randomTimeId();
    props.createAndSelectSignicatEnvironment(environmentId, SIGNICAT_ISSUER_URL, {});
    props.discoverMetaData({ id:environmentId, issuer: SIGNICAT_ISSUER_URL }, 'DISCOVERY', false);
    props.hideCreateWorkspaceModal();
  };


  const checkEnterKey = (event) => {
    if (event.keyCode === 13) {
      submit();
    }
  }

  const updateInputValue = (event) => {
    setInputValue(event.currentTarget.value)
  };

  useEffect(() => {
    const keyboardPresses = event => {
      if (event.keyCode === 27) {
        props.hideCreateWorkspaceModal();
      }
    };
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.hideCreateWorkspaceModal();
      }
    };
    if (props.show) {
      document.addEventListener('keydown', keyboardPresses, false)
      document.addEventListener('mousedown', handleClickOutside, false)
    }
    return () => {
      document.removeEventListener('keydown', keyboardPresses, false)
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [props.show])


  return (
    <>
      {props.show &&
      <div className={'modal modal-is-visible'}>
        <section className="modal-main modal-share" ref={ref}>
          <header className="modal-main-header">
            <div className="flex flex-center justify-between">
              <h3 className="h4 m0">New Workspace</h3>
              <div className="flex flex-center justify-between">
                <button className="button button-close button-link ml2"
                        onClick={props.hideCreateWorkspaceModal}><IconGeneralClose width={18} height={18} />
                </button>
              </div>
            </div>
          </header>
          <div className="modal-main-content">

            <div className="flex flex-column flex-center">
              <div className="col-4">
                <input className="field col-12"
                       data-qa={'add-new-environment-input'}
                       type="text"
                       onChange={updateInputValue}
                       defaultValue={inputValue}
                       autoFocus={true}
                       onKeyUp={checkEnterKey}
                />
              </div>

              <div className="mt1 col-4">
                <button
                  onClick={submit}
                  data-qa={'add-new-environment-submit'}
                  className="button button-success button-small button-fullwidth">
                  Create Workspace
                </button>
              </div>
            </div>

            <div className="mb2 mt3 flex justify-center">
              <p className="or">
                <span>or Create a Playground</span>
                </p>
            </div>

            <div className="flex flex-column flex-center">
              <div className="mt1 col-4 center">
                <div className="center mb2">
                  <button className="button button-small button-primary button-fullwidth" onClick={addCurityPlaygroundEnvironment}>
                    <CurityIcon />Curity
                  </button>
                </div>
              </div>
            </div>
            {!IS_ELECTRON_BUILD && (
            <div className="flex flex-column flex-center">
              <div className="mt1 col-4 center">
                <div className="center mb2">
                  <button className="button button-small button-signicat button-fullwidth"
                          onClick={addSignicatPlaygroundEnvironment}>
                    <SignicatIcon/>Signicat
                  </button>
                </div>
              </div>
            </div>
            )}
          </div>
        </section>
      </div>}
    </>
  )

}

export default CreateWorkspaceModal
