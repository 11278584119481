/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

class DCRParameters {
    constructor(settings) {
        const sett = settings ? settings : {};

        this._authenticatedDCR = sett.authenticatedDCR;
        this._useTemplateClient = sett.useTemplateClient;
        this._initialAccessToken = sett.initialAccessToken;
        this._softwareId = sett.softwareId;

        this._tokenEndpointAuthMethod = sett.tokenEndpointAuthMethod;
        this._scopes = sett.scopes;
        this._grantTypes = sett.grantTypes;
        this._acrs = sett.acrs;
        this._redirectURIs = sett.redirectURIs;
        this._allowedOrigins = sett.allowedOrigins;
        this._defaultMaxAge = sett.defaultMaxAge;
        this._accessTokenTTL = sett.accessTokenTTL;
        this._refreshTokenTTL = sett.refreshTokenTTL;
        this._idTokenTTL = sett.idTokenTTL;
        this._requiresConsent = sett.requiresConsent;
        this._authenticatorFilters = sett.authenticatorFilters;
        this._subjectType = sett.subjectType;
        this._sectorIdentifierURI = sett.sectorIdentifierURI;
        this._clientName = sett.clientName;
        this._clientURI = sett.clientURI;
        this._logoURI = sett.logoURI;
        this._tosURI = sett.tosURI;
        this._policyURI = sett.policyURI;
        this._contacts = sett.contacts;

        this._settings = sett;
    }

    get authenticatedDCR() {
        return this._authenticatedDCR;
    }

    get contacts() {
        return this._contacts;
    }

    get useTemplateClient() {
        return this._useTemplateClient;
    }

    get initialAccessToken() {
        return this._initialAccessToken;
    }

    get softwareId() {
        return this._softwareId;
    }

    get tokenEndpointAuthMethod() {
        return this._tokenEndpointAuthMethod;
    }

    get scopes() {
        return this._scopes;
    }

    get grantTypes() {
        return this._grantTypes;
    }

    get acrs() {
        return this._acrs;
    }

    get redirectURIs() {
        return this._redirectURIs;
    }

    get allowedOrigins() {
        return this._allowedOrigins;
    }

    get defaultMaxAge() {
        return this._defaultMaxAge;
    }

    get accessTokenTTL() {
        return this._accessTokenTTL;
    }

    get refreshTokenTTL() {
        return this._refreshTokenTTL;
    }

    get idTokenTTL() {
        return this._idTokenTTL;
    }

    get requiresConsent() {
        return this._requiresConsent;
    }

    get authenticatorFilters() {
        return this._authenticatorFilters;
    }

    get subjectType() {
        return this._subjectType;
    }

    get sectorIdentifierURI() {
        return this._sectorIdentifierURI;
    }

    get clientName() {
        return this._clientName;
    }

    get clientURI() {
        return this._clientURI;
    }

    get logoURI() {
        return this._logoURI;
    }

    get tosURI() {
        return this._tosURI;
    }

    get policyURI() {
        return this._policyURI;
    }

    toMap = () => {
        return this._settings;
    };

    withUpdatedValue = (key, value) => {

        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new DCRParameters(next);
        } else {
            throw 'Key ' + key + ' is not a property of DCRParameters';
        }
    }
}

export default DCRParameters;
