/*
 * Copyright (C) 2020 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const primary = '#626C87';

const CurityLogo = (props) => {

  return (
    <svg viewBox="0 0 1161 331" aria-hidden="true" {...props}>
      <title>{props.title}</title>
      <defs>
        <path id="prefix__a" d="M0 6.403h1039.456V223H0V6.403z" />
        <path id="prefix__c" d="M0 6.403h1039.456V223H0V6.403z" />
      </defs>
      <g transform="translate(59 54)" fill="none" fillRule="evenodd">
        <mask
          id="prefix__b"
          fill={props.color === 'primary' ? `${primary}` : `${props.color}`}
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M298.146 143.751l-3.353 3.356c-18.933 18.891-42.73 29.733-65.304 29.733-35.813 0-61.803-26.08-61.803-62.005 0-35.903 25.362-61.972 60.313-61.972 20.242 0 44.21 9.419 61.058 23.967l3.374 2.941 33.503-34.926-3.778-3.217c-25.734-22.045-62.282-35.234-97.755-35.234-39.08 0-72.18 17.734-91.847 45.778h-61.74L67.206 82.5h50.714c-1.629 5.48-2.757 11.182-3.523 17.044H38.513l-3.608 30.317h79.481c.756 5.862 1.873 11.575 3.47 17.023H3.615l-3.618 30.328h132.291c19.678 28.257 53.203 45.79 93.614 45.79 50.745 0 86.122-25.9 102.693-41.33l3.523-3.282-33.972-34.639z"
          fill={props.color === 'primary' ? `${primary}` : `${props.color}`}
          mask="url(#prefix__b)"
        />
        <path
          d="M29.917 99.543H7.557l-3.61 30.317H26.32l3.597-30.317zm476.877 40.33c-12.229 11.384-30.822 21.8-54.13 21.8-35.387 0-58.706-25.22-58.706-56.46 0-30.583 23.478-56.281 57.887-56.281 19.561 0 38.803 7.645 51.522 18.551l-14.677 15.28c-9.78-8.452-23.478-13.655-34.887-13.655-21.52 0-35.388 16.427-35.388 36.274 0 20.007 14.187 36.275 36.207 36.275 14.507 0 28.034-7.646 37.325-16.916l14.847 15.132zm116.244-87.69v67.994c0 26.526-20.551 41.5-48.755 41.5-27.394 0-48.425-14.974-48.425-41.5V52.183h23.478v67.994c0 14.006 11.25 21.482 24.947 21.482 14.517 0 25.277-7.476 25.277-21.482V52.183h23.478zm54.94 19.682v27.822h38.154c8.153 0 14.836-5.861 14.836-13.985 0-3.579-1.309-6.839-3.756-9.281-3.747-4.067-8.472-4.556-12.708-4.556h-36.526zm-23.308 86.546V52.178h59.014c15.167 0 25.107 4.407 31.961 11.553 5.545 5.862 8.802 13.508 8.802 21.971 0 13.656-8.472 24.233-21.52 29.925l24.947 42.784h-26.735l-21.53-39.036h-31.631v39.036H654.67z"
          fill={props.color === 'primary' ? `${primary}` : `${props.color}`}
        />
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask>
        <path
          d="M781.835 52.182h23.308v106.233h-23.308V52.182zm44.353.001h100.447V72.35H888.14v86.067h-23.308V72.349h-38.644V52.183zm213.262 0l-42.23 57.746v48.487h-23.478v-48.487l-42.39-57.746h29.012l25.926 36.933 25.777-36.933h27.384z"
          fill={props.color === 'primary' ? `${primary}` : `${props.color}`}
          mask="url(#prefix__d)"
        />
      </g>
    </svg>
  );
};

export default CurityLogo;
