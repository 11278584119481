/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { backendUrl } from './appConstants'

export const fetchMetaData = (url) => {

    return new Promise(((resolve, reject) => {
        fetch(backendUrl() + '/api/client/discover', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ metadata_url: url })
        })
            .then((response) => {
                if (!response.ok) {
                    reject('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                if (data.meta_data && Object.keys(data.meta_data).length > 0) {
                    resolve(data.meta_data)
                } else {
                    reject('No metadata in response')
                }
            })
            .catch(() => {
                reject('Error when fetching data');
            })
    }));
};

export const proxiedBackendCall = (method, endpoint, parameters, headers, body, body_type) => {
    return new Promise(((resolve, reject) => {
        fetch( backendUrl() + '/api/client/proxy', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                method,
                endpoint,
                parameters,
                headers,
                request_body: body,
                body_type
            })
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                console.log('Error: ', error); //Network errors etc, not 400 and so on.
                reject('Error when contacting ' + endpoint);
            })
    }));
};
