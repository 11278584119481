/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react'
import { IconGeneralCheckmark, IconGeneralWarning } from '@curity-internal/ui-icons-react';

class Toast extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div
                className={`toast toast-${this.props.status} ${(this.props.shown ? 'toast-shown toast-active' : '')}`}
            >
                {this.props.status === 'success' ? (
                    <IconGeneralCheckmark width={24} height={24} />
                ) : (
                    <IconGeneralWarning width={24} height={24} />
                )}
                {this.props.message}
            </div>
        )
    }
}

export default Toast
