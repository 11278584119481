/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import Environment from './Environment';

class Environments {

    constructor(environments) {
        this._environments = environments;
    }

    getEnvironmentIds = () => {
        return Object.keys(this._environments);
    };

    getEnvironmentByName = (name) => {
        const environments = this._environments;
        let envId = null;
        Object.keys(environments).forEach((id) => {
            if (environments[id].name === name) {
                envId = id;
            }
        });
        return envId;
    };

    getEnvironment = (id) => {
        const env = this._environments[id];
        if (!env) {
            return null;
        }
        return new Environment(id, env);
    };

    getEnvironmentIdByIssuer = (issuer) => {
        const environments = this._environments;
        let envId = null;
        Object.keys(environments).forEach((id) => {
            if (environments[id].issuer === issuer) {
                envId = id;
            }
        });
        return envId;
    };

    toMap() {
        return this._environments;
    }

    static create = (environments) => {
        return new Environments(environments);
    }

}

export default Environments;
