import React, { useState, useEffect } from 'react';

const Triplet = ({ collection, params, toggleTriplet, children }) => {
  const [checkboxes, setCheckboxes] = useState(Array(params.length).fill(false));

  // Set first checkbox as default checked initially
  useEffect(() => {
    setCheckboxes((prevState) => {
      const newState = [...prevState];
      newState.forEach((elem, index) => {
        newState[index] = !!collection.parameters[params[index].name]
      })
      return newState;
    });
  }, [])

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    const index = Number(id.slice(-1)) - 1;
    setCheckboxes((prevState) => {
      const newState = [...prevState];
      newState[index] = checked;
      return newState;
    });
    toggleTriplet(event)
  };

  return (
    <>
      <div className="button-checkbox-group flex">
        {params.map((param, index) => (
          <div className="flex" key={param.label}>
            <input
              type="checkbox"
              data-name={param.name}
              id={`el${index + 1}`}
              data-size="large"
              className="button-checkbox"
              checked={checkboxes[index]}
              onChange={handleCheckboxChange}
            />
            <label
              aria-label={param.label}
              htmlFor={`el${index + 1}`}
              role="checkbox"
              className="button button-small button-primary-outline button-checkbox"
            >
              <span className="large"></span>
              {param.label}
            </label>
          </div>
        ))}
      </div>
      {React.Children.map(children, (child, index) =>
          collection.parameters[params[index].name] ? <div>{child}</div> : null
        )}
    </>
  );
};

export default Triplet;
