/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import ClientId from './ClientId';
import React from 'react';
import ClientSecret from './ClientSecret';
import AddClientToEnvironmentModal from './AddClientToEnvironmentModal';
import TrickAutoCompleteForms from '../../TrickAutoCompleteForms';

class ClientCredentials extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const environment = this.props.environment;
        const collection = this.props.collection;

        return (
            <React.Fragment>
                <form autoComplete="off">
                    <TrickAutoCompleteForms />

                    <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                        <div className="flex-auto">
                            <ClientId
                                updateParameters={this.props.updateParameters}
                                collection={collection}
                                environment={environment}
                                flow={this.props.flow}
                            />
                        </div>
                        {!(this.props.disableSecret) ?
                            <div className="flex-auto">
                                <ClientSecret
                                    updateParameters={this.props.updateParameters}
                                    environment={environment}
                                    collection={collection}
                                />
                            </div>
                            : ''}

                    </div>
                    <AddClientToEnvironmentModal environment={environment}
                                                 collection={collection}
                                                 flow={this.props.flow}
                                                 updateParameters={this.props.updateParameters}
                                                 updateEnvironment={this.props.updateEnvironment}
                                                 clientSecret={collection.parameters.client_secret}
                                                 clientId={collection.parameters.client_id}/>
                </form>
            </React.Fragment>
        );
    }
}

export default ClientCredentials;
