/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import store from './Store';
import App from './App';
import { Provider } from 'react-redux';
import Spinner from './components/Spinner';
import { splitParams } from './util/util';
import {
    backendUrl,
    LOCAL_STORAGE_CODE_VERIFIER,
    LOCAL_STORAGE_ID_TOKEN,
    LOCAL_STORAGE_STATE,
    OAUTH_TOOLS_LOGIN
} from './util/appConstants'

class LoginCallback extends React.Component {

    checkState = (state) => {
        const savedState = localStorage.getItem(LOCAL_STORAGE_STATE)
        localStorage.removeItem(LOCAL_STORAGE_STATE)
        return !!state && state === savedState;
    }

    componentDidMount() {
        const query = window.location.search.replace('?', '');
        const params = splitParams(query);

        if (this.checkState(params.state)) {

            if (params.code) {
                const myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
                const urlencoded = new URLSearchParams();
                urlencoded.append('endpoint', OAUTH_TOOLS_LOGIN.token_endpoint);
                urlencoded.append('client_id', OAUTH_TOOLS_LOGIN.client_id);
                urlencoded.append('redirect_uri', OAUTH_TOOLS_LOGIN.redirect_uri);
                urlencoded.append('code', params.code);
                urlencoded.append('code_verifier', localStorage.getItem(LOCAL_STORAGE_CODE_VERIFIER));
                localStorage.removeItem(LOCAL_STORAGE_CODE_VERIFIER)
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                };

                fetch(backendUrl() + '/proxy/login/token-request', requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        localStorage.setItem(LOCAL_STORAGE_ID_TOKEN, result.id_token);
                        this.props.loginHandler(result.id_token);
                    })
                    .catch(error => {
                        this.props.loginHandler(null);
                        console.log('error', error);
                    });
            } else if (params.error) {
                this.props.loginHandler(null);
            }
        } else {
            //state miss-match
            this.props.loginHandler(null);
        }
    }

    render() {

        return (
            <Provider store={store}>
                <App>
                    <React.Fragment>
                    <div className="flex flex-center justify-center h100vh">
                        <Spinner size="medium" color="blue"/>
                    </div>
                    </React.Fragment>
                </App>
            </Provider>
        )
    }
}

export default LoginCallback;
