/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { Navigate } from 'react-router';
import { Provider } from 'react-redux';
import store from './Store';
import App from './App';
import { notEmpty } from './util/util';
import { flows } from './util/appConstants';
import { useParams } from 'react-router-dom';

function CallbackContainer() {

  const { flow } = useParams();
  const query = window.location.search;
  const hash = window.location.hash;

  let CallbackComponent = null;
  if (notEmpty(flow) && notEmpty(flows[flow])) {
    CallbackComponent = flows[flow].callback;
  } else {
    return <Navigate to="/"/>
  }

  return (
    <Provider store={store}>
      <App>
        <CallbackComponent query={query} hash={hash} callbackUrl={window.location.href}/>
      </App>
    </Provider>
  )
}

export default CallbackContainer;
