/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from '../actions/actionConstants';
import { logEvent } from '../util/analytics';
import { curityPlaygroundEnvironment } from '../initstate';
import { createSignicatClients, getSignicatDefaultClients, PLAYGROUND } from '../util/appConstants';
import { isEmptyObject } from '../util/validationUtils';

function updateEnvironment(state, action) {

    const newEnvironment = action.environment;
    return {
        ...state,
        [newEnvironment.id]: newEnvironment.toMap()
    };
}

function createEnvironment(state, action) {

    logEvent('Environment', 'created', '');
    let playgroundConfig = {};
    if (action.isPlayground) {
        playgroundConfig = {
            issuer: curityPlaygroundEnvironment.issuer,
            playground: PLAYGROUND.CURITY,
            clients: curityPlaygroundEnvironment.clients
        };
    }
    return {
        ...state,
        [action.id]: {
            ...playgroundConfig,
            name: action.name
        }
    };
}

function createSignicatEnvironment(state, action) {

    logEvent('Environment', 'created', 'Signicat');
    let signicatClientConfig = {}
    if (isEmptyObject(action.clientOverrides)) {
        signicatClientConfig = getSignicatDefaultClients();
    } else {
        signicatClientConfig = createSignicatClients(action.clientOverrides);
    }

    return {
        ...state,
        [action.id]: {
            issuer: action.issuer,
            playground: PLAYGROUND.SIGNICAT,
            name: 'Signicat Playground',
            ...signicatClientConfig
        }
    };
}

function deleteEnvironment(state, action) {
    const newState = {
        ...state
    };

    delete newState[action.environment.id];
    return newState;

}

function importEnvironment(state, action) {
    const newEnvironment = action.environment;
    return {
        ...state,
        [newEnvironment.id]: newEnvironment.toMap()
    };
}

function duplicateEnvironment(state, action) {
    return {
        ...state,
        [action.newEnvironmentId]: state[action.environmentId]
    };
}

function mergeEnvironment(state, action) {
    const mergeCollection = {
        ...state[action.environmentId],
        ...action.environment
    };
    return {
        ...state,
        [action.environmentId]: mergeCollection
    };
}

function environments(state = [], action) {
    switch (action.type) {
        case C.UPDATE_ENVIRONMENT:
            return updateEnvironment(state, action);
        case C.DELETE_ENVIRONMENT:
            return deleteEnvironment(state, action);
        case C.CREATE_ENVIRONMENT:
            return createEnvironment(state, action);
        case C.CREATE_SIGNICAT_ENVIRONMENT:
            return createSignicatEnvironment(state, action);
        case C.IMPORT_ENVIRONMENT:
            return importEnvironment(state, action);
        case C.MERGE_ENVIRONMENT:
            return mergeEnvironment(state, action);
        case C.DUPLICATE_ENVIRONMENT:
            return duplicateEnvironment(state, action);
        default:
            return state;
    }
}

export default environments;
