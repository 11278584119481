/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import { tokenPurposes } from '../../util/appConstants';
import Select from 'react-select';

const TokenPurposeSelector = (props) => {

    const purposes = Object.keys(tokenPurposes).map(key => tokenPurposes[key]);
    const selectedPurpose = tokenPurposes[props.purpose];

    const onChange = (event) => {
        const newValue = event ? event.value : 'unknown';
        props.handleChange(newValue)
    }

    return (<Select
        isClearable
        placeholder="Select token purpose"
        components={makeAnimated()}
        options={purposes}
        className="select-container"
        classNamePrefix="react-select"
        value={selectedPurpose}
        inputId={'token-purpose'}
        onChange={onChange}
        theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary25: '#f2f3f6',
                primary: '#626c87'
            }
        })}
    />)
}

export default TokenPurposeSelector;
