import React from 'react';

const Award = () => (
  <a
    className="mt2 mb2 block"
    target="_blank"
    rel="noopener noreferrer"
    href="https://curity.io/news/curity-and-oauth-tools-win-api-award"
  >
    <h3>Curity&apos;s OAuth Tools receives award for Best in API Security</h3>
    <img
      className="mt2 mb2 block mx-auto"
      src="/assets/images/api-awards-award.jpg"
      alt="API Awards"
    />
  </a>
);

export default Award;
