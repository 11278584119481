/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useState } from 'react';
import {
  IMPORT_CONFIG_MODAL_SOURCE,
  IMPORT_TYPE,
  LOCAL_STORAGE_APP_STATE,
  LOCAL_STORAGE_ID_TOKEN
} from '../util/appConstants';
import { exportAppState } from '../util/util';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import {
  IconOauthToolsExport,
  IconOauthToolsImport
} from '@curity-internal/ui-icons-react';

const ImportExportButtons = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const appState = props.appState;
  const collections = props.collections;
  const environments = props.environments;
  const groups = props.groups;
  const defaultState = {
    appState,
    collections,
    environments,
    groups
  };

  const exportJsonFile = (e) => {
    if (e) {
      e.preventDefault();
    }
    // IF STATE `EXISTS` GET LOCALSTORAGE `UPDATED STATE` ELSE GET `DEFAULT INIT-STATE`:
    const data =
      localStorage[LOCAL_STORAGE_APP_STATE] || JSON.stringify(defaultState);
    const fileName = 'app-config.json';
    const fileType = 'application/json';
    const keysToRemove = ['appState'];
    if (data && Object.keys(data).length > 0) {
      exportAppState(data, fileName, fileType, keysToRemove);
    }
  };

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const onConfirmDelete = () => {
    localStorage.removeItem(LOCAL_STORAGE_APP_STATE);
    window.location.reload();
  };

  const importJsonFile = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.showImportExportModal(
      IMPORT_TYPE.IMPORT_APP_STATE,
      IMPORT_CONFIG_MODAL_SOURCE.BUTTON
    );
  };

  useEffect(() => {
    if (window && typeof window.nodeRequire !== 'undefined') {
      const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
      const addEventListeners = () => {
        removeEventListeners();
        ipcRenderer.on('import-data', (event, message) => {
          importJsonFile();
        });

        ipcRenderer.on('export-data', (event, message) => {
          exportJsonFile();
        });
        ipcRenderer.on('logout-from-menu', (event, message) => {
          localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN);
          window.location.reload();
        });
        ipcRenderer.on('clear-local-storage', (event, message) => {
          setShowConfirmModal(true);
        });
      };
      const removeEventListeners = () => {
        ipcRenderer.removeAllListeners('import-data');
        ipcRenderer.removeAllListeners('export-data');
        ipcRenderer.removeAllListeners('logout-from-menu');
        ipcRenderer.removeAllListeners('clear-local-storage');
      };

      addEventListeners();
      return () => {
        removeEventListeners();
      };
    }
  }, []);

  return (
    <>
      <button
        onClick={importJsonFile}
        className={'activity-bar-button'}
        data-tooltip="Import"
        flow="right"
        aria-label="Import"
        onMouseEnter={(event) => props.showTooltip(event)}
      >
        <IconOauthToolsImport width={28} height={28} />
      </button>
      <button
        onClick={exportJsonFile}
        className={'activity-bar-button'}
        data-tooltip="Export"
        flow="right"
        aria-label="Export"
        onMouseEnter={(event) => props.showTooltip(event)}
      >
        <IconOauthToolsExport width={28} height={28} />
      </button>
      <ConfirmDeleteModal
        show={showConfirmModal}
        title={'Clear Local Storage?'}
        question={'Are you sure you want to delete your Local Storage?'}
        info={
          'This will remove all configuration, workspaces, clients, tokens etc.'
        }
        hideConfirmModal={hideConfirmModal}
        delete={onConfirmDelete}
      />
    </>
  );
};

export default ImportExportButtons;
