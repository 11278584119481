/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */


import { randomTimeId } from '../util/util';
import C from './actionConstants';
import { IMPORT_OPTION } from '../util/appConstants';


export const createNewGroupWithId = (name, environment, groupId) => {
    return {
        type: C.CREATE_NEW_GROUP,
        groupId,
        name,
        environment
    }
}

export const createNewGroup = (name, environment) => {
    const groupId = randomTimeId();
    return {
        type: C.CREATE_NEW_GROUP,
        groupId,
        name,
        environment
    }
}

export const deleteGroup = (groupId, collectionsInGroup) => {
    return {
        type: C.DELETE_GROUP,
        groupId,
        collectionsInGroup
    }
}

export const renameGroup = (groupId, name) => {
    return {
        type: C.RENAME_GROUP,
        groupId,
        name
    }
}

export const setGroupCollapsed = (groupId, collapsed) => {
    return {
        type: C.SET_GROUP_COLLAPSED,
        groupId,
        collapsed
    }
}

export function importGroupAction(groupId, group) {
    return {
        type: C.IMPORT_GROUP,
        groupId,
        group
    }
}

export const mergeGroupAction = (groupId, group, override) => {
    return {
        type: C.MERGE_GROUP,
        groupId,
        group,
        override
    }
};

export const importGroupsActions = (groups, importActionType) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const state = getState();
            const appStateKeys = Object.keys(state.groups);
            if (importActionType === IMPORT_OPTION.MERGE) {
                Object.keys(groups).forEach(group => {
                    if (appStateKeys.indexOf(group) !== -1) {
                        // group  exists in state, merge
                        dispatch(mergeGroupAction(group, groups[group], false))
                    } else {
                        dispatch(importGroupAction(group, groups[group]));
                    }
                })

            } else if (importActionType === IMPORT_OPTION.OVERRIDE) {
                // Override Case:
                Object.keys(groups).forEach(group => {
                    if (appStateKeys.indexOf(group) !== -1) {
                        // group  exists in state, override its entries
                        dispatch(mergeGroupAction(group, groups[group], true))
                    } else {
                        dispatch(importGroupAction(group, groups[group]));
                    }
                })
            } else if (importActionType === IMPORT_OPTION.REPLACE) {
                // Delete current App State Groups:
                Object.keys(appStateKeys).forEach((group) => {
                    const groupIdToDelete = appStateKeys[group]
                    dispatch(deleteGroup(groupIdToDelete, state.groups[groupIdToDelete].order));
                });

                // Add all new groups
                Object.keys(groups).forEach(group => {
                    dispatch(importGroupAction(group, groups[group]));
                });
            }
            resolve(true);
        });
    };
}
