/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import LearnMore from '../about/LearnMore';
import CallOut from '../about/CallOut';
import AboutText from '../about/AboutText';
import StandardProtocols from '../about/StandardProtocols';
import OAuthFlows from '../about/OAuthFlows';
import Video from '../about/Video';
import Award from '../about/Award';

const About = () => {
  return (
    <div className="about-page">
      <div className="lg-flex">
        <main className="flex-70 px2 lg-px3 py2 lg-py4">
          <div className="px2 lg-px4">
            <article className="article" role="article">
              <h1 className="mt0">About OAuth Tools</h1>

              <AboutText />
              <StandardProtocols />
              <OAuthFlows />
              <Video />
              <LearnMore />
              <Award />
            </article>
          </div>
        </main>
        <div className="flex-30">
          <CallOut />
        </div>
      </div>
    </div>
  );
};

export default About;
