/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const OAuthToolsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="194.19"
    height="194.19"
    viewBox="0 0 145.65 145.65"
  >
    <path
      fill="#d959a1"
      d="M135.36 74.29l-1.12-.28a11.18 11.18 0 01-8.3-9.04l.13-.53a11.18 11.18 0 015.14-11.43l.49-.3h.01a13.58 13.58 0 004.61-18.62 13.55 13.55 0 00-18.61-4.62l-.49.3c-3.94 2.32-8.9 2-12.51-.8a11.18 11.18 0 01-4.11-11.57l.15-.59a13.56 13.56 0 00-26.31-6.53l-.28 1.12a11.18 11.18 0 01-9.04 8.3l-.53-.13c-4.5.78-9.03-1.25-11.43-5.14l-.3-.49a13.55 13.55 0 00-18.61-4.62h-.01a13.58 13.58 0 00-4.61 18.62l.3.49c2.32 3.94 2 8.9-.8 12.51a11.19 11.19 0 01-11.7 4.64l-.59-.15a13.56 13.56 0 00-6.53 26.31l1.12.28a11.18 11.18 0 018.3 9.04c.78 4.5-1.25 9.03-5.14 11.43l-.49.3h-.01a13.58 13.58 0 00-4.61 18.62 13.55 13.55 0 0018.61 4.62l.49-.3c3.94-2.32 8.9-2 12.51.8a11.19 11.19 0 014.64 11.7l-.15.59a13.56 13.56 0 0026.31 6.53l.28-1.12a11.18 11.18 0 019.04-8.3c4.5-.78 9.03 1.25 11.43 5.14l.3.49a13.55 13.55 0 0018.61 4.62h.01a13.58 13.58 0 004.61-18.62l-.3-.49c-2.32-3.94-2-8.9.8-12.51a11.17 11.17 0 0111.56-4.11l.59.15a13.56 13.56 0 006.53-26.31zm-42.73 3.37a20.33 20.33 0 11-39.47-9.8 20.33 20.33 0 0139.47 9.8z"
    ></path>
  </svg>
);

export default OAuthToolsIcon;
