/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef } from 'react'
import { IconGeneralClose } from '@curity-internal/ui-icons-react';

function ConfirmDeleteModal(props) {
    const ref = useRef(null);

    const onCancel = () => {
        props.hideConfirmModal();
    }

    const onDelete = () => {
        props.delete()
    }

    useEffect(() => {
        const keyboardPresses = event => {
            if (event.keyCode === 27) {
                props.hideConfirmModal();
            }
        };
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.hideConfirmModal();
            }
        };
        if (props.show) {
            document.addEventListener('keydown', keyboardPresses, false)
            document.addEventListener('mousedown', handleClickOutside, false)
        }
        return () => {
            document.removeEventListener('keydown', keyboardPresses, false)
            document.removeEventListener('mousedown', handleClickOutside, false)
        }
    },[props.show])


        return (
            <>
                {props.show &&
                <div className={'modal modal-is-visible'}>
                    <section className="modal-main modal-share" ref={ref}>
                        <header className="modal-main-header">
                            <div className="flex flex-center justify-between">
                                <h3 className="h4 m0">{props.title}</h3>
                                <div className="flex flex-center justify-between">
                                    <button className="button button-close button-link ml2"
                                            onClick={onCancel}><IconGeneralClose width={18} height={18} />
                                    </button>
                                </div>
                            </div>
                        </header>
                        <div className="modal-main-content">
                            <div className="center">
                            <h1 className="h3 mt0">{props.question}</h1>
                            <p>{props.info}</p>

                            <div>
                                <button
                                    onClick={onCancel}
                                    className="button button-primary-outline button-small mr1">
                                    Cancel
                                </button>
                                <button
                                    onClick={onDelete}
                                    className="button button-danger button-small">
                                    Delete
                                </button>
                            </div>
                            </div>

                        </div>
                    </section>
                </div>}
            </>
        )

}

export default ConfirmDeleteModal
