/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { IconGeneralChevron } from '@curity-internal/ui-icons-react';
import React from 'react';

class EnvironmentDropdown extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showEnvironments: false,
      showSettings: false
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleMenu = () => {
    this.setState((state) => ({
      showEnvironments: !state.showEnvironments
    }));
  };

  selectAndChangeEnvironment = (event) => {
    this.handleSelectEnvironment(event);
    this.changeSelectEnvironment(event);
  };

  handleSelectEnvironment = (event) => {
    this.props.selectProviderForCollection(
      event.currentTarget.id,
      this.props.collection.id
    );
    this.setState({
      showEnvironments: false
    });
  };

  changeSelectEnvironment = (event) => {
    const environmentId = event.currentTarget.id;
    this.props.changeSelectedEnvironmentWrapper(environmentId);
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      if (this._isMounted) {
        this.setState({
          showEnvironments: false
        });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('keydown', this.escFunction);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.escFunction);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showEnvironments: false
      });
    }
  }

  render() {
    const environments = this.props.environments;
    const environmentIds = environments.getEnvironmentIds();
    const collection = this.props.collection;

    let selectedEnvironmentName = 'No Environment';
    let selectedEnvironmentId = null;
    const selectedEnvironment = environments.getEnvironment(
      collection.provider
    );
    if (selectedEnvironment !== null) {
      selectedEnvironmentName = selectedEnvironment.name;
      selectedEnvironmentId = selectedEnvironment.id;
    }

    const environmentNumber = environmentIds.length;

    return (
      <div className="relative" ref={this.setWrapperRef}>
        <div className="inline-block ml1">
          <button
            className={
              'button button-tiny button-light has-context-dropdown-menu ' +
              (this.state.showEnvironments ? 'active' : null)
            }
            data-qa="select-environment"
            onClick={this.handleMenu}
            aria-haspopup="true"
            aria-expanded={this.state.showEnvironments ? 'true' : 'false'}
          >
            <span>ENV</span>
            {selectedEnvironmentName}
            <IconGeneralChevron width={18} height={18} />
          </button>

          <div
            className={
              'context-dropdown-menu ' +
              (this.state.showEnvironments ? 'active' : 'hide')
            }
            aria-labelledby="dropdownButton"
          >
            <nav>
              <ul
                className="m0 list-reset context-dropdown-menu-items"
                data-qa="list-of-environments"
              >
                <li className="context-dropdown-menu-items-header">
                  Environments ({environmentNumber})
                </li>
                {environmentIds.map((key) => {
                  const active = selectedEnvironmentId === key ? 'button-primary' : '';
                  return (
                    <li key={key}>
                      <button
                        className={`button button-tiny button-link ${active}`}
                        onClick={this.selectAndChangeEnvironment}
                        id={key}
                      >
                        {environments.getEnvironment(key).name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default EnvironmentDropdown;
