/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import SingleValueSetting from './SingleValueSetting';
import { IconGeneralEye, IconGeneralEyeHide } from '@curity-internal/ui-icons-react';

class Password extends SingleValueSetting {
  constructor(props) {
    super('password', props);
    this.state = {
      showPassword: false
    };
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const password = this.props.collection.parameters.password;

    return (
      <div className="field-group flex-auto">
        <input
          className={
            this.state.showPassword || !password
              ? 'field col-12'
              : 'field field-mono col-12'
          }
          placeholder="Enter user password"
          name="password"
          type={this.state.showPassword ? 'text' : 'password'}
          autoComplete="off"
          data-lpignore="true"
          spellCheck="false"
          onChange={this.updateValue}
          value={password ? password : ''}
        />
        <button
          className="show-password"
          aria-label={this.state.showSecret ? 'Hide Password' : 'Show Password'}
          type="button"
          onClick={this.handleShowPassword}
        >
          {this.state.showPassword ? (
            <IconGeneralEyeHide width={18} height={18} />
          ) : (
            <IconGeneralEye width={18} height={18} />
          )}
        </button>
      </div>
    );
  }
}

export default Password;
