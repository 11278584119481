/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import Creatable from 'react-select/creatable';

const components = {
    DropdownIndicator: null
};

const createOption = (label) => ({
    label,
    value: label
});

class ClaimsValuesSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            value: props.item.values ? props.item.values.map(v => ({
                label: v,
                value: v
            })) : []
        };
    }

    handleChange = (value, actionMeta) => {
        this.props.onChangeCallback(value);
        this.setState({ value });
    };

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (this.props.item.values === undefined || !this.props.item.values.includes(inputValue)) {
                    const newOption = createOption(inputValue);
                    this.setState({
                        inputValue: '',
                        value: [...value, newOption]
                    });
                    this.props.onChangeCallback([...value, newOption]);
                }
                event.preventDefault();
        }
    };

    render() {
        const { inputValue, value } = this.state;
        return (
            <Creatable
                isMulti
                components={components}
                inputValue={inputValue}
                value={value}
                placeholder={'Type a value and press Enter'}
                className="select-container"
                classNamePrefix="react-select"
                menuIsOpen={false}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onInputChange={this.handleInputChange}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />
        )
    }
}

export default ClaimsValuesSelect;
