/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import jose from 'node-jose'
import { TOKEN_PURPOSE_MAPPING } from './data/tokenPurposeMapping';
import { jwksRequest } from '../actions/environmentActions';
import { isEmptyObject } from './validationUtils';
import { parseSdJwt } from 'curity-ssi-libs-sd-jwt';

const tokenPurposeMap = TOKEN_PURPOSE_MAPPING;
// todo decodeAndValidateJwtWithKeys and decodeAndValidateJwt should be combined to one method
export const decodeAndValidateJwt = async (tokenData, keyValue, purpose, jwksKeys, jwksUriEndpoint, flow) => {
    const tokenMap = {};
    const plainJwt = tokenData.split('~')[0]
    tokenMap.decodedToken = await decodeJwt(tokenData);
    tokenMap.validateTokenData = {};
    if (tokenMap.decodedToken.header) {
        if (keyValue) {
            tokenMap.validateTokenData.signature = {};
            tokenMap.validateTokenData.signature.value =
                await isJwtSignatureValid(plainJwt, tokenMap.decodedToken.header.alg, keyValue);
        } else if (jwksKeys && Object.keys(jwksKeys).length > 0) {
            const keys = Object.keys(jwksKeys);
            let matchingKey = null;
            for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
                const key = jwksKeys[keys[keyIdx]];
                const isKeyValid = await isJwtSignatureValid(plainJwt, tokenMap.decodedToken.header.alg, key.pem);
                if (isKeyValid) {
                    matchingKey = key;
                    break;
                }
            }
            if (matchingKey) {
                tokenMap.validateTokenData.signature = { value: true, key: matchingKey };
            } else if (jwksUriEndpoint) {
                tokenMap.validateTokenData.updated_jwks_keys = await jwksRequest(jwksUriEndpoint).catch(handleKeyError);
            }

        }
    }
    tokenMap.validateTokenData.body = validJwtForPurpose(tokenMap.decodedToken, purpose, flow);
    return tokenMap;
};

export const decodeAndValidateJwtWithKeys = async (tokenData, keys, purpose, jwksUriEndpoint, flow) => {
    let tokenMap = {};
    const plainJwt = tokenData.split('~')[0]
    tokenMap.decodedToken = await decodeJwt(tokenData);
    tokenMap.validateTokenData = {};
    if (tokenMap.decodedToken.header && keys) {
        if (tokenMap.decodedToken.header.kid) {
            if (keys[tokenMap.decodedToken.header.kid]) {
                tokenMap.validateTokenData.signature = {};
                tokenMap.validateTokenData.signature.value = await isJwtSignatureValid(plainJwt,
                    tokenMap.decodedToken.header.alg, keys[tokenMap.decodedToken.header.kid].pem);
            } else if (keys && Object.keys(keys).length > 0) {
                let matchingKey = null;
                for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
                    const key = keys[keys[keyIdx]];
                    const isKeyValid = await isJwtSignatureValid(plainJwt,
                        tokenMap.decodedToken.header.alg, keys[tokenMap.decodedToken.header.kid].pem);
                    if (isKeyValid) {
                        matchingKey = key;
                        break;
                    }
                }
                if (matchingKey) {
                    tokenMap.validateTokenData.signature = { value: true, key: matchingKey };
                } else if (jwksUriEndpoint) {
                    const updatedJwks = await jwksRequest(jwksUriEndpoint).catch(handleKeyError);
                    tokenMap.validateTokenData.updated_jwks_keys = updatedJwks;
                    const decodedTokenkid = tokenMap.decodedToken.header.kid;
                    if (updatedJwks[decodedTokenkid]) {
                        tokenMap.validateTokenData.signature = { value: true, key: matchingKey };
                    } else {
                        tokenMap.validateTokenData.signature = { value: false, key: '' }
                    }
                }
            }
        } else {
            tokenMap = await decodeAndValidateJwt(tokenData, null, purpose, keys, jwksUriEndpoint, flow);
        }
    }
    tokenMap.validateTokenData.body = validJwtForPurpose(tokenMap.decodedToken, purpose, flow);
    return tokenMap;
};

export const decodeJwt = async (tokenData) => {
    const tokenSplit = tokenData.split('.');
    const decodedToken = {};
    try {
        if (tokenSplit[0]) {
            decodedToken.header = JSON.parse(jose.util.base64url.decode(tokenSplit[0]));
        }
        if (tokenSplit[1]) {
            decodedToken.body = JSON.parse(jose.util.base64url.decode(tokenSplit[1]));
        }
    } catch (error) {
        console.warn('Could not decode jwt: ', tokenData, error);
        return {
            header: decodedToken.header || {},
            body: decodedToken.body || {}
        };
    }
    if (tokenSplit.length > 2) {
        decodedToken.signature = tokenSplit[2];
    }

    const parsedPossibleSdJwt = await parseSdJwt(tokenData).catch(handleKeyError);
    decodedToken.disclosedClaims = parsedPossibleSdJwt && parsedPossibleSdJwt.disclosures.length > 0 ?
        parsedPossibleSdJwt.disclosedClaims : null;

    return decodedToken;
};

function handleKeyError(err) {
    console.log(err);
}

export const isJwtSignatureValid = async (token, algorithm, keyInput) => {
    if (keyInput) {
        try {
            if (algorithm.indexOf('HS') !== -1) {
                const hmacKey = await jose.JWK.asKey({
                    kty: 'oct',
                    use: 'sig',
                    alg: algorithm,
                    k: jose.util.base64url.encode(stringToArrayBuffer(keyInput, algorithm))
                });
                await jose.JWS.createVerify(hmacKey).verify(token);
            } else {
                let keyStore = await jose.JWK.createKeyStore();
                const key = await keyStore.add(keyInput, 'pem').catch(handleKeyError);
                await jose.JWS.createVerify(key).verify(token);
            }

            return true;
        } catch (err) {
            console.log('Could not verify JWT signature', err);
        }
    }
    return false;
};

export const validJwtForPurpose = (decodedToken, purpose, flow) => {
    const requiredClaims = tokenPurposeMap[purpose].required_claims;
    const optional_claims = tokenPurposeMap[purpose].optional_claims;
    const recommended_claims = tokenPurposeMap[purpose].recommended_claims;
    const payloadValidation = {};

    payloadValidation.missing_fields = [];
    requiredClaims.forEach((claim) => {
        if (decodedToken.body && !decodedToken.body[claim]) {
            payloadValidation.missing_fields.push({ 'name': claim, 'message': 'Required field is missing' });
        }
    });
    payloadValidation.missing_optional_fields = [];
    recommended_claims.forEach((claim) => {
        if (decodedToken.body && !decodedToken.body[claim]) {
            payloadValidation.missing_optional_fields.push({
                'name': claim,
                'message': 'Recommended field is missing'
            });
        }
    });
    optional_claims.forEach((claim) => {
        if (decodedToken.body && !decodedToken.body[claim]) {
            payloadValidation.missing_optional_fields.push({ 'name': claim, 'message': 'Optional field is missing' });
        }
    });

    if ((purpose === 'access_token' || purpose === 'refresh_token') && flow !== 'none' && flow !== 'create_jwt') {
        if (decodedToken.body && !decodedToken.body.sub && !decodedToken.body.aud && !decodedToken.body.scope &&
            decodedToken.body.jti.startsWith('P$')) {
            // this is likely a wrapped token, don't show the validation errors
            payloadValidation.missing_fields = null;
            payloadValidation.missing_optional_fields = null;
        }
    }

    if (purpose === 'request_object') {
        if (decodedToken.header.alg && decodedToken.header.alg !== 'none' && !decodedToken.body.iss) {
            payloadValidation.missing_optional_fields.push({
                'name': 'iss',
                'message': 'JWT is signed but iss claim is missing'
            })
        }
        if (decodedToken.header.alg && decodedToken.header.alg !== 'none' && !decodedToken.body.aud) {
            payloadValidation.missing_optional_fields.push({
                'name': 'aud',
                'message': 'JWT is signed but aud claim is missing'
            })
        }
        if (!decodedToken.body.scope || decodedToken.body.scope.indexOf('openid') < 0) {
            payloadValidation.missing_optional_fields.push({
                'name': 'scope',
                'message': 'Scope with value openid is missing'
            })
        }
    }

    //extra validation for logout token
    if (purpose === 'logout_token') {
        if (decodedToken.body && !decodedToken.body.sid && !decodedToken.body.sub) {
            payloadValidation.missing_fields.push({
                'name': 'sid or sub', 'message': 'Subject (sid) or Session ID (sid) is required'
            });
        }
        if (decodedToken.header && decodedToken.header.alg === 'none') {
            payloadValidation.missing_fields.push({
                'name': 'alg', 'message': 'Algorithm must not be none'
            });
        }
        if (decodedToken.body && decodedToken.body.iat
            && typeof decodedToken.body.iat !== 'number') {
            payloadValidation.missing_fields.push({
                'name': 'iat', 'message': 'Issued at (iat) field must be a number'
            });
        }
        if (decodedToken.body && decodedToken.body.events) {
            if (!decodedToken.body.events['http://schemas.openid.net/event/backchannel-logout']) {
                payloadValidation.missing_fields.push({
                    'name': 'events', 'message': 'Events (events) must be an object with one ' +
                        'key (http://schemas.openid.net/event/backchannel-logout) and value an empty object.'
                });
            } else if (!isEmptyObject(decodedToken.body.events['http://schemas.openid.net/event/backchannel-logout'])) {
                payloadValidation.missing_optional_fields.push({
                    'name': 'http://schemas.openid.net/event/backchannel-logout',
                    'message': 'The value of http://schemas.openid.net/event/backchannel-logout must be an empty object'
                });
            }
        }
    }


    return payloadValidation;
};

export function stringToArrayBuffer(keyInput, alg) {
    const minSize = -1 !== alg.indexOf('256') ? 64 : 128;
    const byteArray = new Uint8Array(Math.max(minSize, keyInput.length));
    for (let i = 0; i < keyInput.length; i++) {
        byteArray[i] = keyInput.codePointAt(i);
    }
    return byteArray;
}
