/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';

class LoginHint extends React.Component {

    setLoginHint = (event) => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('login_hint', event.currentTarget.value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const loginHint = this.props.collection.parameters.login_hint ? this.props.collection.parameters.login_hint : '';
        const disabled = this.props.isDisabled;

        return (
            <input className="field col-12" type="text" min="0" placeholder="Login Hint" value={loginHint}
                   onChange={this.setLoginHint}
                   data-qa={'login-hint'}
                   autoComplete="off"
                   data-lpignore="true"
                   spellCheck="false"
                   disabled={disabled}
            />
        )
    }
}

export default LoginHint
