/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from '../actions/actionConstants';
import { errorTypes } from '../util/appConstants';

function updateErrorState(state, errorType, message) {
    return {
        isError: state,
        errorType,
        message
    };
}

function appState(state = [], action) {
    let newState, newError, clearError;
    switch (action.type) {
        case C.WORKSPACE_CHANGE_COLLECTION:
            newState = {
                ...state,
                'currentCollection': action.activeCollection
            };
            if (action.activeCollection) {
                history.pushState({ urlChange: true }, null, '/collection/' + action.activeCollection);
            }

            return newState;
        case C.ERROR_SET:
             newError = updateErrorState(true, action.errorType, action.message);
            return {
                ...state,
                error: newError
            };
        case C.ERROR_CLEAR:
            clearError = updateErrorState(false, errorTypes.INFO, '');
            return {
                ...state,
                error: clearError
            };
        case C.DELETE_GROUP:
            return {
                ...state,
                'currentCollection': undefined
            };
        case C.DELETE_COLLECTION:
            return {
                ...state,
                'currentCollection': undefined
            };
        case C.CHANGE_SELECTED_ENVIRONMENT:
            return {
                ...state,
                'activeTab': action.id
            };
        default:
            return state;
    }
}

export default appState;
