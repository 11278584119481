/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect } from 'react'
import { proxiedBackendCall } from '../../util/serverUtil';
import CurityToast from '../curity-toast';
import '../curity-toast/style.css';
import { LOCAL_STORAGE_LICENSE_NOTIFICATION } from '../../util/appConstants';

const CurityNotifications = () => {

    useEffect(() => {
        proxiedBackendCall('GET', 'https://curity.io/oauth-tools.json', {})
            .then((response) => {
                if (response.status_code === 200) {
                    for (let i in response.data) {
                        const notification = response.data[i];
                        if (!localStorage.getItem(notification.id)) {
                            const Toast = new CurityToast()
                            Toast.info({
                                title: notification.title,
                                message: notification.description,
                                dismiss: true,
                                duration: 0,
                                callback: () => {
                                    if (IS_ELECTRON_BUILD) {
                                        const shell = window.nodeRequire('electron').shell;
                                        shell.openExternal(notification.link)
                                    } else {
                                        window.open(notification.link, '_blank').focus();
                                    }
                                },
                                closeCallback: () => {
                                    localStorage.setItem(notification.id, 'true');
                                },
                                closeOnCallback: true
                            })
                        }
                    }
                }
            })
            .catch(() => {
                //ignore error but catch the exception
            })

        if (window && typeof window.nodeRequire !== 'undefined') {
            const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
            const now = new Date();
            const existing_notification = localStorage.getItem(LOCAL_STORAGE_LICENSE_NOTIFICATION);
            // registering the listener only if we haven't shown the notification the last 3 days
            if (!existing_notification || existing_notification < now) {
                ipcRenderer.removeAllListeners('license_expiring');
                ipcRenderer.on('license_expiring', () => {
                    const Toast = new CurityToast()
                    Toast.info({
                        title: 'Your license is about to expire',
                        message: 'Contact <a href="mailto:sales@curity.io">sales@curity.io</a> to get a new license.',
                        dismiss: true,
                        duration: 0,
                        closeCallback: () => {
                            localStorage.setItem(LOCAL_STORAGE_LICENSE_NOTIFICATION, now.getTime() + 259200);
                        }
                    })
                });
            }
        }

    }, [])

    return <></>
}
export default CurityNotifications
