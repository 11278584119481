/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class ResponseStatusCode extends React.Component {
    constructor(props) {
        super(props);
    }

    getAlertClass = statusCode => {
        if (statusCode < 400) {
            return 'success';
        }
        if (statusCode >= 400) {
            return 'error';
        }
    };

    render() {
        const alertClass = this.getAlertClass(this.props.responseStatusCode);
        return (<>
                {this.props.responseStatusCode && <div className={'received-token '} aria-expanded="true">
                    <header className={'alert alert-' + alertClass}>
                        <h4 className="m0">
                            HTTP {this.props.responseStatusCode} {alertClass}
                        </h4>
                    </header>
                </div>
                }
            </>
        )
    }
}

export default ResponseStatusCode
