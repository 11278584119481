/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import { promptOptions } from '../../../util/appConstants';
import SelectSetting from './SelectSetting';
import Select from 'react-select';

class ForceAuthOption extends SelectSetting {

    constructor(props) {
        super('prompt', props);
    }

    render() {
        const selectedPrompt = this.props.collection.parameters.prompt;
        return (
            <Select
                isMulti
                isClearable
                placeholder="Prompt parameter"
                components={makeAnimated()}
                value={selectedPrompt}
                inputId="forceauthoption"
                options={promptOptions}
                onChange={this.handleChange}
                className="select-container"
                classNamePrefix="react-select"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#f2f3f6',
                        primary: '#626c87'
                    }
                })}
            />)
    }
}

export default ForceAuthOption;
