/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import CurityLogo from '../icons/CurityLogo';
import { IconAuthenticatorLinkedin, IconAuthenticatorX } from '@curity-internal/ui-icons-react';

const CallOut = () => (
  <div className="callout py2 lg-py4 clearfix">
    <div className="container">
      <div className="center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=About"
        >
          <CurityLogo color="white" className="callout-logo" />
        </a>

        <p className="white md-col-8 mx-auto">
          OAuth Tools has been developed as a place for users to experiment,
          learn and explore the inner workings of OpenID Connect and OAuth. You
          can easily connect the tool to any OAuth server and try the full set
          of flows.
        </p>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://developer.curity.io/free-trial?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=About"
          className="button button-success button-small"
        >
          Start free trial
        </a>

        <a
          className="mt4 mb4 block center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://curity.io/news/curity-and-oauth-tools-win-api-award"
        >
          <h4 className="white">
            Curity&apos;s OAuth Tools receives award for Best in API Security
          </h4>
          <figure className="mt3">
            <img
              className="block mx-auto"
              style={{ maxWidth: '200px' }}
              src="/assets/images/api-awards-logo.png"
              alt="API Awards"
            />
          </figure>
        </a>

        <nav className="center mt3 flex flex-gap-1 justify-center">
          <a
            href="https://x.com/curityio"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex flex-gap-1 flex-center white"
          >
            <IconAuthenticatorX stroke="transparent" width={24} height={24}/>
            @curityio
          </a>
          <a
            href="https://www.linkedin.com/company/25049399/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex flex-gap-2 flex-center white"
          >
             <IconAuthenticatorLinkedin stroke="transparent" width={24} height={24}/>
            LinkedIn
          </a>
        </nav>

        <p className="white center mt2">
          <small>
            © 2017-2024 Curity AB •{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://curity.io/terms-of-service/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=About"
            >
              Terms Of Service
            </a>
          </small>
        </p>

      </div>
    </div>
  </div>
);

export default CallOut;
