/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import {
    LOCAL_STORAGE_CODE_VERIFIER,
    LOCAL_STORAGE_ID_TOKEN,
    LOCAL_STORAGE_STATE,
    OAUTH_TOOLS_LOGIN
} from './util/appConstants'
import { generateCodeChallenge, generateRandomString } from './util/util';
import Spinner from './components/Spinner';
import CurityIcon from './components/icons/CurityIcon'
import OAuthToolsIcon from './components/icons/OAuthToolsIcon'
import CurityLogo from './components/icons/CurityLogo';
import { Link } from 'react-router-dom';
import { logEvent } from './util/analytics';
import { IconGeneralPlay, IconGeneralQuestionMark } from '@curity-internal/ui-icons-react';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cachedIdToken: localStorage.getItem(LOCAL_STORAGE_ID_TOKEN)
        };
    }

    loginState = () => {
        const state = generateRandomString(16);
        localStorage.setItem(LOCAL_STORAGE_STATE, state);
        return state;
    }

    codeVerifier = () => {
        const codeVerifier = generateRandomString(64)
        localStorage.setItem(LOCAL_STORAGE_CODE_VERIFIER, codeVerifier);
        return codeVerifier
    }

    codeChallenge = () => {
        return generateCodeChallenge(this.codeVerifier())
    }

    loginUrl = (internal) => {
        const url = new URL(OAUTH_TOOLS_LOGIN.authorize_endpoint)
        let query = `client_id=${OAUTH_TOOLS_LOGIN.client_id}`
        if (this.props.error) {
            query += `&prompt=login`
        }
        if (internal) {
            query += `&internal`
        }
        query += `&response_type=code`
        query += `&state=${this.loginState()}`
        query += `&code_challenge=${this.codeChallenge()}&code_challenge_method=S256`
        query += `&scope=${OAUTH_TOOLS_LOGIN.scopes}`
        query += `&redirect_uri=${OAUTH_TOOLS_LOGIN.redirect_uri}`
        url.search = query;

        return url.toString();
    };

    runLoginFlow = (event) => {
        if (window && window.nodeRequire) {
            const shell = window.nodeRequire('electron').shell;
            shell.openExternal(this.loginUrl(event.metaKey))
        }
    };

    handleDoubleClick = () => {
        if (window && window.nodeRequire) {
            const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
            ipcRenderer.send('toggleMaximize', {})
        }
    }

    toggleVideo = (e) => {
        e.preventDefault(); // avoid "#" on URL
        this.setState({ showVideo: !this.state.showVideo });
        if (!this.state.showVideo) {
            logEvent('Video', 'clicked', 'Clicked video link in header');
            document.querySelector('html').style.overflow = 'hidden';
        } else {
            document.querySelector('html').style.overflow = '';
        }
    };

    componentDidMount() {
        if (this.state.cachedIdToken) {
            this.props.checkIdToken(this.state.cachedIdToken)
                .then(res => {
                    if (res) {
                        this.props.loginHandler(this.state.cachedIdToken)
                    } else {
                        localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN)
                        this.setState({
                            cachedIdToken: null
                        })
                    }
                })
        }
    }

    render() {

        const greeting = this.props.user ? 'Your account with email '+ this.props.user +' doesn\'t' :
            'You don\'t';
        const error = this.props.error && <div className={'alert alert-danger mb2'}>
            <p className={'mb0 py1'}>{greeting} have access to this application.<br/>
            Please contact <a href={'mailto:sales@curity.io'}>sales@curity.io</a> or use a different account
            </p>
        </div>

        return (<React.Fragment>
            <header className="header" role="banner">
            <div
                className="header-stripe flex flex-center justify-between px1"
                onDoubleClick={this.handleDoubleClick}
            >
                <div className="header-presentation flex justify-between">


                    <Link to="/" className="header-logo white">
                        <OAuthToolsIcon/>
                        OAuth Tools
                    </Link>

                    <div
                        className={'video-screen ' + (this.state.showVideo ? 'active' : '')}
                    >
                        <div>
                        {this.state.showVideo && (
                            <div className="video-wrapper video-wrapper-16-9 mt3 mb3">
                            <iframe
                                src="https://www.youtube.com/embed/Kr0SECFMoU4"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                            </div>
                        )}
                        </div>
                    </div>
                </div>

                <nav role="navigation">
                <a href="#" onClick={this.toggleVideo} className="inline-flex flex-center flex-gap-1">
                    <IconGeneralPlay width={16} height={16}/>
                    Getting started
                </a>

                    <Link to="/about" className="inline-flex flex-center flex-gap-1">
                        <IconGeneralQuestionMark width={16} height={16}/>
                        About
                    </Link>
                </nav>
                <div className="header-service-from">
                    <a
                    className="flex flex-center justify-center"
                    href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=Header"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit Curity.io"
                    >
                    <small className="flex-auto">A learning tool from</small>
                    <CurityLogo
                        title="Curity"
                        color="white"
                        className="header-powered-by"
                    />
                    </a>
                </div>
            </div>
            </header>
                {!this.state.cachedIdToken &&

                <div className="flex" style={{ minHeight: 'calc(100vh - 41px)' }}>

                <aside className="flex flex-center" style={{ flex: '0 0 30%', backgroundColor: '#3c4251',
                    width: '40%', height: 'calc(100vh - 41px)' }}>
                    <img src="/assets/images/login-background.svg" role="presentation" alt="OAuth Tools Login background"/>
                </aside>

                <main className="flex flex-column flex-center justify-center" style={{ flex: '0 0 70%' }} >
                    <div className="header-logo">
                        <a
                            href="/"
                            title="Tools for exploring and testing OAuth and OpenID Connect flows">
                            <h1 className="flex flex-center justify-center m0">
                                <span className="header-logo-symbol"/>
                                <span className="visually-hidden">OAuth Tools</span>
                            </h1>
                        </a>
                    </div>

                    <div className="center">
                        <h1 className="primary h3">Login</h1>
                        <p>Login using your Curity account</p>
                        {error}
                        <button onClick={this.runLoginFlow}
                                className={'button button-medium button-fullwidth button-primary '}>
                                 <CurityIcon />
                            <span>Sign In with Curity</span>
                        </button>
                    </div>
                </main>
                </div>
                }
                {this.state.cachedIdToken &&
                <div className="flex flex-center justify-center h100vh">
                    <Spinner size="medium" color="blue"/>
                </div>
                }
            </React.Fragment>
        );
    }
}

export default Login;
