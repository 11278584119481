/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.send({ hitType: 'pageview', page });
    };

    return function Component(props) {
        const { pathname } = useLocation();
        useEffect(()=>{
            trackPage(pathname);
        });

        return <WrappedComponent {...props} />;
    };
}
