/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { combineReducers } from 'redux'
//import { routerReducer} from 'react-router-redux';

import collections from './collections';
import groups from './groups';
import appState from './appState';
import environments from './environments';

//const rootReducer = combineReducers({collections, tokens, appState, environments , routing: routerReducer});
const rootReducer = combineReducers({ collections, groups, appState, environments });

export default rootReducer;
