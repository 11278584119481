/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guideIsVisible: false
    };

    this.showGuide = this.showGuide.bind(this);
  }

  showGuide() {
    if (this.props.toggle) {
      this.props.toggle(!this.state.guideIsVisible);
    }
    this.setState((state) => ({
      guideIsVisible: !state.guideIsVisible
    }));
  }

  render() {
    const area = this.props.area;

    return (
      <>
        {area && (
          <>
            <nav
              className="tools-sidebar-nav flex justify-end p1"
              role="navigation"
            >
              <button
                onClick={this.showGuide}
                aria-expanded={this.state.guideIsVisible ? 'true' : 'false'}
                className="button button-tiny button-light "
                aria-label={`${
                  this.state.guideIsVisible ? 'Hide guide' : 'Show guide'
                }`}
              >
                {this.state.guideIsVisible ? 'Hide guide' : 'Show guide'}
              </button>
            </nav>

            <div
              className={
                'tools-sidebar-guide ' +
                (this.state.guideIsVisible ? 'active' : '')
              }
            >
              <h3 className="mt0">{area.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: area.summary }} />

              {area.image && (
                <img
                  className="mb2"
                  src={area.image}
                  alt={area.title}
                  role="presentation"
                  loading="lazy"
                />
              )}

              {area.link && (
                <div className={'mb4 center'}>
                  <a
                    href={`${area.link}/?utm_source=oauth.tools&utm_medium=Link&utm_content=Guide`}
                    title="Read more in Curity Resources"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button-primary button-small"
                  >
                    Read more
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Guide;
