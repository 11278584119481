/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import {
  IconGeneralDuplicate,
  IconGeneralEdit,
  IconGeneralTrash,
  IconSystemGeneral
} from '@curity-internal/ui-icons-react';

function EnvironmentTile(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [contextDropdownTop, setContextDropdownTop] = useState('');
  const ref = useRef(null);

  const getInitialsAttribute = (name) => {
    const nameWords = name.split(' ');
    let initials = nameWords[0][0];
    if (nameWords.length > 1 && nameWords[1].length > 0) {
      initials = initials + nameWords[1][0];
    } else if (nameWords[0].length > 1) {
      initials = initials + nameWords[0][1];
    }
    return initials;
  }

  const selectEnvironment = () => {
    props.changeSelectedEnvironmentWrapper(props.environmentId, true);
  }

  const stringToHSL = (str) => {
    let hash = 0;
    if (str && str.length !== 0) {
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
      }
    }

    let shortened = hash % 360;
    return 'hsl(' + shortened + ',100%,30%)';
  }

  const showContextMenu = (e) => {
    e.preventDefault();
    setContextDropdownTop(e.target.getBoundingClientRect().top)
    setShowDropdown(true)
  }

  const onShowSettings = (e) => {
    e.stopPropagation();
    props.changeSelectedEnvironmentWrapper(props.environmentId, true)
    props.showSettings();
    setShowDropdown(false);
  }

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  }

  const onConfirmDelete = () => {
    setShowConfirmModal(false);
    props.handleDeleteEnvironment(props.environmentId);
  }

  const onDelete = (e) => {
    e.stopPropagation();
    setShowConfirmModal(true);
    setShowDropdown(false);
  }

  const onEdit = (e) => {
    e.stopPropagation();
    props.changeSelectedEnvironmentWrapper(props.environmentId, true)
    props.showSettings(true);
    setShowDropdown(false);
  }

  const onDuplicate = (e) => {
    e.stopPropagation();

    const defaultGroupCopies = [];
    Object.keys(props.collections).forEach(c => {
      if (props.defaultGroup.order.indexOf(c) > -1 && props.collections[c].provider === props.environmentId) {
        defaultGroupCopies.push(c)
      }
    })
    props.duplicateEnvironment(props.environmentId, defaultGroupCopies);
    setShowDropdown(false);
  }

  useEffect(() => {
    const addEventListeners = () => {
      removeEventListeners();
      document.addEventListener('keydown', escFunction, false);
      document.addEventListener('mousedown', handleClickOutside, false);
    }

    const removeEventListeners = () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    }

    const escFunction = (event) => {
      if (event.keyCode === 27 || event.keyCode === 13) {
        setShowDropdown(false)
        removeEventListeners()
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false)
        removeEventListeners();
      }
    }

    if (showDropdown) {
      addEventListeners()
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    }
  }, [showDropdown]);

  const showTooltip = (event) => {
    document.documentElement.style.setProperty('--activity-bar-tooltip-position-top', `${event.target.getBoundingClientRect().top}px`);
  }

  return (
    <>
      <div ref={ref}
           className={'environment-initials' + (props.selected ? ' environment-initials-selected' : '')}
           style={{ background: stringToHSL(props.environmentId) }}
           onClick={selectEnvironment}
           onDoubleClick={onShowSettings}
           onContextMenu={showContextMenu}
           flow={'right'}
           data-tooltip={props.environmentName}
           aria-label={props.environmentName}
           onMouseEnter={(event) => showTooltip(event)}
           >
            {getInitialsAttribute(props.environmentName)}
        <div className={'context-dropdown-menu ' + (showDropdown ? 'active' : 'hide')}
             aria-labelledby={'dropdownButton'}
             style={{ top: contextDropdownTop }}>
          <nav>
            <ul className={'m0 list-reset'}>
              <li>
                <button className="button button-small button-transparent" onClick={onShowSettings}>
                   <IconSystemGeneral width={20} height={20}/>
                  Settings
                </button>
              </li>
              <li>
                <button className="button button-small button-transparent" onClick={onEdit}>
                   <IconGeneralEdit width={20} height={20}/>
                  Rename
                </button>
              </li>
              <li>
                <button className="button button-small button-transparent" onClick={onDuplicate}>
                   <IconGeneralDuplicate width={20} height={20}/>
                  Duplicate
                </button>
              </li>
              <li>
                <button className="button button-small button-transparent" onClick={onDelete}>
                   <IconGeneralTrash width={20} height={20}/>
                  Delete
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <ConfirmDeleteModal
        show={showConfirmModal}
        title={'Delete Workspace'}
        question={'Are you sure you want to delete this workspace?'}
        info={'All flows within this workspace will be deleted as well.'}
        hideConfirmModal={hideConfirmModal}
        delete={onConfirmDelete}
      />
    </>
  )
}

export default EnvironmentTile;
