/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Settings from '../settings/Settings';
import WithErrorHandling from '../WithErrorHandling';
import { logModalView } from '../../util/analytics';
import Collection from '../../data/Collection';
import ImportAppStateModal from '../modals/ImportAppStateModal';
import Toast from '../shared/Toast';
import ImportExportButtons from '../ImportExportButtons';
import {
    getSharedConfigType,
    isHashFragmentExists,
    isQueryParamsExists,
    parseQueryParams,
    splitParams
} from '../../util/util';
import ShareConfigPermalinkModal from '../modals/shareConfigPermalinkModal';
import {
    ERROR_HANDLING_MESSAGES,
    IMPORT_CONFIG_MODAL_SOURCE,
    IMPORT_TYPE,
    TOAST_NOTIFICATION_TYPE,
    shareableConfigUrl
} from '../../util/appConstants';
import Environments from '../../data/Environments';
import EnvironmentTile from './EnvironmentTile';
import CurityNotifications from '../shared/CurityNotifications';
import CreateWorkspaceModal from '../modals/CreateWorkspaceModal';
import { ColorSchemeSwitcher } from '../shared/ColorSchemeSwitcher';
import { IconGeneralPlus, IconGeneralShare } from '@curity-internal/ui-icons-react';

const ErrorHandling = WithErrorHandling(({ children }) => <div>{children}</div>);

class ActivityBar extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showImportModal: false,
            showNewWorkspaceModal: false,
            importType: '',
            toastNotification: {
                toastStatus: '',
                toastMessage: '',
                toastShown: false
            },
            sharedPermalinkModalConfig: {
                showSharedConfigModal: false,
                title: '',
                description: '',
                modalSource: null,
                isIssuerExists: false,
                issuerName: null,
                config: null,
                permalinkValue: '',
                errorExists: false,
                errorMessage: ''
            }
        };
    }

    showImportExportModal = (importType, modalSource) => {
        this.setState({
            showImportModal: true,
            importType,
            sharedPermalinkModalConfig: {
                ...this.state.sharedPermalinkModalConfig,
                modalSource
            }
        });
        logModalView('/import-export-state');
    }

    showToastNotification = (toastStatus, toastMessage, toastShown) => {
        this.setState(
            {
                toastNotification: {
                    toastStatus,
                    toastMessage,
                    toastShown
                }
            },
            () => {// Disable notification by removing `toast-shown toast-active' classes:
                setTimeout(() => {
                    this.setState({
                        toastNotification: {
                            toastShown: false
                        }
                    });
                }, 5000);
            }
        );
        logModalView('/toast-notification');
    }

    keyboardPresses = event => {
        if (this._isMounted) {
            if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA' || event.target.className === 'ace_content') return;
            if (event.keyCode === 27) { // 'Escape' key
                if (this._isMounted) {
                    if (this.state.showVideo) {
                        this.setState({ showVideo: false });
                    }
                    if (this.props.showSettings && !this.state.showImportModal) {
                        this.props.setShowSettings(false);
                    }
                    if (this.state.showImportModal) {
                        this.setState({ showImportModal: false });
                    }
                }
            }
            if (event.keyCode === 78) {
                //'n' key → New Flow
                this.hideSettings();
            }
            if (event.keyCode === 69) {
                // 'e' key → Environments
                this.showSettings();
            }
        }
    };

    showSettings = (edit=false) => {
        this.props.setShowSettings(true);
        this.props.setEditing(edit);
        document.querySelector('html').style.overflow = 'hidden';
        logModalView('/environments');
    };

    hideSettings = () => {
        this.props.setShowSettings(false);
        document.querySelector('html').style.overflow = '';
    };

    hideImportExport = () => {
        this.setState({ showImportModal: false });
        document.querySelector('html').style.overflow = '';
    };

    createSharableLink = (event) => {
        this.props.createSharedConfigPermalink()
            .then((data) => {
                if (!data.success && data.statusCode !== 201) {
                    this.setState({
                        sharedPermalinkModalConfig: {
                            ...this.state.sharedPermalinkModalConfig,
                            showSharedConfigModal: true,
                            permalinkValue: null,
                            errorMessage: `${data.statusCode}:${ERROR_HANDLING_MESSAGES.SHARED_CONFIG_URL}`,
                            errorExists: true
                        }
                    });
                } else {
                    const sharedConfigUrl = `${shareableConfigUrl()}/c/${data.configHash}#${data.sharedSecret}`;
                    this.setState({
                        sharedPermalinkModalConfig: {
                            showSharedConfigModal: true,
                            permalinkValue: sharedConfigUrl
                        }
                    });
                }

                document.querySelector('html').style.overflow = 'hidden';
            }).catch((error) => {
            if (!error.success) {
                this.showToastNotification(TOAST_NOTIFICATION_TYPE.TOAST_ERROR,
                    ERROR_HANDLING_MESSAGES.SHARED_CONFIG_URL, true);
                throw new Error(error);
            }
        })
            .finally(() => {
                document.querySelector('html').style.overflow = '';
            })
    };

    hideSharedConfigModal = () => {
        this.setState({
            sharedPermalinkModalConfig: {
                showSharedConfigModal: false,
                permalinkValue: null
            }
        });
        document.querySelector('html').style.overflow = '';

    };

    hideSharedConfigModalError = () => {
        this.setState({
            sharedPermalinkModalConfig: {
                ...this.state.sharedPermalinkModalConfig,
                permalinkValue: '',
                errorExists: false,
                errorMessage: ''
            }
        }, () => {
            console.log(this.state);
        })
    };

    setEditing = (edit) => {
        this.props.setEditing(edit);
    }

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener('keydown', this.keyboardPresses, false);
        const hash = window.location.hash.replace('#', '');
        const params = splitParams(hash);
        if (params['new-env'] && params.webfinger) {
            this.props.createEnvironmentWithWebFinger(params['new-env']);
            this.showSettings();
        }

        if (params.jwt) {
            let currentCollection;
            if (Object.keys(this.props.collections).length > 0) {
                currentCollection = Collection.getCollection(this.props.appState, this.props.collections);
                this.props.createAndSelectCollectionWithToken('none', currentCollection.provider,
                    params.jwt, 'default');
            } else {
                currentCollection = null;
                this.props.createAndSelectCollectionWithToken('none', null,
                    params.jwt, 'default');
            }
        }

        const location = window.location;
        if (location.href.indexOf('/c/') > -1 && location.hash) {
            // Handle Business Logic of Shared Config URL:
            this.handleSharedUrl();
        }

    }

    handleSharedUrl = async () => {
        const location = window.location;
        const getPlainTextResponse = await this.getDecryptedConfig(location.href);

        if (getPlainTextResponse.success) {
            const configType = getSharedConfigType(getPlainTextResponse.data);
            if (configType !== IMPORT_TYPE.IMPORT_INVALID) {
                const url = location.href;
                if (!isQueryParamsExists(url) && configType === IMPORT_TYPE.IMPORT_APP_STATE) {
                    if (isHashFragmentExists(location.hash)) {
                        const isIssuerExists = false;
                        this.updateStateAndShowShareableLinkModal(getPlainTextResponse.data,
                            IMPORT_TYPE.IMPORT_APP_STATE, IMPORT_CONFIG_MODAL_SOURCE.SHARED_LINK, isIssuerExists, '');
                    }
                } else if (isQueryParamsExists(url) || configType === IMPORT_TYPE.IMPORT_CLIENTS) {
                    let isIssuerExists;
                    let queryParams = parseQueryParams(url);
                    if (Object.keys(queryParams).indexOf('issuer') !== -1 && queryParams.issuer !== null) {
                        const environmentId = this.getEnvironmentIdByIssuerWrapper(queryParams.issuer);
                        if (environmentId !== null && environmentId !== undefined) {
                            isIssuerExists = true;
                            // UPDATE SELECTED ENVIRONMENT:
                            this.props.changeSelectedEnvironment(environmentId);
                        }
                        let issuer = queryParams.issuer ? queryParams.issuer : '';
                        this.updateStateAndShowShareableLinkModal(getPlainTextResponse.data,
                            IMPORT_TYPE.IMPORT_CLIENTS, IMPORT_CONFIG_MODAL_SOURCE.SHARED_LINK, isIssuerExists, issuer);
                    } else {
                        // IMPORT CONFIG ON USER SELECTED ENVIRONMENT:
                        isIssuerExists = false;
                        this.updateStateAndShowShareableLinkModal(getPlainTextResponse.data,
                            IMPORT_TYPE.IMPORT_CLIENTS, IMPORT_CONFIG_MODAL_SOURCE.SHARED_LINK, false, '');
                    }
                }
            }
        } else {
            // Case: When Proper Error Object Returned Catch Message:
            const message = getPlainTextResponse.error || ERROR_HANDLING_MESSAGES.SHARED_URL_CORRUPTED;
            this.showToastNotification(TOAST_NOTIFICATION_TYPE.TOAST_ERROR, message, true);
        }


    };

    getDecryptedConfig = async (url) => {
        return this.props.importShareableConfig({ url: location.href })
            .then((decryptedTextResponse) => {
                return decryptedTextResponse;
            }, (error) => {
                return error;
            });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateStateAndShowShareableLinkModal = (config, importType, modalSource, isIssuerExists, issuerName) => {
        this.setState({
            showImportModal: true,
            importType,
            sharedPermalinkModalConfig: {
                ...this.state.sharedPermalinkModalConfig,
                config,
                modalSource,
                isIssuerExists,
                issuerName
            }
        });
    };


    getEnvironmentIdByIssuerWrapper = (issuer) => {
        const environments = Environments.create(this.props.environments);
        return environments.getEnvironmentIdByIssuer(issuer);
    };

    handleDeleteEnvironment = (environmentId) => {
        const environments = Environments.create(this.props.environments);
        const currentEnvironment = environments.getEnvironment(environmentId);

        let environmentsIds = Object.keys(this.props.environments);
        environmentsIds = environmentsIds.filter(e => e !== currentEnvironment.id);

        if (environmentsIds.length === 0) {
            const name = 'New Workspace';
            this.props.deleteEnvironment(currentEnvironment);
            this.props.createAndSelectEnvironment(name, false, false);
            return;
        }
        const newlySelectedEnvironment = environmentsIds[0];
        this.props.deleteEnvironment(currentEnvironment);
        this.props.changeSelectedEnvironmentWrapper(newlySelectedEnvironment, true);
    }

    showTooltip(event) {
        document.documentElement.style.setProperty('--activity-bar-tooltip-position-top', `${event.target.getBoundingClientRect().top}px`);
    }

    render() {
        const errorState = this.props.appState.error;

        return (
          <ErrorHandling errorState={errorState}>
            <div className={'activity-bar'}>
              <nav>
                  {Object.keys(this.props.environments).map(env =>
                    <EnvironmentTile key={env}
                                     collections={this.props.collections}
                                     defaultGroup={this.props.groups.default}
                                     selected={env === this.props.appState.activeTab}
                                     showSettings={this.showSettings}
                                     handleDeleteEnvironment={this.handleDeleteEnvironment}
                                     duplicateEnvironment={this.props.duplicateEnvironment}
                                     changeSelectedEnvironmentWrapper={this.props.changeSelectedEnvironmentWrapper}
                                     environmentId={env} environmentName={this.props.environments[env].name}/>
                  )}

                <button
                    data-tooltip={'New Workspace'}
                    className={'activity-bar-button'}
                    data-qa={'add-new-environment'}
                    flow={'right'}
                    aria-label={'New Workspace'}
                    onClick={() => this.setState({ showNewWorkspaceModal: true })}
                    onMouseEnter={(event) => this.showTooltip(event)}>
                    <IconGeneralPlus width={24} height={24}/>
                </button>


                <ImportExportButtons {...this.props}
                                     showTooltip={this.showTooltip}
                                     showImportExportModal={this.showImportExportModal}/>

                <button
                    data-tooltip={'Share link'}
                    className={'activity-bar-button'}
                    flow={'right'}
                    aria-label={'Share link'}
                    onClick={this.createSharableLink}
                    onMouseEnter={(event) => this.showTooltip(event)}
                >
                    <span>
                    <IconGeneralShare width={28} height={28}/>
                    </span>
                </button>
              </nav>
              <ColorSchemeSwitcher/>
            </div>

            <Settings
              environments={this.props.environments}
              collections={this.props.collections}
              appState={this.props.appState}
              createAndSelectEnvironment={this.props.createAndSelectEnvironment}
              importEnvironmentsActions={this.props.importEnvironmentsActions}
              updateEnvironment={this.props.updateEnvironment}
              handleDeleteEnvironment={this.handleDeleteEnvironment}
              discoverMetaData={this.props.discoverMetaData}
              discoverCredentialMetaData={this.props.discoverCredentialMetaData}
              discoverWebFinger={this.props.discoverWebFinger}
              jwksRequest={this.props.jwksRequest}
              showSettings={this.props.showSettings}
              editing={this.props.editing}
              setEditing={this.setEditing}
              isImportModalVisible={this.state.showImportModal}
              handleClose={this.hideSettings}
              showImportExportModal={this.showImportExportModal}
            />

            <ImportAppStateModal
              appState={this.props.appState}
              collections={this.props.collections}
              environments={this.props.environments}
              selectProviderForCollection={
                this.props.selectProviderForCollection
              }
              changeSelectedEnvironmentWrapper={
                this.props.changeSelectedEnvironmentWrapper
              }
              importCollectionsAction={this.props.importCollectionsAction}
              importEnvironmentsActions={this.props.importEnvironmentsActions}
              importEnvironmentsClientsActions={
                this.props.importEnvironmentsClientsActions
              }
              importType={this.state.importType}
              modalSource={this.state.sharedPermalinkModalConfig.modalSource}
              showImportModal={this.state.showImportModal}
              showToastNotification={this.showToastNotification}
              shareConfigPermalinkModal={this.state.sharedPermalinkModalConfig}
              importShareableConfig={this.props.importShareableConfig}
              updateStateAndShowShareableLinkModal={
                this.updateStateAndShowShareableLinkModal
              }
              createNewEnvironment={this.props.createNewEnvironment}
              selectEnvironment={this.props.selectEnvironment}
              updateEnvironmentWrapper={this.props.updateEnvironmentWrapper}
              importGroupsActions={this.props.importGroupsActions}
              handleClose={this.hideImportExport}
            />

            <CreateWorkspaceModal
                show={this.state.showNewWorkspaceModal}
                createAndSelectEnvironment={this.props.createAndSelectEnvironment}
                createAndSelectSignicatEnvironment={this.props.createAndSelectSignicatEnvironment}
                discoverMetaData={this.props.discoverMetaData}
                showSettings={this.showSettings}
                hideCreateWorkspaceModal={()=> this.setState({ showNewWorkspaceModal: false })}
            />

            <Toast
              status={this.state.toastNotification.toastStatus}
              message={this.state.toastNotification.toastMessage}
              shown={this.state.toastNotification.toastShown}
            />

            <CurityNotifications/>

            {this.state.sharedPermalinkModalConfig.showSharedConfigModal ? (
              <ShareConfigPermalinkModal
                shareConfigPermalinkModal={
                  this.state.sharedPermalinkModalConfig
                }
                hideSharedConfigModalError={this.hideSharedConfigModalError}
                importType={this.state.importType}
                handleClose={this.hideSharedConfigModal}
              />
            ) : null}
          </ErrorHandling>
        );
    }
}

export default ActivityBar;
