/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Acrs from './Acrs';
import ForceAuthOption from './ForceAuthOption';
import Freshness from './Freshness';
import Locale from './Locale';
import Nonce from './Nonce';
import ResponseTypes from './ResponseTypes';
import LoginHint from './LoginHint';

const OpenIdConnectSettings = (props) => {
    return (
        <details className="mt2" open={true} id="openidsettings">
            <summary aria-controls="openidsettings">
                OpenID Settings
            </summary>

            {props.hideResponseTypes ? '' :
                <div className="sm-flex flex-center flex-gap-2 flex-wra pmt2">
                    <div className="flex-auto">
                        <ResponseTypes
                            collection={props.collection}
                            environment={props.environment}
                            updateParameters={props.updateParameters}
                        />
                    </div>
                </div>
            }

            <div className="sm-flex flex-center flex-gap-2 flex-wrap mt2">
                <div className="flex-auto">
                    <Acrs
                        updateParameters={props.updateParameters}
                        environment={props.environment}
                        collection={props.collection}
                    />
                </div>
            </div>
            <div className="sm-flex  flex-center flex-gap-2  flex-wrap mt2">
                <div className="flex-auto">
                    <Locale
                        collection={props.collection}
                        environment={props.environment}
                        updateParameters={props.updateParameters}
                    />

                </div>
                <div className="flex-auto">
                    <Nonce
                        collection={props.collection}
                        environment={props.environment}
                        updateParameters={props.updateParameters}
                    />
                </div>

            </div>
            <div className="sm-flex  flex-center flex-gap-2  flex-wrap mt2">
                <div className="flex-auto">
                    <ForceAuthOption
                        collection={props.collection}
                        updateParameters={props.updateParameters}
                    />
                </div>
                <div className="flex-auto">
                    <Freshness
                        collection={props.collection}
                        updateParameters={props.updateParameters}
                    />
                </div>
            </div>

            <div className="sm-flex  flex-center flex-gap-2  flex-wrap mt2">
                <div className="flex-auto">
                    <LoginHint
                        collection={props.collection}
                        updateParameters={props.updateParameters}
                    />
                </div>
            </div>

        </details>
    );
};

export default OpenIdConnectSettings;
