/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { flows, PLAYGROUND } from '../../util/appConstants';
import { IconGeneralDownload } from '@curity-internal/ui-icons-react';

const MiddlePaneHeader = (props) => {

    const isSignicatPlayground = props.workspace && props.workspace.playground === PLAYGROUND.SIGNICAT;
    const justify = isSignicatPlayground ? ' tools-form-header-justify' : '';

    const handleClick = (e) => {
        e.preventDefault();
        e.currentTarget.blur();
        switch (props.collection.flow) {
            case flows.client_credentials.id:
                window.open('https://developer.signicat.com/docs/accessing-api-products.html?utm_source=oauth.tools_signicat&utm_medium=referral&utm_campaign=curity_button_signicat_documentation&utm_content=header_button', '_blank');
                break
            default:
                window.open('https://developer.signicat.com/docs/eid-hub/oidc/?utm_source=oauth.tools_signicat&utm_medium=referral&utm_campaign=curity_button_signicat_documentation&utm_content=header_button', '_blank');
        }
    }

    return (<header className={'tools-form-header' + justify}>
        {isSignicatPlayground &&
            <button className={'button button-tiny button-signicat'}
                    onClick={handleClick}>Signicat Documentation</button>}
        <button
            className={'button button-tiny button-light'}
            onClick={() => {
                props.exportCurrentCollection(props.collection);
            }}
        >
            <IconGeneralDownload width={18} height={18} />
            Export
        </button>
    </header>)
};

export default MiddlePaneHeader;
