/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { IconGeneralUploadConfig } from '@curity-internal/ui-icons-react';

class FileDropzone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            dropZoneAreaCounter: 0
        }
    }

    handleFileDragEnter = (e) => {
        e.preventDefault();
        this.setState({
            dropZoneAreaCounter: this.state.dropZoneAreaCounter + 1
        });
    };

    handleFileDragLeave = (e) => {
        e.preventDefault();
        this.setState({
            dropZoneAreaCounter: this.state.dropZoneAreaCounter - 1
        });
    };

    handleFileDragOver = (e) => {
        e.preventDefault();
    };

    handleFileDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let files = e.dataTransfer.files;
        if (files && files.length > 0) {
            this.setState({ files: this.props.multiple ? files : [files[0]] }, () => {
                this.props.onChange(this.state.files);
            });
        }
        this.setState({ dropZoneAreaCounter: 0 });
    };

    handleFileSelect = (e) => {
        this.props.handleFileSelect(e);
    };

    clearFileInput = () => {
        if (this.fileInput) {
            this.fileInput.value = null
        }
    };

    render() {
        const { clearFileInput } = this.props;

        if (clearFileInput){
            this.clearFileInput();
        }

        return (
            <div
                className={'mt1 dropzone ' + (this.state.dropZoneAreaCounter > 0 ? 'drag-active' : '')}
                onDragEnter={this.handleFileDragEnter}
                onDragLeave={this.handleFileDragLeave}
                onDrop={this.handleFileDrop}
                onDragOver={this.handleFileDragOver}>
                <IconGeneralUploadConfig width={96} height={96} color="#ccc" />
                Drag and drop {this.props.multiple ? 'files' : 'file'} here
                <span className="mt2 mb2 block uppercase">OR</span>

                <label className="file-label" htmlFor="uploadAppStateFile">
                    <input id="uploadAppStateFile"
                           type="file"
                           accept="application/json"
                           className="mb2"
                           aria-label="Select file"
                           onChange={this.handleFileSelect}
                           ref={ref => this.fileInput = ref}/>
                </label>
                <label>
                    <small>{this.props.fileRef}</small>
                </label>
            </div>
        )
    }
}

FileDropzone.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default FileDropzone;
