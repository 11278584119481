/*
 * Copyright 2024 Curity AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useEffect, useState } from 'react';
import Environments from '../../data/Environments';
import ResizablePanels from '../ResizablePanels';
import MiddlePaneHeader from './MiddlePaneHeader';
import FlowHeader from './FlowHeader';
import TokenInput from '../token/TokenInput';
import TokenValidationSignature from '../validation/TokenValidationSignature';
import { isEmpty } from '../../util/validationUtils';
import EmptySidebar from '../EmptySidebar';
import Guide from '../guides/Guide';
import { flows, guides } from '../../util/appConstants';
import JwtTokenValidation from '../validation/JwtTokenValidation';
import jose from 'node-jose';

const DecodeTokenPane = (props) => {
    const [guideVisible, setGuideVisible] = useState(false)
    const currentCollection = props.collection;
    const tokenIds = currentCollection.getTokenIds();
    // In this mode we assume there is only one token
    const tokenId = tokenIds[0];
    const selectedToken = currentCollection.getTokenById(tokenId);
    const workspaces = Environments.create(props.environments);

    const currentWorkspace = workspaces.getEnvironment(currentCollection.provider);

    const toggleGuideVisible = (isVisible) => {
        setGuideVisible(isVisible)
    }

    const parseKey = async (key) => {

        if (Object.prototype.hasOwnProperty.call(key, 'jwk')) {
            try {
                const joseJwk = await jose.JWK.asKey(key.jwk);
                key.pem = joseJwk.toPEM();
            } catch (e) {
                // Could not parse key as JWK
                console.warn(e)
            }
        } else {
            try {
                let keyStore = await jose.JWK.createKeyStore();
                const parsedKey = await keyStore.add(key.pem, 'pem');
                key.jwk = parsedKey.toJSON();
            } catch (e) {
                if (!isEmpty(key.pem)) {
                    // Could not parse key as JWK
                    console.warn(e);
                }
            }
        }
        return key;
    }

    const updateKey = async (key) => {
        const parsedKey = await parseKey(key)
        const updatedParameters = props.collection.parameters
            .withUpdatedValue('jwt_flow_key', { validation_key: parsedKey });
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const tokenInput = props.collection.parameters.jwt_flow_token_input;
    const tokenPurpose = props.collection.parameters.jwt_flow_token_purpose;
    const keys = props.collection.parameters.jwt_flow_key;


    useEffect(() => {
        props.validateInputForTokenFlow(currentCollection)
    },[tokenInput, tokenPurpose, keys.validation_key])

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <div>
                    <section className="tools-form jwt-flow">
                        <MiddlePaneHeader
                            collection={props.collection}
                            exportCurrentCollection={props.exportCurrentCollection}/>
                        <div className="tools-form-content">
                            <FlowHeader name={currentCollection.name}
                                        description={'Paste a JWT or an SD-JWT and decode its header, payload, disclosed claims and signature.'}/>

                            <TokenInput
                                collection={currentCollection}
                                updateParameters={props.updateParameters}
                            />


                            <TokenValidationSignature
                                label="Signature"
                                token={keys}
                                collection={currentCollection}
                                environment={currentWorkspace}
                                hideHeader={true}
                                showGenerateButton={false}
                                hideEnvironment={false}
                                updateKeyForToken={updateKey}
                            />

                        </div>
                    </section>

                </div>
                <div>
                    {isEmpty(selectedToken.value) ? <EmptySidebar text="Paste a token to decode"/> :
                        <aside className="tools-sidebar">

                            <Guide area={guides.jwt} toggle={toggleGuideVisible}/>

                            {!guideVisible &&
                                <JwtTokenValidation token={selectedToken} collection={currentCollection}
                                                    flow={flows.none}
                                                    environment={currentWorkspace}/>}
                        </aside>
                    }
                </div>
            </ResizablePanels>
        </React.Fragment>
    )
}

export default DecodeTokenPane
