/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { ENDPOINT_TYPES } from '../../data/Endpoints';
import NoEnvironmentSelected from '../NoEnvironmentSelected';
import ClientSettings from './ClientSettings';
import EnvironmentCreatable from './EnvironmentCreatable';
import PrettyJson from '../validation/PrettyJson';
import CopyToClipboard from '../CopyToClipboard';
import { logModalView } from '../../util/analytics';
import WebFinger from './WebFinger';
import TrickAutoCompleteForms from '../TrickAutoCompleteForms';
import { exportAppState } from '../../util/util';
import { IMPORT_CONFIG_MODAL_SOURCE, IMPORT_TYPE } from '../../util/appConstants';
import { Tabs } from '../Tabs/Tabs';
import { Tab } from '../Tabs/Tab';
import AceEditor from 'react-ace';
import { isEmpty } from '../../util/validationUtils';
import { IconGeneralClose, IconGeneralDownload, IconGeneralEmpty, IconGeneralPlus, IconGeneralShare } from '@curity-internal/ui-icons-react';

class TabBody extends React.Component {
    BUTTON_LOADING_ACTIVE = 'button-loading-active';
    BUTTON_DISABLED = 'button-disabled';

    constructor(props) {
        super(props);
        this._name = 'DISCOVERY';
        this.state = {
            selectedSection: 'endpoints',
            webFingerInputVisible: false,
            showJwk: false
        };
        this.discoverButtonRef = React.createRef();
        this.discoverCredentialButtonRef = React.createRef();
    }

    hideError = () => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withError(null, null);
        this.props.updateEnvironment(updatedEnvironment);
    };

    handleSection = (section) => {
        this.setState({
            selectedSection: section
        }, () => document.querySelector('.modal-environments').scrollTop = 0);

        logModalView('/environments/' + this.props.environment.id + '/' + section);
    };

    togglePemJwt = () => {
        this.setState({ showJwk: !this.state.showJwk })
    };

    discover = (e) => {
        let discoverButton = e.currentTarget;

        discoverButton.classList.add(this.BUTTON_LOADING_ACTIVE, this.BUTTON_DISABLED);
        discoverButton.blur();

        try {
            this.props.discoverMetaData(this.props.environment, this._name, false);
        } catch (err) {
            console.log(err);
        }
    };

    discoverCredential = (e) => {
        let discoverCredentialButton = e.currentTarget;

        discoverCredentialButton.classList.add(this.BUTTON_LOADING_ACTIVE, this.BUTTON_DISABLED);
        discoverCredentialButton.blur();
        try {
            this.props.discoverCredentialMetaData(this.props.environment, this._name);
        } catch (err) {
            console.log(err);
        }
    }

    updateWorkspaceParam = (e) => {
        const updatedEnvironment = this.props.environment.withUpdatedValue(e.currentTarget.name, e.currentTarget.value);
        this.props.updateEnvironment(updatedEnvironment);
    }

    updateEndpoint = (event) => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withUpdatedEndpoint(event.currentTarget.name,
            event.currentTarget.value);

        this.props.updateEnvironment(updatedEnvironment);
    };

    addClient = () => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withNewClient();

        this.props.updateEnvironment(updatedEnvironment);

        setTimeout(() => {
            this.scrollAndFocusLastClient();
        }, 100)

    };

    exportCurrentEnvironment = () => {
        const currentEnvironment = this.props.environment;
        const fileName = (currentEnvironment.name ? currentEnvironment.name.replace(/\s/g, '-') : currentEnvironment.id) + '.json';
        const fileType = 'application/json';

        // !Imp: As Individual export will not have `environment` as `key` thus added  manually;
        const dataToExport = JSON.stringify({
            'environments': {
                [currentEnvironment.id]: currentEnvironment.toMap()
            }
        });
        exportAppState(dataToExport, fileName, fileType, []);
    };

    scrollAndFocusLastClient = () => {
        const addedClients = document.querySelectorAll('[aria-labelledby="client-app-tab"] .modal-panel-section:nth-child(2) tbody tr');
        const lastAddedClient = addedClients[addedClients.length - 2];
        lastAddedClient.querySelector('td:first-child input').focus()
    };

    componentDidUpdate() {

        if (this.props.environment) {

            if (this.discoverButtonRef && this.discoverButtonRef.current) {
                this.discoverButtonRef.current.classList.remove(this.BUTTON_LOADING_ACTIVE, this.BUTTON_DISABLED);
            }
            if (this.discoverCredentialButtonRef) {
                this.discoverCredentialButtonRef.current.classList
                    .remove(this.BUTTON_LOADING_ACTIVE, this.BUTTON_DISABLED);
            }
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.discoverButtonRef) {
                this.discoverButtonRef.current.click();
            }

            if (this.discoverCredentialButtonRef) {
                this.discoverCredentialButtonRef.current.click();
            }
        }
    };

    importClient = () => {
        this.props.showImportExportModal(IMPORT_TYPE.IMPORT_CLIENTS, IMPORT_CONFIG_MODAL_SOURCE.BUTTON);
    };

    showWebFingerInput = () => {
        this.setState({ webFingerInputVisible: true })
    }
    hideWebFingerInput = () => {
        this.setState({ webFingerInputVisible: false })
    }

    renderClientButtons = () => {

        return <React.Fragment>
            <button
                className="button button-tiny button-success-outline mr2"
                data-qa="add-new-client"
                onClick={this.addClient}
            >
                <IconGeneralPlus width={18} height={18} />
                New client
            </button>

            <button
                className="button button-tiny button-primary-outline"
                onClick={this.importClient}
            ><IconGeneralDownload width={18} height={18} />

                Import clients
            </button>
        </React.Fragment>
    };

    render() {
        const environment = this.props.environment;

        let error;
        if (environment) {
            error = environment.error && Object.prototype.hasOwnProperty.call(environment.error, this._name) ?
                <div className="alert alert-danger mt2">

                    {environment.error[this._name]}
                    <button className="alert-close" onClick={this.hideError}><IconGeneralClose width={18} height={18} /></button>
                </div> : '';
        }

        let infoMessage = '';

        if (environment && !environment.raw_metadata) {
            infoMessage =
                <div className="alert alert-info">Enter an issuer or a metadata url and press discover. You can also add the information manually</div>;
        }


        const clients = this.props.environment && this.props.environment.clients ? this.props.environment.clients : {};
        const keys = this.props.environment && this.props.environment.jwks ? this.props.environment.jwks : {};
        const raw_metadata = this.props.environment && this.props.environment.raw_metadata ?
            this.props.environment.raw_metadata : null;
        const haveClients = Object.keys(clients).length > 0;

        return (
            environment == null ? <NoEnvironmentSelected/> :
                <div className="tab-content">
                    <div aria-labelledby="client-app-tab">

                        <div className="md-flex justify-between modal-panel flex-column">
                            {!this.state.webFingerInputVisible && <aside className="p2">
                                <nav>
                                    <ul className="m0 list-reset flex">
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('endpoints')
                                        }} className={(this.state.selectedSection === 'endpoints' ? 'active' : '')}
                                               href="#">Endpoints</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('clients')
                                        }} className={(this.state.selectedSection === 'clients' ? 'active' : '')}
                                               href="#"
                                               data-qa="enviroment-clients">Clients</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('scopes')
                                        }} className={(this.state.selectedSection === 'scopes' ? 'active' : '')}
                                               href="#">Scopes/Claims</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('keys')
                                        }} className={(this.state.selectedSection === 'keys' ? 'active' : '')}
                                               href="#">Keys</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('authentication')
                                        }} className={(this.state.selectedSection === 'authentication' ? 'active' : '')}
                                               href="#">Authentication</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('metadata')
                                        }} className={(this.state.selectedSection === 'metadata' ? 'active' : '')}
                                               href="#">Metadata</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('responsetypes')
                                        }} className={(this.state.selectedSection === 'responsetypes' ? 'active' : '')}
                                               href="#">Response Types</a></li>
                                        <li><a onClick={(e) => {
                                            e.preventDefault(); // avoid "#" on URL
                                            this.handleSection('verifiablecredentials')
                                        }}
                                               className={(this.state.selectedSection === 'verifiablecredentials' ? 'active' : '')}
                                               href="#">Verifiable Credentials</a></li>

                                        <li className="modal-panel-actions">
                                            <button
                                                className="button button-tiny button-light"
                                                onClick={this.exportCurrentEnvironment}
                                            >
                                                <IconGeneralShare width={18} height={18} />
                                                Export Workspace

                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </aside>}

                            <main role="main" className="px2 lg-px3">

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'endpoints' ? '' : 'visually-hidden')}>

                                    {!this.state.webFingerInputVisible && <h3 className="mt0">Endpoints</h3>}

                                    {!this.state.webFingerInputVisible && infoMessage}

                                    {this.state.webFingerInputVisible &&
                                        <WebFinger environment={this.props.environment}
                                                   close={this.hideWebFingerInput}
                                                   updateEnvironment={this.props.updateEnvironment}
                                                   discoverWebFinger={this.props.discoverWebFinger}
                                        />}
                                    {!this.state.webFingerInputVisible && !this.props.environment.issuer &&
                                        <div className="mt2 mb2">
                                            <button className="button button-small button-primary-outline"
                                                    onClick={this.showWebFingerInput}>Use webfinger
                                            </button>
                                        </div>}

                                    {!this.state.webFingerInputVisible && <>
                                        <div className="mb4">
                                            <label htmlFor={'tab_body_issuer'} className="label">
                                                Issuer
                                            </label>
                                            <div className="sm-flex flex-justify flex-center">
                                                <div className="flex-auto">
                                                    <input
                                                        id="tab_body_issuer"
                                                        type="text"
                                                        name="issuer"
                                                        className="field col-12"
                                                        autoFocus
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                        spellCheck="false"
                                                        value={(environment.issuer) ? environment.issuer : ''}
                                                        onChange={this.updateWorkspaceParam}
                                                        onKeyPress={this.handleKeyPress}
                                                    />
                                                </div>
                                            </div>

                                            <label htmlFor="tab_body_metadata_url" className="label block mt1">
                                                Metadata URL
                                            </label>

                                            <div className="sm-flex flex-justify flex-center">
                                                <div className="flex-auto">
                                                    <input
                                                        id="tab_body_metadata_url"
                                                        type="url"
                                                        data-lpignore="true"
                                                        spellCheck="false"
                                                        name="metadata_url"
                                                        className="field col-12"
                                                        value={(environment.metadata_url) ? environment.metadata_url : ''}
                                                        onChange={this.updateWorkspaceParam}
                                                        onKeyPress={this.handleKeyPress}
                                                        data-qa="metadata-url"
                                                    />
                                                </div>

                                                <div className="md-pl1 mt1 md-mt0 ml0 md-ml1">
                                                    <button ref={this.discoverButtonRef}
                                                            className="button button-small button-primary-outline button-loading button-input"
                                                            disabled={!environment.issuer && !environment.metadata_url}
                                                            data-qa="discover-button"
                                                            onClick={this.discover}>
                                                        <span>{environment.raw_metadata ? 'Refresh' : 'Discover'}</span>
                                                    </button>
                                                </div>

                                            </div>
                                            {error}
                                        </div>

                                        {Object.keys(ENDPOINT_TYPES).map((key) =>
                                            <React.Fragment key={key}>
                                                <label
                                                    htmlFor={`tab_body_` + key}
                                                    className="label block mt1">
                                                    {ENDPOINT_TYPES[key]}
                                                </label>
                                                <input type="url"
                                                       id={`tab_body_` + key}
                                                       name={key}
                                                       autoComplete="off"
                                                       data-lpignore="true"
                                                       spellCheck="false"
                                                       value={(environment.endpoints[key]) ? environment.endpoints[key] : ''}
                                                       onChange={this.updateEndpoint}
                                                       className="field col-12"
                                                />
                                            </React.Fragment>
                                        )}
                                    </>}

                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'clients' ? '' : 'visually-hidden')}>

                                    {haveClients && <>
                                        <div className="sm-flex justify-between">
                                            <h3 className="m0">Clients</h3>
                                            <div>{this.renderClientButtons()}</div>
                                        </div>
                                        <form autoComplete="off">

                                            <TrickAutoCompleteForms/>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Client ID/Name</th>
                                                    <th>Secret</th>
                                                    <th>Code</th>
                                                    <th>Implicit</th>
                                                    <th>Hybrid</th>
                                                    <th>Client Credentials</th>
                                                    <th>Token Exchange</th>
                                                    <th>ROPC</th>
                                                    <th>Introspect</th>
                                                    <th>Device</th>
                                                    <th>CIBA</th>
                                                    {!IS_ELECTRON_BUILD && <th>Can be framed</th>}
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {Object.keys(clients).map((key) =>
                                                    <ClientSettings key={key}
                                                                    client={clients[key]}
                                                                    clientId={key}
                                                                    environment={environment}
                                                                    updateEnvironment={this.props.updateEnvironment}
                                                    />
                                                )}

                                                </tbody>
                                            </table>
                                        </form>
                                    </>
                                    }

                                    {!haveClients && <>
                                        <div>
                                            <div className="flex flex-center flex-column justify-center p2 lg-p3">
                                                <IconGeneralEmpty width={256} height={256} color="#ccc"/>
                                                <p className="mt0">Looks like you don’t have any clients. Fortunately, it&apos;s very easy to create one.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-center justify-center">
                                            {this.renderClientButtons()}
                                        </div>

                                    </>}

                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'scopes' ? '' : 'visually-hidden')}>
                                    <h3 className="m0">Scopes and Claims Supported</h3>
                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="scopes"
                                                          placeholder="Add a scope"
                                                          label="Scopes Supported"
                                    />

                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="claims_supported"
                                                          placeholder="Add a claim"
                                                          label="Claims Supported"
                                    />
                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'keys' ? '' : 'visually-hidden')}>
                                    <div className="tools-sidebar-box-content-area flex flex-justify flex-column">
                                        <div
                                            className="tools-sidebar-box-content-area-header flex flex-center justify-between">
                                            <h3 className="m0">Keys</h3>
                                            <div className="">
                                                <label className="mr1 label-key label-key-switch"
                                                       htmlFor="environment_key_type">PEM</label>
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="environment_key_type"
                                                           name="key_type"
                                                           onChange={this.togglePemJwt}
                                                    />
                                                    <div className="toggle-slider toggle-slider-switch round"/>
                                                </label>
                                                <label className="ml1 label-key label-key-switch"
                                                       htmlFor="environment_key_type">JWK</label>
                                            </div>
                                        </div>

                                        {Object.keys(keys).map((key) =>
                                            <React.Fragment key={key}>

                                                <div className="flex flex-justify flex-column mt2">
                                                    {(this.state.showJwk) ?
                                                        <div className="flex-auto">
                                                            <div className="p2">
                                                                <label
                                                                    className="label label-key">kid: <span
                                                                    className="kid">{key}</span><CopyToClipboard
                                                                    flow="right" text={keys[key].jwk}/></label>
                                                            </div>
                                                            <pre
                                                                className="tools-input tools-input-white-bg tools-input-signature">
                                                        <PrettyJson json={keys[key].jwk}/>
                                                    </pre>
                                                        </div>
                                                        :
                                                        <div className="flex-auto">
                                                            <div className="p2">
                                                                <label
                                                                    className="label label-key">kid: <span
                                                                    className="kid">{key}</span><CopyToClipboard
                                                                    flow="right" text={keys[key].pem}/></label>
                                                            </div>
                                                            <pre spellCheck="false"
                                                                 className="tools-input tools-input-signature"
                                                            >{keys[key].pem}</pre>
                                                        </div>
                                                    }
                                                </div>

                                            </React.Fragment>
                                        )}
                                    </div>

                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'authentication' ? '' : 'visually-hidden')}>
                                    <h3 className="mt0">Authentication</h3>
                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="acrs"
                                                          placeholder="Add an Authentication Context Reference (ACR)"
                                                          label="Authentication Context Reference"
                                    />

                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="token_endpoint_auth_methods_supported"
                                                          placeholder="Add supported authentication methods for the token endpoint"
                                                          label="Token Endpoint Authentication Methods Supported"
                                    />

                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="introspection_endpoint_auth_methods_supported"
                                                          placeholder="Add supported authentication methods for the introspection endpoint"
                                                          label="Introspection Endpoint Authentication Methods Supported"
                                    />

                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="revocation_endpoint_auth_methods_supported"
                                                          placeholder="Add supported authentication methods for the revocation endpoint"
                                                          label="Revocation Endpoint Authentication Methods Supported"
                                    />

                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="device_authorization_endpoint_auth_methods_supported"
                                                          placeholder="Add supported authentication methods for the device authorization endpoint"
                                                          label="Device Authorization Endpoint Authentication Methods Supported"
                                    />

                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'metadata' ? '' : 'visually-hidden')}>
                                    <h3 className="mt0">Metadata <CopyToClipboard text={raw_metadata}/></h3>
                                    <pre className="tools-input tools-input-white-bg tools-input-signature">
                                    <PrettyJson json={raw_metadata}/>
                                </pre>
                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'responsetypes' ? '' : 'visually-hidden')}>
                                    <h3 className="mt0">Response Types</h3>
                                    <EnvironmentCreatable environment={environment}
                                                          updateEnvironment={this.props.updateEnvironment}
                                                          field="response_types_supported"
                                                          placeholder="Add supported response types"
                                                          label="Supported response types"
                                    />
                                </section>

                                <section
                                    className={'modal-panel-section ' + (this.state.selectedSection === 'verifiablecredentials' ? '' : 'visually-hidden')}>

                                    <h3 className="mt0">Verifiable Credentials</h3>


                                    <label htmlFor="tab_body_credential_issuer" className="label">
                                        Credential Issuer
                                    </label>
                                    <div className="sm-flex flex-justify flex-center">
                                        <div className="flex-auto">
                                            <input
                                                id="tab_body_credential_issuer"
                                                type="text"
                                                name="credential_issuer"
                                                className="field col-12"
                                                autoFocus
                                                autoComplete="off"
                                                data-lpignore="true"
                                                spellCheck="false"
                                                value={(environment.credential_issuer) ? environment.credential_issuer : ''}
                                                onChange={this.updateWorkspaceParam}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>

                                    <label htmlFor="tab_body_credential_metadata_url" className="label block mt1">
                                        Credential Metadata URL
                                    </label>

                                    <div className="sm-flex flex-justify flex-center">
                                        <div className="flex-auto">
                                            <input
                                                id="tab_body_credential_metadata_url"
                                                type="url"
                                                data-lpignore="true"
                                                spellCheck="false"
                                                name="credential_metadata_url"
                                                className="field col-12"
                                                value={(environment.credential_metadata_url) ? environment.credential_metadata_url : ''}
                                                onChange={this.updateWorkspaceParam}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>

                                        <div className="md-pl1 mt1 md-mt0 ml0 md-ml1">
                                            <button ref={this.discoverCredentialButtonRef}
                                                    className="button button-small button-primary-outline button-loading button-input"
                                                    disabled={!environment.credential_issuer
                                                        && !environment.credential_metadata_url}
                                                    onClick={this.discoverCredential}>
                                                <span>{environment.raw_credential_metadata ? 'Refresh' : 'Discover'}</span>
                                            </button>
                                        </div>

                                    </div>
                                    {error}

                                    <label
                                        htmlFor="tab_body_credential_endpoint"
                                        className="label block mt1">
                                        Credential Endpoint
                                    </label>
                                    <input
                                        id="tab_body_credential_endpoint"
                                        type="url"
                                        name="credential_endpoint"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        spellCheck="false"
                                        value={environment.credential_endpoint}
                                        onChange={this.updateWorkspaceParam}
                                        className="field col-12"
                                    />

                                    <label
                                        htmlFor="tab_body_batch_credential_endpoint"
                                        className="label block mt1">
                                        Batch Credential Endpoint
                                    </label>
                                    <input
                                        id="tab_body_batch_credential_endpoint"
                                        type="url"
                                        name="batch_credential_endpoint"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        spellCheck="false"
                                        value={environment.batch_credential_endpoint}
                                        onChange={this.updateWorkspaceParam}
                                        className="field col-12"
                                    />

                                    <label
                                        htmlFor="tab_body_notification_endpoint"
                                        className="label block mt1">
                                        Notification Endpoint
                                    </label>
                                    <input
                                        id="tab_body_notification_endpoint"
                                        type="url"
                                        name="notification_endpoint"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        spellCheck="false"
                                        value={environment.notification_endpoint}
                                        onChange={this.updateWorkspaceParam}
                                        className="field col-12"
                                    />

                                    <label
                                        htmlFor="tab_body_deferred_credential_endpoint"
                                        className="label block mt1">
                                        Deferred Credential Endpoint
                                    </label>
                                    <input
                                        id="tab_body_deferred_credential_endpoint"
                                        type="url"
                                        name="deferred_credential_endpoint"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        spellCheck="false"
                                        value={environment.deferred_credential_endpoint}
                                        onChange={this.updateWorkspaceParam}
                                        className="field col-12"
                                    />


                                    <Tabs noSelection={true}>
                                        <Tab label="Display">
                                            {Object.keys(environment.display).length > 0 &&
                                                <div className="ace-editor-wrapper px2 py2">
                                                    <AceEditor
                                                        placeholder="Paste JSON"
                                                        mode="json"
                                                        name="raw_credential_metadata"
                                                        theme="monokai"
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            showGutter: true,
                                                            tabSize: 2
                                                        }}
                                                        indentedSoftWrap={false}
                                                        showPrintMargin={false}
                                                        value={JSON.stringify(environment.display, null, 2)}
                                                        width="100%"
                                                        readOnly={true}
                                                        maxLines={1000}
                                                        minLines={12}
                                                        fontSize={12}
                                                        wrapEnabled={true}
                                                        editorProps={{ $blockScrolling: true }}/>
                                                </div>}
                                        </Tab>
                                        <Tab label="Credentials Supported">
                                            {!isEmpty(environment.credentials_supported) &&
                                                <div className="ace-editor-wrapper px2 py2">
                                                    <AceEditor
                                                        placeholder="Paste JSON"
                                                        mode="json"
                                                        name="raw_credential_metadata"
                                                        theme="monokai"
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            showGutter: true,
                                                            tabSize: 2
                                                        }}
                                                        indentedSoftWrap={false}
                                                        showPrintMargin={false}
                                                        value={JSON.stringify(
                                                            environment.credentials_supported, null, 2)}
                                                        width="100%"
                                                        readOnly={true}
                                                        maxLines={1000}
                                                        minLines={12}
                                                        fontSize={12}
                                                        wrapEnabled={true}
                                                        editorProps={{ $blockScrolling: true }}
                                                    />
                                                </div>}
                                        </Tab>
                                        <Tab label="Raw Credential Metadata">
                                            {Object.keys(environment.raw_credential_metadata).length > 0 &&
                                                <div className="ace-editor-wrapper px2 py2">
                                                    <AceEditor
                                                        placeholder="Paste JSON"
                                                        mode="json"
                                                        name="raw_credential_metadata"
                                                        theme="monokai"
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            showGutter: true,
                                                            tabSize: 2
                                                        }}
                                                        indentedSoftWrap={false}
                                                        showPrintMargin={false}
                                                        value={JSON.stringify(
                                                            environment.raw_credential_metadata, null, 2)}
                                                        width="100%"
                                                        readOnly={true}
                                                        maxLines={1000}
                                                        minLines={12}
                                                        fontSize={12}
                                                        wrapEnabled={true}
                                                        editorProps={{ $blockScrolling: true }}
                                                    />
                                                </div>
                                            }
                                        </Tab>
                                    </Tabs>
                                </section>
                            </main>
                        </div>

                    </div>
                </div>
        )
    }
}

export default TabBody;
