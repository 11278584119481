class CurityToast {
    constructor() {
        this.t = null
        this.notification = null
        if (typeof window !== 'undefined') {
            this.duration = window.getComputedStyle(document.documentElement).getPropertyValue('--curityToastDuration')
        }
    }

    clear() {
        clearTimeout(this.t)
        for (const existingToast of document.querySelectorAll('.curityToast')) {
            existingToast.remove()
        }
    }

    success(options) {
        this.create('success', options)
    }

    warning(options) {
        this.create('warning', options)
    }

    danger(options) {
        this.create('danger', options)
    }

    info(options) {
        this.create('info', options)
    }

    handleAction(e) {
        e.preventDefault()
        const button = document.body.querySelector('.curityToast button')
        button.removeEventListener('click', this.handleAction)
        this.notification.classList.remove('curityToastPopout')
        this.notification.classList.add('curityToastHidden')
        setTimeout(() => {
            this.notification.remove()
        }, 200)
    }

    create(type, options) {
        this.clear()

        const { duration, dismiss, callback, closeOnCallback, title, message, closeCallback } = options

        if (duration) {
            this.duration = duration
            document.documentElement.style.setProperty('--curityToastDuration', duration)
        }

        this.notification = document.createElement('div')
        this.notification.id = 'curityToast'
        this.notification.classList.add(
            'curityToast',
            `curityToast-${type}`,
            `curityToast-${dismiss ? 'dismiss' : 'notify'}`
        )

        this.callback = callback || function () {
        }
        this.closeCallback = closeCallback || function () {
        }

        const div = document.createElement('div')
        const heading = document.createElement('h3')
        heading.innerHTML = title;

        const text = document.createElement('p')
        text.innerHTML = message || 'Hi there!'
        div.append(heading)
        div.append(text)

        this.notification.append(div)
        this.notification.classList.add('curityToastPopout')

        if (type === 'success') {
            const icon = document.createElement('span')
            icon.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>'
            this.notification.insertBefore(icon, this.notification.firstChild)
        }

        if (type === 'warning') {
            const icon = document.createElement('span')
            icon.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>'
            this.notification.insertBefore(icon, this.notification.firstChild)
        }

        if (type === 'danger') {
            const icon = document.createElement('span')
            icon.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>'
            this.notification.insertBefore(icon, this.notification.firstChild)
        }

        if (dismiss) {
            const button = document.createElement('button')

            button.innerHTML =
                '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
            this.notification.append(button)

            button.addEventListener('click', (e) => {
                this.handleAction(e)
                if (closeCallback) {
                    this.closeCallback.call(this.notification)
                }
            })
        }
        if (duration) {
            this.t = window.setTimeout(() => {
                this.notification.remove()
            }, duration)
        }

        document.body.append(this.notification)

        if (callback) {
            this.notification.style.cursor = 'pointer'
            this.notification.addEventListener('click', event => {
                if (event.target.id !== this.notification.id && event.target.parentNode.tagName !== 'DIV') return false
                event.preventDefault()
                this.callback.call(this.notification)
                if (closeOnCallback) {
                    this.notification.remove()
                    if (closeCallback) {
                        this.closeCallback.call(this.notification)
                    }
                }
            })
        }
    }
}

export default CurityToast
