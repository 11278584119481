/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'

class EnvironmentCreatable extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange = (selectedOption) => {
        const currentEnvironment = this.props.environment;
        let newOptions = [];
        Object.values(selectedOption).forEach((value) => {
            newOptions.push(value.value);
        });

        const updatedEnvironment = currentEnvironment.withUpdatedValue(this.props.field,
            newOptions);
        this.props.updateEnvironment(updatedEnvironment);
    };

    render() {
        const field = this.props.field;

        let options = [];
        if (this.props.environment && this.props.environment[field]) {
            const optionList = this.props.environment[field];
            options = optionList.map((value) => {
                return { value, label: value }
            })
        }

        return (
            <div className="mt2 ">
                <label className="mt2">{this.props.label}</label>
                <div className="sm-flex flex-justify flex-center">

                    <Creatable
                        isMulti
                        placeholder={this.props.placeholder}
                        components={makeAnimated()}
                        value={options}
                        options={options}
                        onChange={this.handleChange}
                        className="select-container select-container-big"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#f2f3f6',
                            primary: '#626c87'
                        }
                        })}
                    />
                </div>
            </div>
        )
    }
}

export default EnvironmentCreatable;
