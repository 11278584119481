/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import Select from 'react-select';

class AlgorithmSelector extends Component {
    constructor(props) {
        super(props);
        const symmetricAlgorithms = [
            { value: 'none', label: 'none' },
            { value: 'HS256', label: 'HS256' },
            { value: 'HS384', label: 'HS384' },
            { value: 'HS512', label: 'HS512' }
        ]
        const assymetricAlgorithms = [
            { value: 'RS256', label: 'RS256' },
            { value: 'RS384', label: 'RS384' },
            { value: 'RS512', label: 'RS512' },
            { value: 'ES256', label: 'ES256' },
            { value: 'ES384', label: 'ES384' },
            { value: 'ES512', label: 'ES512' },
            { value: 'PS256', label: 'PS256' },
            { value: 'PS384', label: 'PS384' }
        ]
        this.state = {
            algorithms: props.excludeSymmetric ? assymetricAlgorithms : symmetricAlgorithms.concat(assymetricAlgorithms)
        };
    }

    updateAlgorithm(selectedOption) {
        this.setState({ selectedAlgorithm: selectedOption.value });
        this.props.saveAlgorithm(selectedOption.value)
    }

    render() {
        return (
            <>
                <div className="flex mb2">
                    <div className="">
                        <Select
                            value={this.state.algorithms.filter(({ value }) => value === this.props.selectedAlgorithm)}
                            onChange={(e) => this.updateAlgorithm(e)}
                            options={this.state.algorithms}
                            placeholder="Select Algorithm..."
                            className="select-container"
                            classNamePrefix="react-select"
                            id="algorithmSelector"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#f2f3f6',
                                    primary: '#626c87'
                                }
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default AlgorithmSelector;
