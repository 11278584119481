import React, { useState, useEffect } from 'react';
import { IconGeneralMoon, IconGeneralSun } from '@curity-internal/ui-icons-react';

export const ColorSchemeSwitcher = () => {
  const [icon, setIcon] = useState(null)

  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem('colorScheme') || 'system'
  );
  const applyColorScheme = (colorScheme) => {
    document.documentElement.setAttribute('data-color-scheme', colorScheme);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    localStorage.setItem('colorScheme', selectedValue);

    if (selectedValue !== 'system') {
      applyColorScheme(selectedValue);
    }
  };

  useEffect(() => {
    const handleSystemColorScheme = () => {
      const systemColorScheme = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches
        ? 'dark'
        : 'light';

      if (selectedOption === 'system') {
        applyColorScheme(systemColorScheme);
        localStorage.setItem('colorScheme', systemColorScheme);
      }
    };

    // Attach event listener for system color scheme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => handleSystemColorScheme();
    mediaQuery.addEventListener('change', handleChange);

    // Initial handling
    handleSystemColorScheme();

    // Cleanup
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [selectedOption]);

  useEffect(() => {
    // Handle system color scheme on full reload
    applyColorScheme(localStorage.getItem('colorScheme') || 'system');
  }, []);

  useEffect(() => {
    setIcon(localStorage.getItem('colorScheme'))
  });

  return (
    <div className="color-scheme-switcher-holder">

      {icon === 'light' && <IconGeneralSun color="white" width={28} height={28}/>}
      {icon === 'dark' && <IconGeneralMoon color="white" width={28} height={28}/>}

      <select
        className="color-scheme-switcher"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value="light">Light</option>
        <option value="dark">Dark</option>
        <option value="system">System</option>
      </select>
    </div>
  );
};
