/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'auto' : 'initial',
        backgroundColor: state.isSelected ? '#f2f3f6' : 'initial'
    })
};

const Option = props => (
    <div>
        <components.Option {...props}>
            <input type="checkbox" checked={props.isSelected} onChange={() => null}/>{' '}
            <label>{props.label}</label>
        </components.Option>
    </div>
);

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

class ClaimUsageSelect extends Component {

    handleChange = (newValue, actionMeta) => {
        const optionsValue = newValue.map(({ value }) => value);
        this.props.onChangeCallback(optionsValue);
    };

    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.node,
                label: PropTypes.node
            })
        ).isRequired,
        onChangeCallback: PropTypes.func.isRequired
    };

    static defaultProps = {
        options: []
    };


    render() {
        // eslint-disable-next-line no-unused-vars
        const { options, value, onChangeCallback, ...otherProps } = this.props;
        const selectedValues = value ? value.map(v => {
            return { label: v, value: v }
        }) : null;
        return (
            <Creatable
                isMulti
                placeholder="Select or add usage"
                onChange={this.handleChange}
                components={{ makeAnimated, Option, MultiValue }}
                options={options}
                value={selectedValues}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                className="select-container"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                styles={customStyles}
                {...otherProps}
            />
        )
    }
}

export default ClaimUsageSelect;
