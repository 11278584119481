/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import CurityLogo from './components/icons/CurityLogo';
import About from './components/pages/About';
import Changelog from './components/pages/Changelog';
import OAuthToolsIcon from './components/icons/OAuthToolsIcon'
import { ColorSchemeSwitcher } from './components/shared/ColorSchemeSwitcher';
import { IconGeneralArrowBack, IconGeneralArrowForward } from '@curity-internal/ui-icons-react';

function PageContainer() {
    const { pathname } = useLocation();

    const handleDoubleClick = () => {
        if (window && window.nodeRequire) {
            const ipcRenderer = window.nodeRequire('electron').ipcRenderer;
            ipcRenderer.send('toggleMaximize', {})
        }
    }


        return (
            <>
                <header className="header header-about" role="banner">
                    <div className="header-stripe flex flex-center justify-between px1"
                         onDoubleClick={handleDoubleClick}>

                        <div className="header-presentation flex justify-between">
                            <Link to="/" className="header-logo white">
                                <OAuthToolsIcon/>
                                OAuth Tools
                            </Link>
                        </div>

                        <nav role="navigation">
                            <Link to="/" className="white flex flex-center flex-gap-1" aria-label="Try out OAuth Tools">
                                <IconGeneralArrowBack width={16} height={16} />
                                Try out OAuth Tools
                            </Link>
                            {!IS_ELECTRON_BUILD && (
                                <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://developer.curity.io/oauth-tools?utm_source=oauth.tools&utm_medium=Link&utm_content=header"
                                className="white flex flex-center flex-gap-1"
                                >
                                <IconGeneralArrowForward width={16} height={16} />
                                OAuth Tools for macOS, Windows and Linux
                                </a>
                            )}
                        </nav>
                        <div className="header-service-from">
                            <div className="flex flex-center flex-gap-2">

                            {IS_ELECTRON_BUILD ? (
                                <a
                                className="flex flex-center justify-center ml3"
                                href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=Header"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Visit Curity.io"
                                >
                                <small className="flex-auto">A learning tool from</small>
                                <CurityLogo
                                    title="Curity"
                                    color="white"
                                    className="header-powered-by"
                                />
                                </a>
                            ) : (
                                <>
                                    <ColorSchemeSwitcher />
                                    <a
                                    className="flex flex-center justify-center"
                                    href="https://curity.io/?utm_source=oauth.tools&amp;utm_medium=Link&amp;utm_content=Header"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Visit Curity.io"
                                    >
                                    <small className="flex-auto">A service from</small>
                                    <CurityLogo
                                        title="Curity"
                                        color="white"
                                        className="header-powered-by"
                                        />
                                    </a>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </header>

                <main className={'about'}>
                    {pathname === '/about' && <About />}
                    {pathname === '/changelog' && <Changelog />}
                </main>
            </>
        );
}

export default PageContainer;
