/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { tokenPurposes } from '../../util/appConstants';
import TokenPurposeSelector from './TokenPurposeSelector';

class TokenPurpose extends React.Component {

    handleChange = (newValue) => {
        const tokenWithNewPurpose = this.props.token.withUpdatedPurpose(newValue);
        this.props.updateToken(this.props.collectionId, tokenWithNewPurpose);
    };

    render() {

        const tokenPurpose = this.props.token ? this.props.token.purpose : 'unknown';
        const purpose = tokenPurposes[tokenPurpose];
        const label = this.props.token && this.props.token.name === 'frontend' ? 'frontend ' + purpose.label : purpose.label;

        const editable = (this.props.editable);

        if (editable) {
            return (<TokenPurposeSelector
                purpose={tokenPurpose}
                handleChange={this.handleChange}
            />)
        } else {
            return (
                <React.Fragment>
                    <span className="tools-box-header-title">
                        {this.props.labelOverride ? this.props.labelOverride : label}
                    </span>
                </React.Fragment>
            );
        }

    }
}

export default TokenPurpose;
