/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from './actionConstants';
import { base64encode, claimsArrayToRequestParameter, encodeUrlParameter, mergeDeep, randomTimeId } from '../util/util';
import Token from '../data/Token';
import Collection from '../data/Collection';
import { selectCollection } from './workspaceActions'
import { proxiedBackendCall } from '../util/serverUtil';
import { IMPORT_OPTION, oauthResponseTypes, tokenPurposes, flows } from '../util/appConstants';
import Parameters from '../data/Parameters';
import { decodeAndValidateJwt, decodeAndValidateJwtWithKeys } from '../util/jwtUtil';
import Environments from '../data/Environments';
import { updateEnvironment, addCollectionToGroup } from './environmentActions';
import { isEmpty } from '../util/validationUtils';


export const createCollection = (flow, provider, groupId) => {
    const index = randomTimeId();

    return {
        type: C.CREATE_COLLECTION,
        index,
        flow,
        provider,
        groupId
    }
};

const duplicateCollectionAction = (collectionId, newCollectionId, groupId) => {
    return {
        type: C.DUPLICATE_COLLECTION,
        newCollectionId,
        collectionId,
        groupId
    }
}

export const createDemoCollection = (id, flow) => {
    return {
        type: C.CREATE_DEMO_COLLECTION,
        id,
        flow
    }
};

export const createAndSelectDemoCollection = (id, flow) => {
    // Create collection with possible token reference
    const createDemoCollectionAction = createDemoCollection(id, flows[flow].demoFlow);
    // Select the newly created collection
    const selectCollectionAction = selectCollection(id);

    const addCollectionToDefaultGroup = addCollectionToGroup(id, 'default', null, -1)

    //redux-thunk
    return function (dispatch) {
        //These will run synchronously.
        dispatch(createDemoCollectionAction);
        dispatch(selectCollectionAction);
        dispatch(addCollectionToDefaultGroup);
    }
}

export const duplicateCollection = (collectionId, groupId, order) => {
    const newCollectionId = randomTimeId();
    const createCollectionAction = duplicateCollectionAction(collectionId, newCollectionId, groupId);
    const addCollectionToDefaultGroup = addCollectionToGroup(newCollectionId, groupId, null, order)
    const selectCollectionAction = selectCollection(newCollectionId);

    return function (dispatch) {
        //These will run synchronously.
        dispatch(createCollectionAction);
        dispatch(addCollectionToDefaultGroup);
        dispatch(selectCollectionAction);
    }
}

export const createAndSelectCollection = (flow, provider, groupId) => {
    const createCollectionAction = createCollection(flow, provider);
    const addCollectionToDefaultGroup = addCollectionToGroup(createCollectionAction.index, groupId, null, -1)
    const selectCollectionAction = selectCollection(createCollectionAction.index);

    return function (dispatch) {
        //These will run synchronously.
        dispatch(createCollectionAction);
        dispatch(addCollectionToDefaultGroup);
        dispatch(selectCollectionAction);
    }
};


export function updateCollection(collectionId, collectionData) {
    return {
        type: C.UPDATE_COLLECTION,
        collectionId,
        collectionData
    }
}


export function deleteCollection(collectionId) {
    return {
        type: C.DELETE_COLLECTION,
        collectionId
    }
}

export function setErrorOnCollection(collectionId, error) {
    return {
        type: C.SET_ERROR_ON_COLLECTION,
        collectionId,
        error
    }
}

export const setOAuthResponseOnCollection = (collectionId, response, responseName) => {
    return {
        type: C.UPDATE_OAUTH_RESPONSE,
        collectionId,
        response,
        responseName
    };
};

export const clearOAuthResponses = (collectionId) => {
    return {
        type: C.CLEAR_OAUTH_RESPONSES,
        collectionId
    };
};


export const setTokensOnCollection = (collectionId, tokens, keys, error, skipDecode) => {
    for (let i = 0; i < tokens.length; i++) {
        if (!(tokens[i] instanceof Token)) {
            console.error('setTokensOnCollection called with incorrect input of token value');
            throw 'Invalid type for setTokensOnCollection';
        }
    }

    return async (dispatch, getState) => {
        const state = getState();
        const { appState, collections, environments } = state;
        const collection = Collection.getCollectionById(collectionId, getState().collections);
        let jwksUriEndpoint;

        if (Object.keys(collections).length > 0) {
            const environment = environments[appState.activeTab];
            if (environment) {
                jwksUriEndpoint = environment.endpoints && environment.endpoints.jwks_uri ? environment.endpoints.jwks_uri : '';
            }
        }

        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
            let decodedValidatedJwt = {};
            decodedValidatedJwt.decodedToken = token.decoded_token;
            if (token.isJwt() && !skipDecode) {
                decodedValidatedJwt = await decodeAndValidateJwtWithKeys(token.value, keys, token.purpose,
                    jwksUriEndpoint, collection.flow);
                const { validateTokenData } = decodedValidatedJwt;
                if (validateTokenData && validateTokenData.updated_jwks_keys &&
                    Object.keys(validateTokenData.updated_jwks_keys).length > 0) {
                    const { updated_jwks_keys } = validateTokenData;
                    const envs = Environments.create(environments);
                    const currEnv = envs.getEnvironment(appState.activeTab);
                    const combinedJwks = {
                        ...currEnv.jwks,
                        ...updated_jwks_keys
                    };
                    const updatedEnvironment = currEnv.withUpdatedValue('jwks', combinedJwks);
                    await dispatch(updateEnvironment(updatedEnvironment));
                }
            }

            tokens[i] = {
                ...token.toMap(),
                validation_data: decodedValidatedJwt.validateTokenData,
                decoded_token: decodedValidatedJwt.decodedToken
            };
        }
        dispatch(setTokensDispatch(collectionId, tokens, keys, error));
    }


};

const setTokensDispatch = (collectionId, tokens, keys, error) => {
    return {
        type: C.SET_TOKENS,
        collectionId,
        tokens,
        keys,
        error
    };
};

export const clearResponseInCollection = (collectionId) => {
    return {
        type: C.UPDATE_RESPONSE,
        collectionId,
        response: null
    };
};


const addTokensOnCollection = (getState, collectionId, tokens, keys, error) => {

    const collection = Collection.getCollectionById(collectionId, getState().collections);
    const oldTokens = collection.tokens;
    Object.values(oldTokens).reverse().forEach(value => {
        tokens.unshift(Token.createNewToken({
            purpose: value.purpose,
            name: value.name,
            value: value.value
        }));
    });

    return setTokensOnCollection(collectionId, tokens, keys, error);
};

export const updateParameters = (collectionId, updatedParameters) => {

    if (!(updatedParameters instanceof Parameters)) {
        console.error('updatedParameters called with incorrect input of parameters value', updatedParameters);
        throw 'Invalid type for updatedParameters';
    }
    return {
        type: C.UPDATE_PARAMETERS,
        collectionId,
        updatedParameters
    };
};

export const refreshTokens = (token, collection, environment) => {
    const requestParameters = {
        grant_type: 'refresh_token',
        refresh_token: token.value
    };
    if (collection.parameters.code_verifier) {
        requestParameters.code_verifier = collection.parameters.code_verifier;
    }
    if (collection.parameters.token_endpoint_auth_method === 'client_secret_post') {
        requestParameters.client_id = collection.parameters.client_id;
        requestParameters.client_secret = collection.parameters.client_secret || '';
    }

    return tokenEndpointRequest(collection, environment, requestParameters, true, 'RefreshTokensResponse');
};

export const userInfoRequest = (token, collection, environment) => {
    const userInfoEndpoint = environment.endpoints.userinfo_endpoint;

    return (dispatch, getState) => {
        const collectionState = Collection.getCollectionById(collection.id, getState().collections);
        const additionalHeader = { 'Authorization': 'Bearer ' + token };

        proxiedBackendCall('GET', userInfoEndpoint, {}, additionalHeader)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'UserInfoResponse'));

                if (response.status_code === 200) {

                    const tokens = [];
                    if (response.response_headers['content-type'].toLowerCase() === 'application/jwt') {
                        tokens.push(Token.createNewToken({
                            name: 'Userinfo Response',
                            value: response.data
                        }))
                    } else {
                        tokens.push(Token.createNewToken({
                            name: 'Userinfo Response',
                            decoded_token: { body: response.data }
                        }))
                    }
                    const keys = (environment.jwks !== undefined) ? environment.jwks : [];
                    dispatch(setTokensOnCollection(collectionState.id, tokens, keys, null));
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to userinfo endpoint resulted in status code: ' + response.status_code + detailedError));
                }
            })
            .catch((error) => {
                dispatch(setErrorOnCollection(collectionState.id, 'Could not get userinfo: ' + JSON.stringify(error)));
            })

    }
};

export const revocationRequest = (collection, environment) => {

    const revocationEndpoint = environment.endpoints.revocation_endpoint;
    const parameters = collection.parameters;
    const client_auth_method = parameters.revocation_endpoint_auth_method ?
        parameters.revocation_endpoint_auth_method : 'client_secret_basic';

    let requestBody = {
        token: parameters.token_to_revoke,
        token_type_hint: parameters.token_type_hint
    };

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    if (!parameters.revocation_endpoint_auth_method || parameters.revocation_endpoint_auth_method === 'client_secret_basic') {
        headers.Authorization = 'Basic ' + base64encode(parameters.client_id + ':' + (parameters.client_secret || ''));
    }

    if (client_auth_method === 'client_secret_post') {
        requestBody.client_id = parameters.client_id;
        requestBody.client_secret = (parameters.client_secret || '');
    }

    parameters.request_extra_query_parameters?.filter(
        queryParam => queryParam.name !== '' || queryParam.value !== '')
        .forEach(queryParam => {
            requestBody[queryParam.name] = queryParam.value
        });

    const encodedBody = encodeUrlParameter(requestBody)

    return (dispatch, getState) => {
        const collectionState = Collection.getCollectionById(collection.id, getState().collections);
        const tokenState = collectionState.getTokenById(0);

        proxiedBackendCall('POST', revocationEndpoint, {}, headers, encodedBody, null)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'RevocationResponse'));

                const decodedToken = {
                    'header': null,
                    'body': response.data,
                    'signature': null
                };
                if (response.status_code !== 200) {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to Revocation Endpoint resulted in status code: ' + response.status_code + detailedError));
                }
                dispatch({
                    type: C.UPDATE_TOKEN,
                    collectionId: collectionState.id,
                    tokenValidation: { decodedToken },
                    updatedToken: tokenState

                })
            })
            .catch((error) => {
                const decodedToken = {
                    'header': null,
                    'body': null,
                    'signature': null
                };

                dispatch({
                    type: C.UPDATE_TOKEN,
                    collectionId: collectionState.id,
                    tokenValidation: { decodedToken },
                    updatedToken: tokenState

                });
                dispatch(setErrorOnCollection(collectionState.id, 'Could not revoke token: ' + JSON.stringify(error)));
            })
    };
};

export const introspectToken = (token, collection, environment, extraParams) => {

    const useApplicationJwt = collection.parameters.use_application_jwt ?
        collection.parameters.use_application_jwt : false;

    const clientId = collection.parameters.use_separate_client_for_introspection ?
        collection.parameters.introspection_client_id : collection.parameters.client_id;
    const clientSecret = collection.parameters.use_separate_client_for_introspection ?
        collection.parameters.introspection_client_secret : collection.parameters.client_secret;


    return (dispatch, getState) => {

        const collectionState = Collection.getCollectionById(collection.id, getState().collections);
        const tokenState = collectionState.getTokenById(token.id);

        const bodyParams = {
            token: token.value,
            ...extraParams
        };

        if (collection.parameters.token_type_hint) {
            bodyParams.token_type_hint = collection.parameters.token_type_hint;
        }
        if (collection.parameters.introspection_endpoint_auth_method === 'client_secret_post') {
            bodyParams.client_id = clientId;
            bodyParams.client_secret = clientSecret || '';
        }

        const headers = {
            'Accept': useApplicationJwt ? 'application/jwt' : 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        if (!collection.parameters.introspection_endpoint_auth_method || collection.parameters.introspection_endpoint_auth_method === 'client_secret_basic') {
            headers.Authorization = 'Basic ' + base64encode(clientId + ':' + (clientSecret || ''))
        }

        const body = encodeUrlParameter(bodyParams);

        proxiedBackendCall('POST', environment.endpoints.introspection_endpoint, {}, headers, body)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'IntrospectionFlowResponse'));

                let responseData;

                if (response.status_code === 200 || response.status_code === 204) {
                    responseData = response.data;

                    const updatedParameters =
                        collectionState.parameters
                            .withUpdatedValue('pre_authorized_code', response.data['pre-authorized_code'])
                    const spendAuthorizationCodeAction = updateParameters(collectionState.id, updatedParameters);
                    dispatch(spendAuthorizationCodeAction)
                } else {
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to introspection endpoint resulted in status code: ' + response.status_code));
                }
                dispatch({
                    type: C.UPDATE_INTROSPECTED_TOKEN,
                    collectionId: collectionState.id,
                    introspected_token_data: responseData,
                    updatedToken: tokenState

                })
            })
            .catch((error) => {
                dispatch(setTokensOnCollection(collection.id, [], null, error));
            });
    };
};

export const exchangeCodeForToken = (collection, environment, redirectUri) => {
    const requestParameters = {
        grant_type: 'authorization_code',
        redirect_uri: redirectUri,
        code: collection.parameters.authorization_code
    };

    if (collection.parameters.code_verifier) {
        requestParameters.code_verifier = collection.parameters.code_verifier;
    }
    if (collection.parameters.token_endpoint_auth_method === 'client_secret_post') {
        requestParameters.client_id = collection.parameters.client_id;
        requestParameters.client_secret = collection.parameters.client_secret || '';
    }

    collection.parameters.token_request_extra_query_parameters?.filter(
        queryParam => queryParam.name !== '' || queryParam.value !== '')
        .forEach(queryParam => {
            requestParameters[queryParam.name] = queryParam.value
        });

    return tokenEndpointRequest(collection, environment, requestParameters, false, 'CodeFlowTokens');
};

export const tokenEndpointRequest = (collection, environment, bodyParameters, replaceExistingTokens, OAuthFlowName) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    if (!collection.parameters.token_endpoint_auth_method || collection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
        headers.Authorization = 'Basic ' + base64encode(collection.parameters.client_id + ':' + (collection.parameters.client_secret || ''))
    }

    const body = encodeUrlParameter(bodyParameters);

    return (dispatch, getState) => {
        proxiedBackendCall('POST', environment.endpoints.token_endpoint, {}, headers, body)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, OAuthFlowName));

                const collectionState =
                    Collection.getCollectionById(collection.id, getState().collections);
                let tokens = [];
                if (response.status_code === 200) {
                    if (response.data.access_token) {
                        tokens.push(Token.createNewToken({
                            purpose: tokenPurposes.access_token.value,
                            name: 'Access Token',
                            value: response.data.access_token
                        }));
                    }
                    if (response.data.refresh_token) {
                        tokens.push(Token.createNewToken({
                            purpose: tokenPurposes.refresh_token.value,
                            name: 'Refresh Token',
                            value: response.data.refresh_token
                        }));
                    }
                    if (response.data.authorization_details) {
                        tokens.push(Token.createNewToken({
                            purpose: tokenPurposes.authorization_details.value,
                            name: 'Authorization Details',
                            authorization_details: response.data.authorization_details
                        }))
                    }
                    if (response.data.id_token) {
                        tokens.push(Token.createNewToken({
                            purpose: tokenPurposes.id_token.value,
                            name: 'ID Token',
                            value: response.data.id_token
                        }));
                    }
                    const updatedParameters =
                        collectionState.parameters
                            .withUpdatedValue('authorization_code_spent', true)
                            .withUpdatedValue('pre_authorized_code', response.data['pre-authorized_code'])
                            .withUpdatedValue('c_nonce', response.data.c_nonce)
                            .withUpdatedValue('auth_req_id_spent', true);
                    const spendAuthorizationCodeAction = updateParameters(collectionState.id, updatedParameters);
                    dispatch(spendAuthorizationCodeAction);
                    const keys = (environment.jwks !== undefined) ? environment.jwks : [];
                    const setTokensAction = replaceExistingTokens ?
                        setTokensOnCollection(collectionState.id, tokens, keys)
                        : addTokensOnCollection(getState, collectionState.id, tokens, keys);
                    dispatch(setTokensAction);
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to token endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                dispatch(setTokensOnCollection(collection.id, [], null, error));
            });
    };
};

export const runDCRRequest = (environmentId, requestParameters, initialAccessToken, collection) => {
    return (dispatch, getState) => {
        const currentEnvironments = Environments.create(getState().environments);
        const collectionState = Collection.getCollectionById(collection.id, getState().collections);
        const currentEnvironment = currentEnvironments.getEnvironment(environmentId);
        const registrationEndpoint = currentEnvironment.endpoints.registration_endpoint;

        const additionalHeaders = initialAccessToken ? { 'Authorization': 'Bearer ' + initialAccessToken } : {};

        proxiedBackendCall('POST', registrationEndpoint, {}, additionalHeaders, requestParameters, null)
            .then((response) => {

                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'DynamicClientRegistrationResponse'));

                if (response.status_code === 201) {
                    dispatch({
                        type: C.UPDATE_RESPONSE,
                        collectionId: collectionState.id,
                        response: response.data
                    });
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to dynamic client registration endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                const response = {
                    'headers': null,
                    'body': null,
                    'status': null
                };

                dispatch({
                    type: C.UPDATE_RESPONSE,
                    collectionId: collectionState.id,
                    response

                });
                dispatch(setErrorOnCollection(collectionState.id, 'Could not contact Dynamic Client Registration Endpoint: ' + JSON.stringify(error)));
            })
    }
};

export const runDCRMRequest = (environmentId, collection) => {
    return (dispatch, getState) => {
        const workspaces = Environments.create(getState().environments);
        const currentWorkspace = workspaces.getEnvironment(environmentId);
        const selectedClientMapId = currentWorkspace.clientMapId(collection.parameters.dcrm_client_id);
        const selectedClient = currentWorkspace.clients[selectedClientMapId];
        const method = collection.parameters.dcrm_request_method?.value;
        const token = collection.parameters.dcrm_token ? collection.parameters.dcrm_token
            : selectedClient.registration_access_token;
        const additionalHeaders = token ? { 'Authorization': 'Bearer ' + token } : {};

        const requestParameters = method === 'PUT' ? collection.parameters.dcrm_request_body : null;
        const body_type = method === 'PUT'? 'json' : null;

        proxiedBackendCall(method, selectedClient.registration_client_uri, {},
            additionalHeaders, requestParameters, body_type)
            .then((response) => {

                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'DynamicClientManagementResponse'));

                if (response.status_code === 200 || response.status_code === 204) {
                    dispatch({
                        type: C.UPDATE_RESPONSE,
                        collectionId: collection.id,
                        response: response.data
                    });
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collection.id,
                        'Call to dynamic client management endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                const response = {
                    'headers': null,
                    'body': null,
                    'status': null
                };

                dispatch({
                    type: C.UPDATE_RESPONSE,
                    collectionId: collection.id,
                    response

                });
                dispatch(setErrorOnCollection(collection.id, 'Could not contact Dynamic Client Management Endpoint: ' + JSON.stringify(error)));
            })
    }
}

export const deviceEndpointRequest = (collection, environment) => {
    const deviceAuthorizationEndpoint = environment.endpoints.device_authorization_endpoint;
    const deviceEndpointAuthorizationMethod = collection.parameters.device_authorization_endpoint_auth_method ?
        collection.parameters.device_authorization_endpoint_auth_method : 'client_secret_basic';
    const bodyParameters = {};
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    if (!isEmpty(collection.parameters.claims)) {
        bodyParameters.claims = JSON.stringify(claimsArrayToRequestParameter(collection.parameters.claims));
    }


    if (deviceEndpointAuthorizationMethod === 'client_secret_basic') {
        headers.Authorization = 'Basic ' + base64encode(collection.parameters.client_id + ':' + (collection.parameters.client_secret || ''))
    } else {
        bodyParameters.client_id = collection.parameters.client_id;
        bodyParameters.client_secret = collection.parameters.client_secret || '';
    }


    if (collection.parameters.nonce) {
        bodyParameters.nonce = collection.parameters.nonce;
    }

    const scopeList = (collection.parameters.scopes) ?
        collection.parameters.scopes.map((scope) => scope.value) : [];
    if (scopeList.length > 0) {
        bodyParameters.scope = scopeList.join(' ');
    }

    collection.parameters.request_extra_query_parameters?.filter(
        queryParam => queryParam.name !== '' || queryParam.value !== '')
        .forEach(queryParam => {
            bodyParameters[queryParam.name] = queryParam.value
        });

    const body = encodeUrlParameter(bodyParameters);

    return (dispatch, getState) => {

        proxiedBackendCall('POST', deviceAuthorizationEndpoint, {}, headers, body)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'DeviceFlowAuthorizationResponse'));

                const collectionState =
                    Collection.getCollectionById(collection.id, getState().collections);
                if (response.status_code === 200) {
                    let updatedParameters = collectionState.parameters;

                    if (response.data.device_code) {
                        updatedParameters = updatedParameters.withUpdatedValue('device_device_code', response.data.device_code);
                    }
                    if (response.data.user_code) {
                        updatedParameters = updatedParameters.withUpdatedValue('device_user_code', response.data.user_code);
                    }
                    if (response.data.verification_uri) {
                        updatedParameters = updatedParameters.withUpdatedValue('device_verification_uri', response.data.verification_uri);
                    }
                    if (response.data.verification_uri_complete) {
                        updatedParameters = updatedParameters.withUpdatedValue('device_verification_uri_complete', response.data.verification_uri_complete);
                    }
                    if (response.data.qr_code) {
                        updatedParameters = updatedParameters.withUpdatedValue('device_qr_code', response.data.qr_code);
                    }
                    dispatch(updateParameters(collectionState.id, updatedParameters));
                    dispatch(setTokensOnCollection(collection.id, [], null, null));
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to device endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                dispatch(setTokensOnCollection(collection.id, [], null, error));
            });
    };
};

export const backChannelEndpointRequest = (collection, environment) => {
    const backChannelEndpoint = environment.endpoints.backchannel_authentication_endpoint;
    const backChannelEndpointAuthorizationMethod = collection.parameters.backchannel_endpoint_auth_method ?
        collection.parameters.backchannel_endpoint_auth_method : 'client_secret_basic';
    const bodyParameters = {};
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    if (!isEmpty(collection.parameters.claims)) {
        bodyParameters.claims = JSON.stringify(claimsArrayToRequestParameter(collection.parameters.claims));
    }

    const acrList = (collection.parameters.acrs) ?
        collection.parameters.acrs.map((scope) => scope.value) : [];
    if (acrList.length > 0) {
        bodyParameters.acr_values = acrList.join(' ');
    }

    if (collection.parameters.login_hint) {
        bodyParameters.login_hint = collection.parameters.login_hint;
    }

    if (collection.parameters.login_hint_token) {
        bodyParameters.login_hint_token = collection.parameters.login_hint_token;
    }

    if (collection.parameters.requested_expiry) {
        bodyParameters.requested_expiry = collection.parameters.requested_expiry;
    }

    if (collection.parameters.user_code) {
        bodyParameters.user_code = collection.parameters.user_code;
    }

    if (collection.parameters.binding_message) {
        bodyParameters.binding_message = collection.parameters.binding_message;
    }

    if (collection.parameters.id_token_hint) {
        bodyParameters.id_token_hint = collection.parameters.id_token_hint;
    }

    if (backChannelEndpointAuthorizationMethod === 'client_secret_basic') {
        headers.Authorization = 'Basic ' + base64encode(collection.parameters.client_id + ':' + (collection.parameters.client_secret || ''))
    } else {
        bodyParameters.client_id = collection.parameters.client_id;
        bodyParameters.client_secret = collection.parameters.client_secret || '';
    }

    if (collection.parameters.nonce) {
        bodyParameters.nonce = collection.parameters.nonce;
    }

    const scopeList = (collection.parameters.scopes) ?
        collection.parameters.scopes.map((scope) => scope.value) : [];
    if (scopeList.length > 0) {
        bodyParameters.scope = scopeList.join(' ');
    }

    collection.parameters.request_extra_query_parameters?.filter(
        queryParam => queryParam.name !== '' || queryParam.value !== '')
        .forEach(queryParam => {
            bodyParameters[queryParam.name] = queryParam.value
        });


    const body = encodeUrlParameter(bodyParameters);

    return (dispatch, getState) => {

        proxiedBackendCall('POST', backChannelEndpoint, {}, headers, body)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'CIBAFlowAuthorizationResponse'));

                const collectionState =
                    Collection.getCollectionById(collection.id, getState().collections);
                if (response.status_code === 200 && response.data.auth_req_id && response.data.expires_in) {
                    let updatedParameters = collectionState.parameters;

                    updatedParameters = updatedParameters.withUpdatedValue('auth_req_id', response.data.auth_req_id);
                    updatedParameters = updatedParameters.withUpdatedValue('expires_in', response.data.expires_in);

                    if (response.data.interval) {
                        updatedParameters = updatedParameters.withUpdatedValue('interval', response.data.interval);
                    }
                    dispatch(updateParameters(collectionState.id, updatedParameters));
                    dispatch(setTokensOnCollection(collection.id, [], null, null));
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to backchannel endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                dispatch(setTokensOnCollection(collection.id, [], null, error));
            });
    };
};

export const pushedAuthorizationRequest = (collection, environment, requestParameters) => {
    const parEndpoint = environment.endpoints.pushed_authorization_request_endpoint;
    const tokenEndpointAuthorizationMethod = collection.parameters.token_endpoint_auth_method ?
        collection.parameters.token_endpoint_auth_method : 'client_secret_basic';
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    if (tokenEndpointAuthorizationMethod === 'client_secret_basic') {
        headers.Authorization = 'Basic ' + base64encode(collection.parameters.client_id + ':' + (collection.parameters.client_secret || ''))
    } else {
        requestParameters.client_id = collection.parameters.client_id;
        requestParameters.client_secret = collection.parameters.client_secret || '';
    }

    const body = encodeUrlParameter(requestParameters);

    return (dispatch, getState) => {

        proxiedBackendCall('POST', parEndpoint, {}, headers, body)
            .then((response) => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'CodeFlowPushedAuthorization'));

                const collectionState =
                    Collection.getCollectionById(collection.id, getState().collections);
                if (response.status_code === 201 && response.data.request_uri && response.data.expires_in) {
                    let updatedParameters = collectionState.parameters;
                    updatedParameters = updatedParameters.withUpdatedValue('par_request_uri', response.data.request_uri);
                    updatedParameters = updatedParameters.withUpdatedValue('authorization_code_spent', false);

                    dispatch(updateParameters(collectionState.id, updatedParameters));
                    dispatch(setTokensOnCollection(collection.id, [], null, null));
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to PAR endpoint resulted in status code: ' + response.status_code + detailedError));
                }

            })
            .catch((error) => {
                dispatch(setTokensOnCollection(collection.id, [], null, error));
            });
    };
};

export const issueCredentialsRequest = (collection, workspace, requestObject) => {
    return (dispatch, getState) => {
        proxiedBackendCall('POST', requestObject.url, null, requestObject.headers, requestObject.body, 'json')
            .then((response => {
                dispatch(setOAuthResponseOnCollection(collection.id, {
                    type: oauthResponseTypes.json,
                    body: JSON.stringify(response.data),
                    status: response.status_code,
                    headers: response.response_headers
                }, 'VerifiableCredentialIssuanceResponse'));

                const collectionState =
                    Collection.getCollectionById(collection.id, getState().collections);

                if (response.status_code === 200 && response.data.credential) {
                    const tokens = [];
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.verifiable_credential.value,
                        name: 'Credential',
                        value: response.data.credential
                    }));
                    const keys = (workspace.jwks !== undefined) ? workspace.jwks : [];
                    dispatch(setTokensOnCollection(collectionState.id, tokens, keys));
                } else {
                    const detailedError = response.data.error_description ? ', ' + response.data.error_description : '';
                    dispatch(setErrorOnCollection(collectionState.id,
                        'Call to credential endpoint resulted in status code: ' + response.status_code + detailedError));
                }

                const updatedParameters = collectionState.parameters
                    .withUpdatedValue('c_nonce', response.data.c_nonce);
                const updateCNonce = updateParameters(collectionState.id, updatedParameters);
                dispatch(updateCNonce);

            }))
    }
}

export const externalAPIRequest = (token, collection) => {
    let apiEndpoint = collection.parameters.api_call_endpoint;
    const requestMethod = collection.parameters.api_call_request_method.value;
    const tokenValue = token ? token.value : null;

    return (dispatch, getState) => {
        const collectionState = Collection.getCollectionById(collection.id, getState().collections);

        const additionalHeaders = tokenValue ? { 'Authorization': 'Bearer ' + tokenValue } : {};
        const headers = collection.parameters.api_call_headers;
        headers.filter(header => header.name !== '').forEach(header => {
            additionalHeaders[header.name] = header.value
        });

        const queryParameters = collection.parameters.api_call_parameters;
        const queryParametersMap = {};
        queryParameters.filter(queryParam => queryParam.name !== '').forEach(queryParam => {
            if (queryParametersMap[encodeURIComponent(queryParam.name)]) {
                queryParametersMap[encodeURIComponent(queryParam.name)].push(encodeURIComponent(queryParam.value))
            } else {
                queryParametersMap[encodeURIComponent(queryParam.name)] = [encodeURIComponent(queryParam.value)]
            }
        });
        const body = collection.parameters.api_call_body;
        const body_type = collection.parameters.api_call_body_type;

        proxiedBackendCall(requestMethod, apiEndpoint, queryParametersMap, additionalHeaders, body, body_type)
            .then((data) => {
                const response = {
                    'headers': data.response_headers ? data.response_headers : {},
                    'body': data.data,
                    'status': data.status_code
                };

                dispatch({
                    type: C.UPDATE_RESPONSE,
                    collectionId: collectionState.id,
                    response
                });
            })
            .catch((error) => {
                const response = {
                    'headers': null,
                    'body': null,
                    'status': null
                };

                dispatch({
                    type: C.UPDATE_RESPONSE,
                    collectionId: collectionState.id,
                    response

                });
                dispatch(setErrorOnCollection(collectionState.id, 'Could not contact API Endpoint: ' + JSON.stringify(error)));
            })

    }
};

export const selectProviderForCollection = (providerId, collectionId) => {
    return {
        type: C.SELECT_PROVIDER_FOR_COLLECTION,
        providerId,
        collectionId
    }
};

export const updateToken = (collectionId, updatedToken) => {

    if (!(updatedToken instanceof Token)) {
        console.error('updateToken called with incorrect input of token value');
        throw 'Invalid type for updatedToken';
    }

    return async (dispatch, getState) => {
        // when it's a jwt but not opaque
        const collection = Collection.getCollectionById(collectionId, getState().collections);
        let tokenValidation = {};
        tokenValidation.decodedToken = {};
        const state = getState();
        const { appState, collections, environments } = state;
        let dispatchedUpdatedToken = updatedToken;

        if (updatedToken.isJwt()) {
            let jwksKeys;
            let jwksUriEndpoint;
            if (Object.keys(collections).length > 0) {
                const environment = environments[appState.activeTab];
                if (environment) {
                    jwksKeys = environment.jwks || {};
                    jwksUriEndpoint = environment.endpoints && environment.endpoints.jwks_uri ? environment.endpoints.jwks_uri : '';
                }
            }

            const { value, validation_key, purpose } = updatedToken;
            tokenValidation = await decodeAndValidateJwt(value, validation_key.pem, purpose, jwksKeys,
                jwksUriEndpoint, collection.flow);

            const { validateTokenData } = tokenValidation;
            if (validateTokenData && validateTokenData.updated_jwks_keys &&
                Object.keys(validateTokenData.updated_jwks_keys).length > 0) {
                const { updated_jwks_keys } = validateTokenData;
                const envs = Environments.create(environments);
                const currEnv = envs.getEnvironment(appState.activeTab);
                const combinedJwks = {
                    ...currEnv.jwks,
                    ...updated_jwks_keys
                };
                const updatedEnvironment = currEnv.withUpdatedValue('jwks', combinedJwks);
                await dispatch(updateEnvironment(updatedEnvironment));
            }

            if (validateTokenData && validateTokenData.signature && validateTokenData.signature.key) {
                dispatchedUpdatedToken = updatedToken.withUpdatedProperty('validation_key', tokenValidation.validateTokenData.signature.key);
            }
        }

        dispatch(updateTokenDispatch(collectionId, dispatchedUpdatedToken, tokenValidation));
    };
};

export const validateInputForTokenFlow = (collection) => {
    const params = collection.parameters;
    return async (dispatch) => {
        const token = Token.createNewToken({
            value: params.jwt_flow_token_input?.replace(/(\r\n|\r|\n)/g, ''),
            purpose: params.jwt_flow_token_purpose,
            validation_key: params.jwt_flow_key.validation_key
        })
        dispatch(updateToken(collection.id, token));
    }
}

const updateTokenDispatch = (collectionId, updatedToken, tokenValidation) => {
    return {
        type: C.UPDATE_TOKEN,
        collectionId,
        updatedToken,
        tokenValidation
    };
};


// IMPORT COLLECTION ACTION CREATOR
export function importCollectionActionCreator(collection) {
    return {
        type: C.IMPORT_COLLECTION,
        collection
    }
}


// IMPORT COLLECTION ACTION DISPATCHER:
export const importCollectionsAction = (collections, importActionType) => {

    return (dispatch, getState) => {
        return new Promise((resolve) => {

            let collectionsArr = [];
            const state = getState();

            if (importActionType === IMPORT_OPTION.MERGE) {
                // GET COLLECTION INSTANCES TO ADD `NEW COLLECTION` AND `IGNORE` SAME `KEY` COLLLECTION PRESENT IN BROWSER APP STATE:
                const uploadedFileKeys = Object.keys(collections);
                const appStateKeys = Object.keys(state.collections);

                const { keysToAdd, keysExists } = filterKeys({ uploadedFileKeys, appStateKeys });

                // ADD IF NOT EXISTS:
                if (keysToAdd.length > 0) {
                    collectionsArr = getCollectionsFromImportFile(collections, keysToAdd);
                }
                // UPDATE IF EXISTS:
                if (keysExists.length > 0) {
                    const stateCollections = state.collections;
                    keysExists.forEach((key) => {
                        const mergeObj = mergeDeep(stateCollections[key], collections[key]);
                        dispatch(updateCollection(key, mergeObj));
                    });
                }
            } else if (importActionType === IMPORT_OPTION.OVERRIDE) {
                // OVERRIDE CASE:
                const uploadedCollectionsKeys = Object.keys(collections);
                collectionsArr = getCollectionsFromImportFile(collections, uploadedCollectionsKeys);
            } else if (importActionType === IMPORT_OPTION.REPLACE) {
                // DELETE CURRENT APP STATE COLLECTIONS:
                const deleteAllCollections = state.collections;
                Object.keys(deleteAllCollections).forEach((collectionKey) => {
                    dispatch(deleteCollection(collectionKey));
                });

                // Add Collections uploaded from Uploaded file:
                const uploadedCollectionsKeys = Object.keys(collections);
                collectionsArr = getCollectionsFromImportFile(collections, uploadedCollectionsKeys);
            }

            // By-Default `FIRST COLLECTION` is selected for `currentCollection` for appState
            const selectCollectionAction = collectionsArr.length > 0 ?
                selectCollection(collectionsArr[0].id) : selectCollection(state.appState.currentCollection);

            collectionsArr.forEach((collection) => {
                if (Object.keys(collection).length > 0) {
                    dispatch(importCollectionActionCreator(collection));
                }
            });
            dispatch(selectCollectionAction);

            resolve(true);
        });

    };
};

// GET COLLECTION INSTANCES FOR UPLOADED FILE:
function getCollectionsFromImportFile(uploadedCollections, filteredKeys) {
    let collectionsArr = [];
    filteredKeys.forEach((key) => {
        const currentCollection = uploadedCollections[key];
        if (currentCollection) {
            const addNewCollection = new Collection(key, currentCollection);
            if (!(addNewCollection instanceof Collection)) {
                console.error('Import Collection called with incorrect input of collection value');
            }
            collectionsArr.push(addNewCollection);
        }
    });

    return collectionsArr;
}


const filterKeys = ({ uploadedFileKeys, appStateKeys }) => {

    let keysToAdd = [];
    let keysExists = [];

    uploadedFileKeys.filter((key) => {
        if (appStateKeys.includes(key)) {
            keysExists.push(key);
        } else {
            keysToAdd.push(key);
        }
    });

    return {
        keysToAdd,
        keysExists
    }
};
