/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from './actionConstants';
import { errorTypes } from '../util/appConstants';

export function selectCollection(collectionId) {

    return {
        type: C.WORKSPACE_CHANGE_COLLECTION,
        activeCollection: collectionId
    }
}

export function changeCollection(index, collections) {
    const tokens = collections[index].tokens;
    let nextToken;
    if (tokens.length > 0) {
        nextToken = tokens[0];
    } else {
        nextToken = null
    }

    return selectCollection(index, nextToken)
}

export const setError = (message) => {
    return {
        type: C.ERROR_SET,
        errorType: errorTypes.ERROR,
        message
    }
};

export const setWarning = (message) => {
    return {
        type: C.ERROR_SET,
        errorType: errorTypes.WARNING,
        message
    }
};

export const setInfo = (message) => {
    return {
        type: C.ERROR_SET,
        errorType: errorTypes.INFO,
        message
    }
};

export const clearError = () => {
    return {
        type: C.ERROR_CLEAR
    };
};
