import React from 'react';

const StandardProtocols = () => (
  <>
    <h2>Standard Protocols</h2>
    <p>
      Standards such as Dynamic Client Registration, OpenID Discovery and
      WebFinger are implemented to allow for rapid prototyping and to
      automatically connect the tool to an OAuth server.
    </p>
  </>
);

export default StandardProtocols;
