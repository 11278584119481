/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect } from 'react';
import { base64encode, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import {
    flows, formatOptionLabel, guides
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import MiddlePaneHeader from './MiddlePaneHeader';
import ResizablePanels from '../ResizablePanels';
import Environments from '../../data/Environments';
import StepBox from './StepBox';
import ServerResponse from '../shared/ServerResponse';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import RunButton from './RunButton';
import ClientCredentials from './settings/ClientCredentials';
import IntrospectionSettings from './settings/IntrospectionSettings';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';
import { IconGeneralClose } from '@curity-internal/ui-icons-react';


const PreAuthorizedCodeFlow = (props) => {

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(props.collection.id, null)
    };

    const setPreAuthorizedCode = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('precode_code', newValue.value);
            props.updateParameters(props.collection.id, updatedParameters);
        }
        if (actionMeta.action === 'clear') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('precode_code', undefined);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    }

    const setTransactionCode = (e) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('precode_tx_code', e.currentTarget.value);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const enableStep2 = !!props.collection.parameters.client_id && !!props.collection.parameters.precode_code &&
        !!props.collection.parameters.precode_tx_code;


    const curlPreview = () => {
        const workspaces = Environments.create(props.environments);
        const workspace = workspaces.getEnvironment(props.collection.provider);
        const currentCollection = props.collection;

        const requestParameters = {
            grant_type: 'urn:ietf:params:oauth:grant-type:pre-authorized_code',
            'pre-authorized_code': props.collection.parameters.precode_code,
            'tx_code': props.collection.parameters.precode_tx_code
        };

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const tokenEndpoint = workspace ? workspace.endpoints.token_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + encodeUrlParameter(requestParameters) + '\''
    };

    const runFlow = () => {
        const requestParameters = {
            grant_type: 'urn:ietf:params:oauth:grant-type:pre-authorized_code',
            'pre-authorized_code': props.collection.parameters.precode_code,
            'tx_code': props.collection.parameters.precode_tx_code
        };
        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        props.tokenEndpointRequest(currentCollection, currentWorkspace, requestParameters, true, 'PreAuthorizedCodeFlowResponse');
    };


    const currentCollection = props.collection;
    const workspaces = Environments.create(props.environments);
    const currentWorkspace = workspaces.getEnvironment(currentCollection.provider);

    const error = (!currentCollection.error) ? '' : <div className="alert alert-danger mb2">

        {decodeUrlParameter(currentCollection.error)}
        <button className="alert-close" onClick={clearErrorFromCollection}><IconGeneralClose width={18} height={18} />
        </button>
    </div>;

    const preAuthorizedCodeFromCollections = [];
    Object.values(props.collections)
        .filter(collection => collection.provider === currentCollection.provider)
        .forEach(collection => {
            if (collection.parameters.pre_authorized_code) {
                preAuthorizedCodeFromCollections.push({
                    value: collection.parameters.pre_authorized_code,
                    label: `${collection.name}: `,
                    tokenString: collection.parameters.pre_authorized_code,
                    meta: {
                        name: collection.parameters.pre_authorized_code
                    }
                });
            }
        });


    const selectedPreAuthorizedCode = currentCollection.parameters.precode_code;
    let selectedPreAuthorizedCodeOption = null;
    if (selectedPreAuthorizedCode) {
        let maybePreAuthorizedCodeOption = preAuthorizedCodeFromCollections.filter(
            option => option.value === selectedPreAuthorizedCode);
        if (maybePreAuthorizedCodeOption.length === 0) {
            maybePreAuthorizedCodeOption = {
                value: selectedPreAuthorizedCode, label: selectedPreAuthorizedCode, meta: {
                    name: selectedPreAuthorizedCode
                }
            }
            preAuthorizedCodeFromCollections.push(maybePreAuthorizedCodeOption);
        }
        selectedPreAuthorizedCodeOption = maybePreAuthorizedCodeOption;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const requestPreview = curlPreview()

    return (<React.Fragment>
        <ResizablePanels {...props}>
            <section className="tools-form">
                <MiddlePaneHeader
                    collection={props.collection}
                    exportCurrentCollection={props.exportCurrentCollection}/>
                <div className="tools-form-content">

                    <FlowHeader name={currentCollection.name}
                                description={'Build a Pre Authorized Code request.'}/>
                    {error}

                    <StepBox title={'Settings'} step={'1'} enabled={true}>

                        <ClientCredentials
                            updateParameters={props.updateParameters}
                            updateEnvironment={props.updateEnvironment}
                            collection={currentCollection}
                            environment={currentWorkspace}
                            flow={flows.precode}
                        />

                        <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                            <div className="flex-auto">
                                <input className="field col-12" type="text" min="0" placeholder="Transaction Code"
                                       value={props.collection.parameters.precode_tx_code}
                                       onChange={setTransactionCode}
                                       data-qa={'login-hint'}
                                       autoComplete="off"
                                       data-lpignore="true"
                                       spellCheck="false"
                                />
                            </div>
                            <div className="flex-auto">
                                <Creatable
                                    isClearable
                                    placeholder="Type a pre-authorized code or select one from a Collection"
                                    components={makeAnimated()}
                                    inputId={'select-c-nonce-vci'}
                                    options={preAuthorizedCodeFromCollections}
                                    formatOptionLabel={formatOptionLabel}
                                    defaultValue={selectedPreAuthorizedCodeOption}
                                    onChange={setPreAuthorizedCode}
                                    className="select-container select-container-big"
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme, borderRadius: 0, colors: {
                                            ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <IntrospectionSettings
                            collection={currentCollection}
                            environment={currentWorkspace}
                            updateEnvironment={props.updateEnvironment}
                            updateParameters={props.updateParameters}
                        />

                    </StepBox>

                    <StepBox title={'Call token Endpoint'} step={'2'} enabled={enableStep2}>
                        <div className={'flex justify-end'}>
                            <ExtraQueryParametersModal
                                updateParameters={props.updateParameters}
                                collection={props.collection}
                                parameter={'token_request_extra_query_parameters'}
                            />
                        </div>
                        <CurlRequestPreview request={requestPreview}/>
                        <RunButton runFlow={runFlow}/>

                    </StepBox>

                    <ServerResponse response={currentCollection.OAuthResponses.PreAuthorizedCodeFlowResponse}/>

                </div>
            </section>

            <ReceivedTokensSidebar
                guide={guides.precode}
                flow={flows.precode}
                collection={currentCollection}
                environment={currentWorkspace}
                groups={props.groups}
                clearOAuthResponses={props.clearOAuthResponses}
                setTokensOnCollection={props.setTokensOnCollection}
                introspectToken={props.introspectToken}
                createAndSelectCollectionWithToken={props.createAndSelectCollectionWithToken}
            />
        </ResizablePanels>
    </React.Fragment>);

}

export default PreAuthorizedCodeFlow
