/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated';
import { flows } from '../../../util/appConstants';
import { getUnique, randomTimeId } from '../../../util/util';

class ResponseTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.collection.parameters.responseTypes
        }
    }

    onChange = (value, { action }) => {
        switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (value.length === 0) {
                    this.props.collection.parameters.default_response_types.forEach(response_type => {
                        value.push({
                            value: response_type,
                            label: response_type
                        });
                    });
                }
                break;
            case 'clear':
                this.props.collection.parameters.default_response_types.forEach(response_type => {
                    value.push({
                        value: response_type,
                        label: response_type
                    });
                });
                break;
        }

        this.handleChange(value);
    };

    handleChange = (selectedOption) => {
        let updatedParameters =
            this.props.collection.parameters.withUpdatedValue('responseTypes', selectedOption);
        if (selectedOption.map((e) => {
            return e.value;
        }).indexOf('id_token') > 0) {
            const nonceValue = randomTimeId();
            updatedParameters = updatedParameters.withUpdatedValue('nonce', nonceValue);
        }

        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const collection = this.props.collection;
        let responseTypeOptions = [];
        if (this.props.environment && this.props.environment.response_types_supported) {
            const optionList = this.props.environment.response_types_supported;
            responseTypeOptions = optionList
                .filter((value) => {
                    return !(value === 'code' && collection.flow === flows.implicit.id);

                }).map((value) => {
                    return { value, label: value }
                });
        }

        this.props.collection.parameters.default_response_types.forEach(response_type => {
            responseTypeOptions.push({
                value: response_type,
                label: response_type
            });
        });

        responseTypeOptions = getUnique(responseTypeOptions, 'value');


        let selectedOptions = this.props.collection.parameters.responseTypes ? [] : responseTypeOptions;

        if (this.props.collection.parameters.responseTypes) {
            this.props.collection.parameters.responseTypes.forEach(response_type => {
                selectedOptions.push({
                    value: response_type.value,
                    label: response_type.label
                });
            });
        }


        selectedOptions = getUnique(selectedOptions, 'value');

        return (
            <>
                <label>Response types</label>
                <div className="sm-flex flex-justify flex-center">
                    <div className="flex-100">
                        <Creatable
                            isMulti
                            inputId="responsetypes"
                            placeholder="Select or add response types"
                            components={makeAnimated()}
                            options={responseTypeOptions}
                            defaultValue={selectedOptions}
                            onChange={this.onChange}
                            className="select-container select-container-big"
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#f2f3f6',
                                    primary: '#626c87'
                                }
                            })}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default ResponseTypes
