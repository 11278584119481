/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from '../actions/actionConstants';
import { flows } from '../util/appConstants';
import Collection from '../data/Collection';
import { logEvent } from '../util/analytics';
import { removeKey } from '../util/util';

function selectEnvironment(state, action) {
    const collection = state[action.collectionId];
    const updatedCollection = {
        ...collection,
        provider: action.providerId
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function updateCollection(state, action) {

    const updatedCollection = {
        ...state[action.collectionId],
        ...action.collectionData
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function createCollection(state, action) {

    const name = flows[action.flow].label;
    const response_types = flows[action.flow].response_types;
    const newCollection = Collection.createCollection(action.index, name, response_types, action.flow, action.provider);

    logEvent('Collection', 'created', action.flow);

    return {
        ...state,
        [action.index]: newCollection.toMap()
    };
}

function createDemoCollection(state, action) {
    logEvent('Collection', 'created', action.flow);

    return {
        ...state,
        [action.id]: { ...action.flow }
    };
}

function deleteCollection(state, action) {

    const newState = {
        ...state
    };

    delete newState[action.collectionId];
    return newState;
}

function importCollection(state, action) {
    const newCollection = action.collection;
    return {
        ...state,
        [newCollection.id]: newCollection.toMap()
    };
}
function updateIntrospectedToken(state, action) {
    const newToken = {
        ...action.updatedToken.toMap(),
        introspected_token_data: action.introspected_token_data
    };

    const currentCollection = state[action.collectionId];
    let updatedCollectionTokens;

    updatedCollectionTokens = {
        ...currentCollection.tokens,
        [action.updatedToken.id]: newToken
    };


    const updatedCollection = {
        ...currentCollection,
        tokens: updatedCollectionTokens
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}
function updateToken(state, action) {

    const newToken = {
        ...action.updatedToken.toMap(),
        validation_data: action.tokenValidation.validateTokenData,
        decoded_token: action.tokenValidation.decodedToken
    };

    const currentCollection = state[action.collectionId];
    let updatedCollectionTokens;

    if (currentCollection.flow === flows.none.id) {
        updatedCollectionTokens = {
            [action.updatedToken.id]: newToken
        };
    } else {
        updatedCollectionTokens = {
            ...currentCollection.tokens,
            [action.updatedToken.id]: newToken
        };
    }

    const updatedCollection = {
        ...currentCollection,
        tokens: updatedCollectionTokens
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function setTokens(state, action) {

    const currentCollection = state[action.collectionId];
    const updatedCollection = {
        ...currentCollection,
        tokens: action.tokens,
        error: action.error
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function updateKeyInToken(state, action) {

    const currentCollection = state[action.collectionId];
    const currentToken = currentCollection.tokens[action.tokenId];

    const newToken = {
        ...currentToken,
        validation_key: action.keyValue,
        validation_data: action.validation
    };

    const updatedCollectionTokens = {
        ...currentCollection.tokens,
        [action.tokenId]: newToken
    };

    const updatedCollection = {
        ...currentCollection,
        tokens: updatedCollectionTokens
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function updatePrivateKeyInToken(state, action) {
    const currentCollection = state[action.collectionId];
    const currentToken = currentCollection.tokens[action.tokenId];

    const newToken = {
        ...currentToken,
        private_key: action.keyValue,
        value: '',
        validation_data: {},
        decoded_token: {}
    };

    const updatedCollectionTokens = {
        ...currentCollection.tokens,
        [action.tokenId]: newToken
    };
    const updatedCollection = {
        ...currentCollection,
        tokens: updatedCollectionTokens
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function deleteEnvironmentFromCollections(state, action) {
    let newState = { ...state };

    Object.keys(state).forEach((collectionId) => {
        if (state[collectionId].provider === action.environment.id) {
            newState = removeKey(collectionId, newState);
        }
    });

    return newState;
}

function updateResponseInCollection(state, action) {

    const currentCollection = state[action.collectionId];
    const updatedCollection = {
        ...currentCollection,
        response: action.response
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };

}

function updateOAuthResponseInCollection(state, action) {

    const currentCollection = state[action.collectionId];
    const updatedCollection = {
        ...currentCollection
    };

    updatedCollection.OAuthResponses[action.responseName] = action.response;

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function clearOAuthResponsesInCollection(state, action) {
    const currentCollection = state[action.collectionId];
    const updatedCollection = {
        ...currentCollection
    };

    updatedCollection.OAuthResponses = {};

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function updateParameters(state, action) {

    const currentCollection = state[action.collectionId];
    const updatedCollection = {
        ...currentCollection,
        parameters: action.updatedParameters.toMap()
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function deleteCollectionsAfterDeletingGroup(state, action) {
    const newState = {
        ...state
    };
    if (action.collectionsInGroup) {
        action.collectionsInGroup.forEach(element => delete newState[element]);
    }
    return newState;
}

function duplicate(state, action) {
    const newState = {
        ...state
    };
    const collectionToDuplicate = state[action.collectionId];
    const newCollection = {
        ...collectionToDuplicate,
        name: collectionToDuplicate.name + ' Copy',
        id: action.newCollectionId
    }

    return {
        ...newState,
        [action.newCollectionId]: newCollection
    }
}

function duplicateCollectionsFromEnvironment(state, action) {
    const newState = Object.assign({}, state);

    Object.keys(state).forEach(c => {
        if (state[c].provider === action.environmentId) {
            newState[c + 'd'] = Object.assign({}, state[c]);
            newState[c + 'd'].id = c + 'd';
            newState[c + 'd'].provider = action.newEnvironmentId;
        }
    })

    return {
        ...newState
    }
}

function setErrorOnCollection(state, action) {
    const currentCollection = state[action.collectionId];

    const updatedCollection = {
        ...currentCollection,
        error: action.error
    };

    return {
        ...state,
        [action.collectionId]: updatedCollection
    };
}

function collections(state = [], action) {
    switch (action.type) {
        case C.CREATE_COLLECTION:
            return createCollection(state, action);
        case C.CREATE_DEMO_COLLECTION:
            return createDemoCollection(state, action);
        case C.SELECT_PROVIDER_FOR_COLLECTION:
            return selectEnvironment(state, action);
        case C.UPDATE_COLLECTION:
            return updateCollection(state, action);
        case C.IMPORT_COLLECTION:
            return importCollection(state, action);
        case C.SET_ERROR_ON_COLLECTION:
            return setErrorOnCollection(state, action);
        case C.DUPLICATE_COLLECTION:
            return duplicate(state, action);
        case C.DELETE_COLLECTION:
            return deleteCollection(state, action);
        case C.UPDATE_TOKEN:
            return updateToken(state, action);
        case C.UPDATE_INTROSPECTED_TOKEN:
            return updateIntrospectedToken(state, action);
        case C.SET_TOKENS:
            return setTokens(state, action);
        case C.UPDATE_PARAMETERS:
            return updateParameters(state, action);
        case C.UPDATE_KEY_FOR_TOKEN:
            return updateKeyInToken(state, action);
        case C.UPDATE_PRIVATE_KEY_FOR_TOKEN:
            return updatePrivateKeyInToken(state, action);
        case C.DUPLICATE_ENVIRONMENT:
            return duplicateCollectionsFromEnvironment(state, action);
        case C.DELETE_ENVIRONMENT:
            return deleteEnvironmentFromCollections(state, action);
        case C.UPDATE_RESPONSE:
            return updateResponseInCollection(state, action);
        case C.UPDATE_OAUTH_RESPONSE:
            return updateOAuthResponseInCollection(state, action);
        case C.CLEAR_OAUTH_RESPONSES:
            return clearOAuthResponsesInCollection(state, action);
        case C.DELETE_GROUP:
            return deleteCollectionsAfterDeletingGroup(state, action);
        default:
            return state;
    }
}

export default collections;
