/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'
import ClaimsTable from '../flows/settings/claims/ClaimsTable'
import ClaimsJson from '../flows/settings/claims/ClaimsJson'
import { claimsArrayToRequestParameter } from '../../util/util';
import { openidClaimsMappings } from '../../util/appConstants';
import { IconGeneralClose } from '@curity-internal/ui-icons-react';

class ClaimsModal extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            preset: 'none'
        };

    }

    setClaimsModalWrapper = node => {
        this.claimsModalRef = node;
    };

    setClaimsTableRef = node => {
        this.claimsTable = node;
    };

    handleClickOutside = event => {
        if (
            this.claimsModalRef &&
            !this.claimsModalRef.contains(event.target)
        ) {
            this.props.handleClose()
        }
    };

    closeClaimsModal = () => {
        this.props.handleClose()
    };

    keyboardPresses = event => {
        if (this._isMounted) {
            if (event.keyCode === 27) {
                this.closeClaimsModal()
            }
        }
    };

    togglePreset = (presetType) => {
        this.setState({ preset: presetType });
    };

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener('keydown', this.keyboardPresses, false)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    triggerOpenIdClaimsMappings(claims) {
        if (this.claimsTable) {
            this.claimsTable.setOpenIdClaimsMappings(claims);

            this.claimsModalRef.scrollTo(0,0)
        }
    }

    updateClaimsFromJson(value) {
        let formattedClaims = [];

        Object.keys(value).forEach(usage => {
            const claimNames = value[usage];
            Object.keys(claimNames).forEach(claimName => {

                if (claimNames[claimName] === null) {
                    formattedClaims.push({
                        name: claimName,
                        usage: [usage]
                    })
                } else {
                    let values = null;
                    if (claimNames[claimName].value){
                        values = [claimNames[claimName].value];
                    }
                    if (claimNames[claimName].values) {
                        values = claimNames[claimName].values;
                    }

                    formattedClaims.push({
                        name: claimName,
                        usage: [usage],
                        values,
                        required: !!claimNames[claimName].essential
                    })
                }
            })
        });

        if (this.claimsTable) {
            this.claimsTable.setClaimsFromJson(formattedClaims);
        }
    }


    render() {
        const modalTitle = 'Add Claims';

        const showHideClassName = this.props.showClaimsModal
            ? 'modal modal-claims modal-is-visible'
            : 'modal modal-claims modal-is-hidden';

        if (this.props.showClaimsModal) {
            document.addEventListener('mousedown', this.handleClickOutside)
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside)
        }
        const claimsJson = claimsArrayToRequestParameter(this.props.collection.parameters.claims);

        return (
            <div className={showHideClassName}>
                {this.props.showClaimsModal && <section
                    className="modal-main modal-main-wide"
                    ref={this.setClaimsModalWrapper}
                >
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-between">
                            <h3 className="h4 m0">{modalTitle}</h3>
                            <div className="flex flex-center justify-between">
                                <button
                                    aria-label="Close modal"
                                    className="button button-close button-link ml2"
                                    onClick={this.closeClaimsModal}
                                >
                                    <IconGeneralClose width={18} height={18} />
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="modal-main-content">
                        <ClaimsTable ref={this.setClaimsTableRef}
                                     environment={this.props.environment}
                                     updateParameters={this.props.updateParameters}
                                     collection={this.props.collection}/>
                        <ClaimsJson claimsJson={claimsJson}
                                    updateClaimsFromJson={this.updateClaimsFromJson.bind(this)}/>

                        <label className="label block mt2">OpenID scope presets</label>
                        <div className="button-group mt1">
                            <button
                                aria-label="Toggle preset openid"
                                className={`button button-tiny ${(this.state.preset === 'openid' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                        this.triggerOpenIdClaimsMappings(openidClaimsMappings.openid),
                                        this.togglePreset('openid')
                                        ]
                                    }
                                }
                            >
                                OpenID
                            </button>
                            <button
                                aria-label="Toggle preset profile"
                                className={`button button-tiny ${(this.state.preset === 'profile' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                            this.triggerOpenIdClaimsMappings(openidClaimsMappings.profile),
                                            this.togglePreset('profile')
                                        ]
                                    }
                                }
                            >
                                Profile
                            </button>
                            <button
                                aria-label="Toggle preset email"
                                className={`button button-tiny ${(this.state.preset === 'email' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                            this.triggerOpenIdClaimsMappings(openidClaimsMappings.email),
                                            this.togglePreset('email')
                                        ]
                                    }
                                }
                            >
                                Email
                            </button>
                            <button
                                aria-label="Toggle preset address"
                                className={`button button-tiny ${(this.state.preset === 'address' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                            this.triggerOpenIdClaimsMappings(openidClaimsMappings.address),
                                            this.togglePreset('address')
                                        ]
                                    }
                                }
                            >
                                Address
                            </button>
                            <button
                                aria-label="Toggle preset phone"
                                className={`button button-tiny ${(this.state.preset === 'phone' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                            this.triggerOpenIdClaimsMappings(openidClaimsMappings.phone),
                                            this.togglePreset('phone')
                                        ]
                                    }
                                }
                            >
                                Phone
                            </button>
                            <button
                                aria-label="Reset presets"
                                className={`button button-tiny ${(this.state.preset === 'none' ? 'button-primary': 'button-primary-outline')}`}
                                onClick={ () => {
                                    return [
                                            this.triggerOpenIdClaimsMappings([]),
                                            this.togglePreset('none')
                                        ]
                                    }
                                }
                            >
                                { this.state.preset === 'none' ? 'No preset' : 'Reset' }
                            </button>
                        </div>
                    </div>
                </section>}
            </div>
        )
    }
}

export default ClaimsModal
