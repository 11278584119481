/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'

function About() {
    const [post, setPost] = useState('');

    useEffect(() => {
        if (IS_ELECTRON_BUILD) {
            import('./CHANGELOG.md')
                .then(res => {
                    fetch(res.default)
                        .then(r => r.text())
                        .then(t => setPost(t))
                        .catch(err => console.log(err));
                })
                .catch(err => console.log(err));
        }
    });

    return (
        <>
            <div className="lg-flex">
                <main className="flex-100 px2 lg-px3 py2 lg-py4">
                    <div className="px2 lg-px4">
                        <article className="article" role="article">
                            <ReactMarkdown>{post}</ReactMarkdown>
                        </article>
                    </div>
                </main>
            </div>
        </>
    );
}

export default About;
