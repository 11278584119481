/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const TOKEN_PURPOSE_MAPPING = {
    'access_token': {
        'required_claims': ['sub'],
        'recommended_claims': [],
        'optional_claims': ['aud', 'scope']
    },
    'refresh_token': {
        'required_claims': ['sub'],
        'recommended_claims': [],
        'optional_claims': ['aud', 'scope']
    },
    'id_token': {
        'required_claims': ['iss', 'sub', 'aud', 'exp', 'iat'],
        'recommended_claims': [],
        'optional_claims': ['auth_time', 'nonce', 'acr', 'amr', 'azp']
    },
    'userinfo': {
        'required_claims': ['iss', 'sub', 'aud', 'exp', 'iat'],
        'recommended_claims': [],
        'optional_claims': ['auth_time', 'nonce', 'acr', 'amr', 'azp']
    },
    'client_assertion': {
        'required_claims': ['iss', 'aud', 'exp'],
        'recommended_claims': [],
        'optional_claims': ['iat', 'jti']
    },
    'authorization_details': {
        'required_claims': [],
        'recommended_claims': [],
        'optional_claims': []
    },
    'user_assertion': {
        'required_claims': ['iss', 'sub', 'aud', 'exp'],
        'recommended_claims': [],
        'optional_claims': ['jti']
    },
    'request_object': {
        'required_claims' : ['response_type', 'client_id', 'redirect_uri'],
        'recommended_claims' : [],
        'optional_claims': []
    },
    'openid_metadata': {
        'required_claims': ['issuer', 'token_endpoint', 'authorization_endpoint', 'response_types_supported',
            'jwks_uri', 'subject_types_supported', 'id_token_signing_alg_values_supported'],
        'recommended_claims': ['userinfo_endpoint', 'registration_endpoint', 'scopes_supported', 'claims_supported'],
        'optional_claims': [
            'response_modes_supported', 'grant_types_supported', 'token_endpoint_auth_methods_supported',
            'token_endpoint_auth_signing_alg_values_supported', 'service_documentation', 'ui_locales_supported',
            'op_policy_uri', 'op_tos_uri', 'revocation_endpoint', 'revocation_endpoint_auth_methods_supported',
            'revocation_endpoint_auth_signing_alg_values_supported', 'introspection_endpoint', 'acr_values_supported',
            'introspection_endpoint_auth_methods_supported', 'introspection_endpoint_auth_signing_alg_values_supported',
            'code_challenge_methods_supported', 'id_token_encryption_alg_values_supported', 'request_parameter_supported',
            'id_token_encryption_enc_values_supported', 'userinfo_signing_alg_values_supported',
            'display_values_supported', 'claim_types_supported', 'service_documentation',
            'claims_locales_supported', 'claims_parameter_supported', 'request_uri_parameter_supported',
            'require_request_uri_registration',
            'userinfo_encryption_alg_values_supported', 'userinfo_encryption_enc_values_supported', 'request_object_encryption_enc_values_supported',
            'request_object_signing_alg_values_supported', 'request_object_encryption_alg_values_supported']
    },
    'logout_token' : {
        'required_claims': ['iss', 'nonce', 'aud', 'iat', 'jti','events'],
        'recommended_claims': ['sid'],
        'optional_claims': []
    },
    'verifiable_credential' : {
        'required_claims': [],
        'recommended_claims': [],
        'optional_claims': []
    },
    'unknown': {
        'required_claims': [],
        'recommended_claims': [],
        'optional_claims': []
    }
};

