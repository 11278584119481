/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import InteractiveFlow from './InteractiveFlow';
import AC, { flows, guides } from '../../util/appConstants';
import Scopes from './settings/Scopes';
import Environments from '../../data/Environments';
import OpenIdConnectSettings from './settings/OpenIdConnectSettings';
import IntrospectionSettings from './settings/IntrospectionSettings';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import StepBox from './StepBox';
import StartUrl from './StartUrl';
import RunButton from './RunButton';
import StartHere from './StartHere';
import ClientCredentials from './settings/ClientCredentials';
import { decodeUrlParameter } from '../../util/util';
import FlowHeader from './FlowHeader';
import CallbackUriHelper from './settings/CallbackUriHelper';
import CanBeFramed from './settings/CanBeFramed';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClaimsModal from '../modals/ClaimsModal';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';
import { IconGeneralClose } from '@curity-internal/ui-icons-react';

class ImplicitFlow extends InteractiveFlow {

    constructor(props) {
        super(props, AC.IMPLICIT_PATH, 'token');
        this.state.startUrl = '';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.refs.redeemCodeButton) {
            this.refs.redeemCodeButton.classList.remove('button-loading-active', 'button-disabled');
        }
        if (prevProps !== this.props) {
            this.startUrl().then(url => {
                this.setState({ startUrl: url });
            })
        }
    }

    render() {
        super.render();

        const currentCollection = this.props.collection;
        const parameters = currentCollection.parameters;

        let clientId = null;
        if (parameters.client_id) {
            clientId = parameters.client_id;
        }
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        const enableStep2 = (clientId !== null && environment && environment.canDoImplicit());
        const startUrl = (!enableStep2) ? '' : this.state.startUrl;


        const loginIFrameModal = this.state.showModal
            ? <div className="modal modal-is-visible">
                <section className="modal-main" ref={this.setInteractiveFlowModalWrapper}>
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-end">
                            <button className="button button-close button-link" onClick={this.hideModal}>
                            <IconGeneralClose width={18} height={18} />
                            </button>
                        </div>
                    </header>
                    <div className="modal-main-content">
                        <iframe className="login-iframe" src={this.state.loginModalUrl}/>
                    </div>
                </section>
            </div>
            : '';

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">

                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><IconGeneralClose width={18} height={18} />
                </button>
            </div>;


        return (
            <React.Fragment>
                {loginIFrameModal}
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>
                            <div className="tools-form-content">

                                <FlowHeader name={currentCollection.name}
                                            description={'Enter client ID and run a new implicit flow.'}/>
                                {error}

                                <CallbackUriHelper text={this.interactiveFlowCallbackUrlHelpText()}
                                                updateParameters={this.props.updateParameters}
                                                collection={currentCollection}
                                                showEditButton={true}
                                                defaultUrl={this.defaultRedirectUri()}
                                                url={this.redirectUri()}/>

                                <StepBox title={'Settings'} step={'1'} enabled={true}>

                                    <StartHere clientId={clientId}/>

                                    <ClientCredentials
                                        updateParameters={this.props.updateParameters}
                                        updateEnvironment={this.props.updateEnvironment}
                                        collection={currentCollection}
                                        disableSecret={true}
                                        environment={environment}
                                        flow={flows.implicit}
                                    />

                                    <div className="sm-flex flex-center flex-wrap flex-gap-2 mt2">
                                        <div>
                                            <Scopes
                                                updateParameters={this.props.updateParameters}
                                                environment={environment}
                                                collection={currentCollection}
                                            />
                                        </div>
                                        {environment && environment.claims_parameter_supported ?
                                        <div>
                                            <ClaimsModal
                                                updateParameters={this.props.updateParameters}
                                                environment={environment}
                                                collection={currentCollection}
                                                showClaimsModal={this.state.showClaimsModal}
                                                handleClose={this.hideClaimsModal}/>
                                            <button onClick={this.showClaimsModal} className="button button-small button-primary-outline button-input">
                                                Add Claims
                                            </button>
                                        </div>
                                            : '' }
                                    </div>

                                    <OpenIdConnectSettings
                                        environment={environment}
                                        collection={currentCollection}
                                        updateParameters={this.props.updateParameters}
                                        hideResponseTypes={true}
                                    />

                                    <IntrospectionSettings
                                        collection={currentCollection}
                                        environment={environment}
                                        updateEnvironment={this.props.updateEnvironment}
                                        updateParameters={this.props.updateParameters}
                                    />

                                </StepBox>

                                <StepBox step={'2'} title={'Start Flow'} enabled={enableStep2} tooltip="Define an authorization and token endpoint to start using this flow">
                                    <div className={'flex flex-justify'}>
                                        <CanBeFramed
                                            collection={currentCollection}
                                            updateParameters={this.props.updateParameters}/>

                                        <ExtraQueryParametersModal
                                            updateParameters={this.props.updateParameters}
                                            collection={this.props.collection}
                                            parameter={'authorization_request_extra_query_parameters'}
                                        />
                                    </div>
                                    <StartUrl startUrl={startUrl}/>

                                    <RunButton runFlow={this.runFlow}/>

                                </StepBox>

                                <ServerResponse response={
                                    currentCollection.OAuthResponses.ImplicitFlowAuthorizationCode}/>

                            </div>
                    </section>
                    <ReceivedTokensSidebar
                        guide={guides.implicit}
                        flow={flows.implicit}
                        collection={currentCollection}
                        environment={environment}
                        groups={this.props.groups}
                        clearOAuthResponses={this.props.clearOAuthResponses}
                        setTokensOnCollection={this.props.setTokensOnCollection}
                        introspectToken={this.props.introspectToken}
                        createAndSelectCollectionWithToken={this.props.createAndSelectCollectionWithToken}
                    />
                </ResizablePanels>
            </React.Fragment>
        )
    }
}

export default ImplicitFlow;
