/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const ENDPOINT_TYPES = {
    'authorization_endpoint': 'Authorization Endpoint',
    'token_endpoint': 'Token Endpoint',
    'userinfo_endpoint': 'Userinfo Endpoint',
    'introspection_endpoint': 'Introspection Endpoint',
    'revocation_endpoint': 'Revoke Endpoint',
    'jwks_uri': 'JWKS Endpoint',
    'device_authorization_endpoint': 'Device Endpoint',
    'registration_endpoint': 'Dynamic Client Registration Endpoint',
    'end_session_endpoint': 'Logout Endpoint',
    'backchannel_authentication_endpoint': 'Backchannel Authentication Endpoint',
    'pushed_authorization_request_endpoint': 'Push Authorization Request Endpoint'
};

class Endpoints {

    constructor(endpoints) {
        const endp = endpoints ? endpoints: {};

        const endpointsMap = {
            authorization_endpoint: endp.authorization_endpoint,
            token_endpoint: endp.token_endpoint,
            introspection_endpoint: endp.introspection_endpoint,
            revocation_endpoint: endp.revocation_endpoint,
            registration_endpoint: endp.registration_endpoint,
            userinfo_endpoint: endp.userinfo_endpoint,
            jwks_uri: endp.jwks_uri,
            device_authorization_endpoint: endp.device_authorization_endpoint,
            end_session_endpoint: endp.end_session_endpoint,
            backchannel_authentication_endpoint: endp.backchannel_authentication_endpoint,
            pushed_authorization_request_endpoint: endp.pushed_authorization_request_endpoint
        };

        this._authorization_endpoint = endp.authorization_endpoint;
        this._token_endpoint = endp.token_endpoint;
        this._introspection_endpoint = endp.introspection_endpoint;
        this._revocation_endpoint = endp.revocation_endpoint;
        this._registration_endpoint = endp.registration_endpoint;
        this._userinfo_endpoint = endp.userinfo_endpoint;
        this._jwks_uri = endp.jwks_uri;
        this._device_authorization_endpoint = endp.device_authorization_endpoint;
        this._end_session_endpoint = endp.end_session_endpoint;
        this._backchannel_authentication_endpoint = endp.backchannel_authentication_endpoint;
        this._pushed_authorization_request_endpoint = endp.pushed_authorization_request_endpoint;
        this._endpoints = endpointsMap;
    }

    get authorization_endpoint() {
        return this._authorization_endpoint;
    }

    get token_endpoint() {
        return this._token_endpoint;
    }

    get introspection_endpoint() {
        return this._introspection_endpoint;
    }

    get revocation_endpoint() {
        return this._revocation_endpoint;
    }

    get registration_endpoint() {
        return this._registration_endpoint;
    }

    get userinfo_endpoint() {
        return this._userinfo_endpoint;
    }

    get device_authorization_endpoint() {
        return this._device_authorization_endpoint;
    }

    get end_session_endpoint() {
        return this._end_session_endpoint;
    }

    get pushed_authorization_request_endpoint() {
        return this._pushed_authorization_request_endpoint;
    }

    get backchannel_authentication_endpoint() {
        return this._backchannel_authentication_endpoint;
    }

    get jwks_uri() {
        return this._jwks_uri;
    }

    toMap = () => {
        return this._endpoints;
    };

    withUpdatedValue = (key, value) => {

        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new Endpoints(next);
        } else {
            throw 'Key ' + key + ' is not a property of Endpoints';
        }
    };
}

export default Endpoints;
