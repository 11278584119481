/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import {
    base64ToBuffer,
    bufferToBase64,
    decryptMessage,
    encryptMessage,
    generateKey,
    isCryptoSupportedByAgent,
    isQueryParamsExists,
    removePropsFromData
} from '../util/util';
import { ERROR_HANDLING_MESSAGES, configApiUrl } from '../util/appConstants';
import { HttpStatus } from '../util/HttpStatus';

export const createSharedConfigPermalink = () => {
    return (dispatch, state) => {
        return new Promise((resolve, reject) => {
            if (isCryptoSupportedByAgent()) {
                let sharedSecret = new Uint8Array(32);
                window.crypto.getRandomValues(sharedSecret);
                const message = JSON.stringify(removePropsFromData(state(), ['appState']));

                AESEncryption({ sharedSecret, message }).then((ivAndbase64AndEncryptedData) => {
                    serverApiCall({ sharedSecret, base64Data: ivAndbase64AndEncryptedData })
                        .then(response => {
                        if (response.status_code === 201) {
                            const configHash = response.config_hash;
                            sharedSecret = bufferToBase64(sharedSecret);
                            resolve({
                                success:true,
                                statusCode: response.status_code,
                                sharedSecret,
                                configHash });
                        } else {
                            resolve({
                                success:false,
                                statusCode: response.status_code
                            });
                        }
                    }).catch((error) => {
                        reject({
                            success: false,
                            error
                        });
                    });
                }).catch((error) => {
                    reject({
                        success: false,
                        error
                    });
                });
            } else {
                reject(new Error("Agent doesn't support to generate key"));
            }
        })
    }
};


export const importShareableConfig = ({ url }) => {
    return () => {
        return new Promise(((resolve, reject) => {
            const tokens = url.split('#');
            if (url.indexOf('/c/') !== -1 && tokens.length > 0) {
                const urlTokens = tokens[0].split('/');
                const configHash = urlTokens[urlTokens.length - 1];
                let sharedSecret;
                if (isQueryParamsExists(tokens[1])) {
                    // Flow Without Query params:
                    const secretKey = tokens[1].split('&')[0];
                    try {
                        sharedSecret = base64ToBuffer(secretKey); // conversion to bytes(Uint8Array)` before decrypt;
                    } catch (e) {
                        reject ({
                            success: false,
                            error: ERROR_HANDLING_MESSAGES.SECRET_KEY_CORRUPTED
                        })
                    }
                } else {
                    try {
                        // Default Flow Without Query params:
                        sharedSecret = base64ToBuffer(tokens[1]);
                    } catch (e) {
                        reject ({
                            success: false,
                            error: ERROR_HANDLING_MESSAGES.SECRET_KEY_CORRUPTED
                        })
                    }
                }

                return fetchConfig(configHash).then((response) => {
                    if (response.success) {
                        return AESDecryption({ sharedSecret, base64Data: response.data })
                            .then((decryptedTextResponse) => {
                                resolve(decryptedTextResponse);
                            }, (error) => {
                                reject(error)
                            });
                    } else {
                        reject(response);
                    }
                }, (error) => {
                    reject(error);
                });
            } else {
                reject({
                    success: false,
                    error: `${ERROR_HANDLING_MESSAGES.SHARED_URL_CORRUPTED}`
                });
            }
        }))
    }
};

const fetchConfig = (hash) => {
    const url = `/api/c/${hash}`;
    return fetch(configApiUrl() + url, {
        method: 'GET'
    }).then((response) => {
        if (!response.ok) {
            return {
                success: false,
                error: `${ERROR_HANDLING_MESSAGES.SHARED_FETCH_CONFIG}`
            };
        }
        return response.json();
    }).then(result => {
        if (result.status_code === HttpStatus.OK) {
            return {
                success: true,
                data: result.config
            };
        } else {
            return {
                success: false,
                error: `${ERROR_HANDLING_MESSAGES.SHARED_FETCH_CONFIG}`
            };
        }
    });
};

export const AESEncryption = ({ sharedSecret, message }) => {
    return generateKey(sharedSecret).then((key) => {
        return encryptMessage({ message, key }).then((base64) => {
            return base64;
        });
    });
};

export const AESDecryption = ({ sharedSecret, base64Data }) => {
    return generateKey(sharedSecret).then((key) => {
        return decryptMessage(base64Data, key).then((base64) => {
            return {
                success:true,
                data:atob(base64)
            }
        }, () => {
            return {
                success: false,
                error: ERROR_HANDLING_MESSAGES.SECRET_KEY_CORRUPTED
            }
        });
    }, () => {
        return {
            success: false,
            error: ERROR_HANDLING_MESSAGES.SECRET_KEY_CORRUPTED
        }
    });
};

const serverApiCall = ({ base64Data }) => {
    return new Promise(((resolve, reject) => {
        fetch(configApiUrl() + '/api/c', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                'config': base64Data
            })
        })
            .then((response) => {
                if (!response.ok) {
                    reject(response);
                }
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            })

    }));
};
