/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from '../actions/actionConstants';
import { logEvent } from '../util/analytics';
import { removeKey } from '../util/util';

function createGroup(state, action) {
    logEvent('Group', 'created', action.name);
    return {
        ...state,
        [action.groupId]: { name: action.name, order: [], environment: action.environment }
    };
}

function deleteGroupsOfEnvironment(state, action) {
    let newState = { ...state };

    Object.keys(state).forEach((groupId) => {
        if (state[groupId].environment === action.environment.id) {
            newState = removeKey(groupId, newState);
        }
    });

    return newState;
}

function createDefaultGroupOfEnvironment(state, action) {
    return { ...state, [action.id]: { environment: action.id, order: [] } };
}

function renameGroup(state, action) {
    logEvent('Group', 'renamed', action.name);
    const newState = {
        ...state
    };

    newState[action.groupId].name = action.name;
    return newState;
}

function setGroupCollapsed(state, action) {
    const newState = {
        ...state
    };

    newState[action.groupId].collapsed = action.collapsed;
    return newState;
}

function deleteCollectionFromAnyGroup(state, action) {
    const newState = {
        ...state
    };
    Object.keys(state).forEach(k => {
        newState[k].order = newState[k].order.filter((value) => {
            return value !== action.collectionId;
        })
    })

    return newState;
}

function deleteGroup(state, action) {
    logEvent('Group', 'deleted', action.name);
    const newState = {
        ...state
    };

    delete newState[action.groupId];
    return newState;
}

function importGroup(state, action) {
    logEvent('Group', 'imported', action.name);
    return {
        ...state,
        [action.groupId]: action.group
    };
}

function mergeGroup(state, action) {
    logEvent('Group', 'merged', action.name);
    const newOrder = action.override ? action.group.order : state[action.groupId].order.concat(action.group.order)
    return {
        ...state,
        [action.groupId]: { name: action.group.name, order: newOrder }
    };
}

function addCollectionToGroup(state, action) {
    const newState = {
        ...state
    };
    if (action.oldGroupId) {
        newState[action.oldGroupId].order = newState[action.oldGroupId].order.filter((value) => {
            return value !== action.collectionId
        })
    }

    if (newState[action.groupId]) {
        const order = newState[action.groupId].order;
        const newOrder = order.filter((value) => {
            return value !== action.collectionId
        })

        const index = action.index === -1 ? newOrder.length : action.index;
        newOrder.splice(index, 0, action.collectionId)

        newState[action.groupId].order = newOrder;
    }

    return newState;
}

function duplicateGroupsForEnvironment(state, action) {
    const newState = {
        ...state
    };

    Object.keys(state).forEach(g => {
        if (state[g].environment === action.environmentId) {
            newState[g + 'd'] = { ...state[g], environment: action.newEnvironmentId }
            newState[g + 'd'].order = state[g].order.map((c) => {
                return c + 'd'
            });
        }
    })
    newState.default.order = [...state.default.order, ...action.defaultGroupOrder.map((c) => {
        return c + 'd'
    })];

    return newState;
}

function groups(state = [], action) {
    switch (action.type) {
        case C.DELETE_ENVIRONMENT:
            return deleteGroupsOfEnvironment(state, action);
        case C.CREATE_ENVIRONMENT:
            return createDefaultGroupOfEnvironment(state, action);
        case C.CREATE_SIGNICAT_ENVIRONMENT:
            return createDefaultGroupOfEnvironment(state, action);
        case C.CREATE_NEW_GROUP:
            return createGroup(state, action);
        case C.SET_GROUP_COLLAPSED:
            return setGroupCollapsed(state, action);
        case C.DELETE_GROUP:
            return deleteGroup(state, action);
        case C.IMPORT_GROUP:
            return importGroup(state, action);
        case C.MERGE_GROUP:
            return mergeGroup(state, action);
        case C.RENAME_GROUP:
            return renameGroup(state, action);
        case C.ADD_COLLECTION_TO_GROUP:
            return addCollectionToGroup(state, action);
        case C.DELETE_COLLECTION:
            return deleteCollectionFromAnyGroup(state, action);
        case C.DUPLICATE_ENVIRONMENT:
            return duplicateGroupsForEnvironment(state, action);
        default:
            return state;
    }
}

export default groups;
